import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { deleteAllPhotos } from 'services/lots'

const DeleteAllLotPhotosModal = ({ lot, setAssets, approval }) => {
    const [ visible, setVisible ] = useState(false)

    const header = (
        <div className="text-lg font-bold">
            Delete All Lot Photos
        </div>
    )

    const close = () => {
        setVisible(false)
    }

    const submit = () => {
        deleteAllPhotos(lot.id, ({ data }) => {
            setAssets(approval ? (data.lot.pending_approvals[0] ? data.lot.pending_approvals[0].assets : []) : data.lot.assets)
            close()
        }, approval)
    }

    const footer = (
        <div>
            <Button color="outline" onClick={close}>
                Cancel
            </Button>
            <Button onClick={submit}>
                OK
            </Button>
        </div>
    )

    return (
        <div>
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer} style={{ width: "40rem" }}
                    onHide={close}>
                <p>
                    Are you sure you want to delete all this lot's photos?
                </p>
            </Dialog>

            <Button
                color="red"
                size="md"
                onClick={() => {
                    setVisible(true)
                }}
            >
                Delete All
            </Button>
        </div>
    )
}

export default DeleteAllLotPhotosModal