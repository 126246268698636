import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import Button from 'components/buttons/Button'

const Information = ({ client, setClient, submit }) => {
    const setData = (k, v) => {
        setClient({
            ...client,
            [k]: v
        })
    }

    return (
        <Card extra={"w-full !p-5"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Client Information
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Here you can change the client's information
                </p>
            </div>

            <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
                <InputField
                    label="Name"
                    id="name"
                    type="text"
                    value={client.name || ''}
                    onChange={(e) => setData('name', e.target.value)}
                />
            </div>

            <div className="mt-12 flex w-full justify-end">
                <Button
                    onClick={submit}
                >Submit</Button>
            </div>
        </Card>
    );
};

export default Information;
