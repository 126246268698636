import noteTypes from './NoteTypes'

const NoteDisplay = ({ notes }) => {
    return (
        <>
            {notes?.filter(note => note.options?.notify).map(note => {
                const type = noteTypes.filter(type => type.value === note.options.type)[0]
                const classes = type ? type.classes : ''

                return (
                    <div key={note.id} className={classes + " w-full px-3 py-2 mb-2"}>
                        <div dangerouslySetInnerHTML={{
                            __html: note.body
                        }}></div>
                    </div>
                )
            })}
        </>
    )
}

export default NoteDisplay