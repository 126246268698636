import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import Link from 'components/buttons/Link'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import InputField from 'components/fields/InputField'
import Label from 'components/fields/Label'
import SelectField from 'components/fields/SelectField'
import { FaPlus } from 'react-icons/fa6'
import UsersAutocomplete from 'components/users/UsersAutocomplete'
import { registerBidder } from 'services/auctions'

const AddBidderModal = ({ auction, after }) => {
    const [ visible, setVisible ] = useState(false)
    const [ data, setData ] = useState({
        bidder_number: '',
        user_id: ''
    })
    const notify = useNotificationsDispatch()

    const submit = () => {
        registerBidder(auction.id, data, () => {
            setVisible(false)

            notify({
                message: 'Bidder has been added to the auction.',
                title: 'Success!',
                type: 'success',
            })

            if (after) {
                after()
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Add Bidder to Auction
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={submit}>
                Submit
            </Button>
        </div>
    )

    const setValue = (k, v) => {
        setData({
            ...data,
            [k]: v
        })
    }

    return (
        <div className="-mt-0.5">
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer} style={{ width: "40rem" }}
                    onHide={() => setVisible(false)}>
                <InputField
                    id="bidder_number"
                    label="Bidder Number"
                    placeholder="Use default value (user's permanent or next available)"
                    onChange={(e) => setValue('bidder_number', e.target.value)}
                />

                <div className="mt-4">
                    <Label htmlFor="user">Select a user:</Label>
                    <UsersAutocomplete
                        id="user"
                        onSelect={(user) => setValue('user_id', user?.id)}
                    />
                </div>
            </Dialog>

            <Button onClick={() => {
                setVisible(true)
            }}>
                <span className="flex gap-2 items-center">
                    <FaPlus className="w-5 h-5" />
                    <span>Add Bidder</span>
                </span>
            </Button>
        </div>
    )
}

export default AddBidderModal