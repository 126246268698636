import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/folders', {
        params
    }).then(then)
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/folder/' + id)
        .then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/folders/create', data)
        .then(then)
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/folder/' + id + '/update', data)
        .then(then)
}

export const deleteFolder = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/folder/' + id + '/delete')
        .then(then)
}

export const files = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/folder/' + id + '/files', {
        params
    }).then(then)
}