import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Label from '../fields/Label'
import Combobox from 'components/fields/Combobox'
import noteTypes from './NoteTypes'
import { useState } from 'react'
import Button from '../buttons/Button'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const AlertForm = ({ onSubmit, options, ...props }) => {
    const [ alert, setAlert ] = useState(props.alert)
    const notify = useNotificationsDispatch()

    const setAlertAttr = (k, v) => {
        setAlert({
            ...alert,
            [k]: v
        })
    }

    const setAlertOption = (k, v) => {
        if (! alert.options) {
            alert.options = {}
        }

        setAlertAttr('options', {
            ...alert.options,
            [k]: v
        })
    }

    const submit = () => {
        onSubmit(alert, () => {
            notify({
                message: 'Alert saved.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    return (
        <div className="bg-slate-50 shadow sm:rounded-lg mt-4 px-4 py-5 sm:p-6">
            <Label>Alert Color</Label>

            <Combobox
                options={noteTypes}
                defaultValue={alert.options?.type}
                onChange={(option) => {
                    setAlertOption('type', option.value)
                }}
            />

            <div style={{
                height: '300px',
                marginBottom: '60px'
            }}>
                <ReactQuill theme="snow"
                            className="mt-2 h-full"
                            value={alert.body || ''}
                            onChange={(v) => {
                                setAlertAttr('body', v)
                            }}
                />
            </div>

            <div className="flex justify-end items-center gap-4">
                <div>
                    {options && options(alert.options, setAlertOption)}
                </div>

                <div className="flex justify-end items-center gap-2">
                    <Button
                        size="md"
                        onClick={submit}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AlertForm