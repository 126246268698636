const CreditCardDisplay = ({ card }) => {
    let logo

    switch (card.brand) {
        case "amex":
            logo = "amex.svg"
            break

        case "discover":
            logo = "discover.svg"
            break

        case "mastercard":
            logo = "mastercard.svg"
            break

        case "visa":
            logo = "visa.svg"
            break
    }

    return (
        <div className="flex gap-2 items-center text-sm">
            {logo ? (
                <img src={"/img/cards/" + logo} alt={card.brand} className="h-12"/>
            ) : card.brand}

            <div>
                <h3 className="font-bold text-sm">
                    {card.name ? card.name : card.last4}
                </h3>
                <div className="text-gray-500">ending in {card.last4}</div>
                <span className="text-gray-500">exp {card.exp_month} / {card.exp_year}</span>
            </div>
        </div>
    )
}

export default CreditCardDisplay