import BaseSettings from './base'

export default () => {
    return (
        <BaseSettings name="company.privacy_policy">
            <p>
                The privacy policy tells users how you use their data.
                It is accessible to users through a link on the footer of the site.
            </p>
        </BaseSettings>
    )
}