import { Link } from 'react-router-dom'
import React from 'react'

export default ({ trail }) => {
    const last = trail[trail.length - 1]

    return (
        <>
            <div className="pt-1">
                <a
                    className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                    href="/admin/dashboard"
                >
                    Admin
                </a>
                {trail.map((breadcrumb, i) => (
                    <div key={i} className="inline-block">
                        <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                          {" "}/{" "}
                        </span>
                        <Link
                            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                            to={breadcrumb.href}
                        >
                            {breadcrumb.title}
                        </Link>
                    </div>
                ))}
            </div>
            <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
                <Link
                    to={last.href}
                    className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
                >
                    {last.title}
                </Link>
            </p>
        </>
    )
}