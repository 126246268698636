import { Routes, Route, Navigate } from "react-router-dom";

import RTLLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";
import axios from 'axios'

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#f8fafc",
    "--color-100": "#f1f5f9",
    "--color-200": "#e2e8f0",
    "--color-300": "#cbd5e1",
    "--color-400": "#94a3b8",
    "--color-500": "#64748b",
    "--color-600": "#475569",
    "--color-700": "#334155",
    "--color-800": "#1e293b",
    "--color-900": "#0f172a",
    "--color-950": "#020617",
  });
  const [mini, setMini] = useState(false);

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line
  }, [themeApp]);

  return (
    <Routes>
      <Route></Route>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          <AdminLayout
            setMini={setMini}
            mini={mini}
            theme={themeApp}
            setTheme={setThemeApp}
          />
        }
      />
      <Route
        path="rtl/*"
        element={
          <RTLLayout
            setMini={setMini}
            mini={mini}
            theme={themeApp}
            setTheme={setThemeApp}
          />
        }
      />
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default App;
