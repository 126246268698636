import { useState } from 'react'
import Card from 'components/card'
import Button from 'components/buttons/Button'
import Location from './Location'

const Locations = ({ locations, setLocations, submit }) => {
    const [ newCounter, setNewCounter ] = useState(0)

    const setLocation = (editedLocation) => {
        setLocations(
            locations.map(location => {
                if (location.id === editedLocation.id) {
                    return editedLocation
                }

                return {
                    ...location,
                    is_default: false
                }
            })
        )
    }

    const addLocation = () => {
        locations.push({
            id: 'new' + newCounter,
            editing: true
        })

        setNewCounter(newCounter + 1)

        setLocations([
            ...locations
        ])
    }

    const removeLocation = (e, location) => {
        e.preventDefault()

        setLocations(
            locations.filter(l => l.id !== location.id)
        )
    }

    return (
        <Card extra={"w-full !p-5"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Company Locations
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Add or edit the company's location information
                </p>
            </div>

            <div className="divide-y divide-gray-300">
                {locations.map((location) => (
                    <Location
                        key={location.id}
                        location={location}
                        setLocation={setLocation}
                        removeLocation={removeLocation}
                    />
                ))}
            </div>

            <div className="mt-12 flex w-full justify-between">
                <Button color="outline"
                        onClick={addLocation}
                >
                    Add Location
                </Button>

                <Button
                    onClick={() => submit()}
                >Submit</Button>
            </div>
        </Card>
    );
};

export default Locations;
