import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { Premiums } from 'services/settings'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import Label from 'components/fields/Label'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'

const defaultPremium = {
    type: 'fixed'
}

const types = [
    {
        'value': 'fixed',
        'label': 'Fixed'
    },
    // {
    //     'value': 'scale',
    //     'label': 'Scale'
    // }
]

const SettingsPremiums = () => {
    const [ visible, setVisible ] = useState(false)
    const [ dialogTitle, setDialogTitle ] = useState('Create Premium')
    const [ premiums, setPremiums ] = useState([])
    const [ premium, setPremium ] = useState(defaultPremium)

    const loadPremiums = () => {
        Premiums.index({}, ({ data }) => {
            setPremiums(data.premiums)
        })
    }

    const deletePremium = (p) => {
        Premiums.delete(p.id, () => {
            loadPremiums()
        })
    }

    useEffect(() => {
        loadPremiums()
    }, [])

    const headerElement = (
        <div className="text-lg font-bold">
            {dialogTitle}
        </div>
    )

    const footerContent = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={() => savePremium()}>
                Save
            </Button>
        </div>
    )

    const TypeTemplate = (rowData) => {
        return types.filter(type => type.value === rowData.type)[0].label
    }

    const AmountTemplate = (rowData) => {
        let amount = rowData.config.amount

        return (
            <>
                {amount}%
            </>
        )
    }

    const ActionsTemplate = (rowData) => {
        return (
            <div className="flex gap-2 items-center">
                <Button
                    onClick={() => {
                        setPremium(rowData)
                        setDialogTitle('Edit Premium')
                        setVisible(true)
                    }}
                >Edit</Button>
                <Button
                    color="red"
                    onClick={() => {
                        if (window.confirm("Are you sure you want to delete?")) {
                            deletePremium(rowData)
                        }
                    }}>Delete</Button>
            </div>
        )
    }

    const setData = (k, v) => {
        setPremium({
            ...premium,
            [k]: v
        })
    }

    const setConfig = (k, v) => {
        const config = premium.config || {}

        setPremium({
            ...premium,
            config: {
                ...config,
                [k]: v
            }
        })
    }

    const savePremium = () => {
        const callback = () => {
            setVisible(false)
            setPremium(defaultPremium)
            loadPremiums()
        }

        if (premium.id) {
            Premiums.update(premium.id, premium, callback)
        } else {
            Premiums.create(premium, callback)
        }
    }

    return (
        <SettingsLayout expandedKeys={['finance', 'invoices']}>
            <div className="flex w-full justify-end">
                <Button
                    onClick={() => {
                        setDialogTitle('Create Premium')
                        setVisible(true)
                    }}
                >
                    Create
                </Button>
            </div>

            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={headerElement} footer={footerContent}
                    style={{ width: '50rem' }}
                    onHide={() => setVisible(false)}>
                <div className="grid grid-cols-2 gap-2 items-start">
                    <InputField
                        id="premium_name"
                        label="Name"
                        value={premium.name || ''}
                        onChange={(e) => setData('name', e.target.value)}
                    />

                    <SelectField
                        id="premium_type"
                        label="Type"
                        value={premium.type || ''}
                        onChange={(e) => setData('type', e.target.value)}
                        options={types}
                    />

                    <div className="col-span-2 mt-2">
                        <Label htmlFor="premium_amount">
                            Amount
                        </Label>
                        <div className="p-inputgroup flex-1 mt-2">
                            <InputNumber
                                inputId="premium_amount"
                                maxFractionDigits={2}
                                value={premium.config?.amount || ''}
                                onChange={(e) => setConfig('amount', e.value)}
                            />
                            <span className="p-inputgroup-addon">%</span>
                        </div>
                    </div>
                </div>
            </Dialog>

            <DataTable className="text-sm" value={premiums} lazy dataKey="id">
                <Column field="name" header="Name" />
                <Column field="type" header="Type" body={TypeTemplate} />
                <Column header="Amount" body={AmountTemplate} />
                <Column body={ActionsTemplate} />
            </DataTable>
        </SettingsLayout>
    )
}

export default SettingsPremiums