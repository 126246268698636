import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PortalLayout from './components/PortalLayout'
import { get, create, update } from 'services/portals'
import Card from 'components/card'
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import Button from 'components/buttons/Button'
import { states } from 'services/states'
import { all as allCompanies } from 'services/companies'
import { formatDatetime } from 'helpers/dates'

const EditPortal = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ portal, setPortal ] = useState({})
    const [ settings, setSettings ] = useState({})
    const [ companies, setCompanies ] = useState([])
    const [ companyId, setCompanyId ] = useState('')

    useEffect(() => {
        if (id) {
            get(id, ({ data }) => {
                setPortal(data.portal)

                if (! data.state_code) {
                    setCompanyId(data.entity_id)
                }

                const settings = {}

                data.portal.settings.forEach(setting => {
                    settings[setting.name] = setting.value
                })

                setSettings(settings)
            })
        }

        allCompanies({}, ({ data }) => {
            setCompanies(data.companies)
        })
    }, [])

    useEffect(() => {
        if (portal?.state_code) {
            setPortal({
                ...portal,
                entity_type: null,
                entity_id: ''
            })
            setCompanyId('')
        }
    }, [portal?.state_code])

    const setData = (k, v) => {
        setPortal({
            ...portal,
            [k]: v
        })
    }

    const selectCompany = (id) => {
        setCompanyId(id)
        setPortal({
            ...portal,
            entity_type: id ? 'App\\Models\\Company' : null,
            entity_id: id,
            state_code: id ? null : portal.state_code
        })
    }

    const submit = (e) => {
        e.preventDefault()

        portal.settings = settings

        if (id) {
            update(id, portal, ({ data }) => {
                navigate('/admin/portal/' + id)
            }, () => {

            })
        } else {
            create(portal, ({ data }) => {
                navigate('/admin/portal/' + data.portal.id)
            }, () => {

            })
        }
    }

    const updateSetting = (k, v) => {
        setSettings({
            ...settings,
            [k]: v
        })
    }

    return (
        <PortalLayout id={id} portal={portal}>
            <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <form className="flex flex-col gap-5" onSubmit={submit}>
                    <Card extra={"w-full !p-5"}>
                        {/* Header */}
                        <div className="w-full px-[8px]">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Portal Type
                            </h4>
                            <p className="mt-1 text-base text-gray-600">
                                Link a state or company whose auctions should be displayed
                            </p>
                        </div>
                        <div className="mt-7 mb-2 w-1/2 flex flex-col gap-3">
                            <SelectField
                                label="State"
                                id="state"
                                value={portal.state_code || ''}
                                onChange={(e) => setData('state_code', e.target.value)}
                                options={states}
                            />

                            <div className="text-center">
                                OR:
                            </div>

                            <SelectField
                                label="Company"
                                id="Company"
                                value={companyId || ''}
                                onChange={(e) => selectCompany(e.target.value)}
                                options={companies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.id
                                    }
                                })}
                            />
                        </div>
                    </Card>

                    <Card extra={"w-full !p-5"}>
                        {/* Header */}
                        <div className="w-full px-[8px]">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Portal Configuration
                            </h4>
                            <p className="mt-1 text-base text-gray-600">
                                Set up the portal
                            </p>
                        </div>

                        <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
                            <InputField
                                label="Domain Name"
                                id="domain"
                                type="text"
                                value={portal.domain || ''}
                                onChange={(e) => setData('domain', e.target.value)}
                                required
                                above={(
                                    <div className="text-xs pt-1">
                                        (or subdomain)
                                    </div>
                                )}
                            />
                        </div>

                        <div className="mt-7 mb-2 w-1/2 flex flex-col gap-3">
                            <label className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    checked={settings?.hide_peak_logo || false}
                                    onChange={(e) => updateSetting('hide_peak_logo', e.target.checked)}
                                />
                                <span>Hide Peak Logo</span>
                            </label>
                        </div>

                        <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
                            <InputField
                                label="Opens At"
                                id="opens_at"
                                type="datetime-local"
                                value={formatDatetime(portal.opens_at || '')}
                                onChange={(e) => setData('opens_at', e.target.value)}
                                above={(
                                    <div className="text-xs pt-1">
                                        (optional)
                                    </div>
                                )}
                            />

                            <InputField
                                label="Closes At"
                                id="closes_at"
                                type="datetime-local"
                                value={formatDatetime(portal.closes_at || '')}
                                onChange={(e) => setData('closes_at', e.target.value)}
                                above={(
                                    <div className="text-xs pt-1">
                                        (optional)
                                    </div>
                                )}
                            />
                        </div>
                    </Card>

                    <div className="mt-12 flex w-full justify-end">
                        <Button>Submit</Button>
                    </div>
                </form>
            </div>
        </PortalLayout>
    )
}

export default EditPortal