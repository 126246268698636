const Label = ({ htmlFor, variant, noMargin, state = '', children }) => {
    return (
        <label
            htmlFor={htmlFor}
            className={`block text-sm whitespace-nowrap ${
                variant === "auth" ? "ml-1.5 font-medium" : `${noMargin ? '' : 'ml-3'} font-bold`
            } ${
                state === "error" ? "text-red-500" : "text-navy-700 dark:text-white"
            }`}
        >
            {children}
        </label>
    )
}

export default Label