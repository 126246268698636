import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getApprovals } from 'services/auctions';
import Link from 'components/buttons/Link'
import pluralize from 'pluralize'
import RegistationActionPanel from './components/RegistrationActionPanel'
import RemoveFromAuctionModal from './components/modals/RemoveFromAuctionModal'
import EditRegistrationModal from './components/modals/EditRegistrationModal'

const AuctionApprovalsIndex = () => {
    const [ registrations, setRegistrations ] = useState([])
    const [ expandedRows, setExpandedRows ] = useState([])
    const [ activeRegistration, setActiveRegistration ] = useState(null)
    const [ visibleModal, setVisibleModal ] = useState(false)

    useEffect(() => {
        getApprovals({}, ({ data }) => {
            setRegistrations(data.registrations)
        })
    }, [])

    const headerTemplate = (registration) => {
        return registration.auction && (
            <>
                <span className="vertical-align-middle ml-2 font-bold line-height-3">
                    <span>Auction: </span>
                    <Link to={`/admin/auction/${registration.auction_id}`}>{registration.auction.name}</Link>
                </span>
            </>
        );
    };

    const footerTemplate = (registration) => {
        const count = calculateRegistrationCount(registration.auction)

        return (
            <>
                <td colSpan={6}>
                    <div className="flex justify-content-end font-bold w-full">{pluralize('Bidder', count)}: {count}</div>
                </td>
            </>
        );
    };

    const calculateRegistrationCount = (auction) => {
        let count = 0;

        if (! auction) {
            return count;
        }

        if (registrations) {
            for (let registration of registrations) {
                if (registration.auction_id === auction.id) {
                    count++;
                }
            }
        }

        return count;
    };

    const handleStatusUpdate = (registration, status) => {
        const updatedRegistrations = [...registrations]

        const updatedRegistration = updatedRegistrations.find(r => r.id === registration.id)
        updatedRegistration.status = status

        setRegistrations(updatedRegistrations)
    }

    const afterUpdate = (registration) => {
        setRegistrations(registrations.map(r => {
            if (r.id === registration.id) {
                return registration
            }

            return r
        }))
    }

    const removeRegistration = (registration) => {
        setRegistrations(
            registrations.filter(r => r.id !== registration.id)
        )
    }

    const edit = (registration) => {
        setActiveRegistration(registration)
        setVisibleModal('edit')
    }

    const remove = (registration) => {
        setActiveRegistration(registration)
        setVisibleModal('remove')
    }

    return (
        <>
            <div>
                <DataTable value={registrations} rowGroupMode="subheader" groupRowsBy="auction.id"
                           sortMode="single" sortField="auction.name" sortOrder={1}
                           expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                           rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate}>
                    <Column body={(registration) => {
                        return (
                            <>
                                <Link to={`/admin/user/${registration.user.id}`}>
                                    {registration.user.first_name} {registration.user.last_name} ({registration.user.email}
                                </Link>
                            </>
                        )
                    }} header="Bidder" style={{ width: '25%' }} />
                    <Column field="auction.dates.starts" header="Starts" style={{ width: '15%' }} />
                    <Column field="auction.dates.ends" header="Ends" style={{ width: '15%' }} />
                    <Column field="dates.created" header="Request Date" style={{ width: '15%' }} />
                    <Column body={(registration) => {
                        return (
                            <span className="capitalize">
                                {registration.status || 'Pending'}
                            </span>
                        )
                    }} header="Status" style={{ width: '15%' }} />
                    <Column body={(registration) => {
                        return (
                            <RegistationActionPanel
                                handleStatusUpdate={handleStatusUpdate}
                                edit={edit}
                                remove={remove}
                                registration={registration}
                            />
                        )
                    }} style={{ width: '15%' }} />
                </DataTable>

                <EditRegistrationModal
                    registration={activeRegistration}
                    setRegistration={setActiveRegistration}
                    visible={visibleModal}
                    setVisible={setVisibleModal}
                    then={(registration) => {
                        setActiveRegistration(null)
                        afterUpdate(registration)
                    }}
                />

                <RemoveFromAuctionModal
                    registration={activeRegistration}
                    visible={visibleModal}
                    setVisible={setVisibleModal}
                    then={(registration) => {
                        setActiveRegistration(null)
                        removeRegistration(registration)
                    }}
                />
            </div>
        </>
    )
}

export default AuctionApprovalsIndex