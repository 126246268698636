import Label from './Label'
import {InputNumber} from 'primereact/inputnumber'

const CurrencyField = ({ id, label, value, onChange }) => {
    return (
        <div>
            <Label htmlFor={id}>
                {label}
            </Label>
            <div className="p-inputgroup flex-1 mt-2">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber
                    inputId={id}
                    maxFractionDigits={2}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default CurrencyField