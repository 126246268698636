import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { can, useAuth } from 'components/auth/AuthContext'
import { all as getAllCompanies, index } from 'services/companies'
import { all as getAllClients } from 'services/clients'
import { CompanyTypes } from 'services/settings'
import Btn from 'components/buttons/Button'
import { FaEllipsisVertical, FaMagnifyingGlass, FaPencil, FaPlus } from 'react-icons/fa6'
import { MdBusiness } from 'react-icons/md'
import { FilterMatchMode } from 'primereact/api'
import { debounce } from 'lodash'
import { InputText } from 'primereact/inputtext'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import SelectField from 'components/fields/SelectField'

const CompaniesIndex = ({ client, company }) => {
    const auth = useAuth()
    const canCreate = can(auth, 'create_subcompanies')
    const canUpdate = can(auth, 'update_subcompanies')
    const canViewClients = can(auth, 'view_clients')
    const prefiltered = !!(client || company)
    const navigate = useNavigate()
    const [ companies, setCompanies ] = useState([])
    const [ companyTypes, setCompanyTypes ] = useState([])
    const [ clients, setClients ] = useState([])
    const [ allCompanies, setAllCompanies ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'companies.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'companies.consignor_code': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'companies.license_number': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'company_types.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'companies.type_id': { value: null, matchMode: FilterMatchMode.EQUALS },
        'companies.parent_id': { value: company?.id, matchMode: FilterMatchMode.EQUALS },
        'companies.client_id': { value: client?.id, matchMode: FilterMatchMode.EQUALS },
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        index({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify(filters)
        }, ({ data }) => {
            setCompanies(data.companies)
        })
    }, [page, sort, filters])

    useEffect(() => {
        CompanyTypes.index({}, ({ data }) => {
            setCompanyTypes(data.types)
        })

        if (! prefiltered) {
            getAllClients({}, ({ data }) => {
                setClients(data.clients)
            })

            getAllCompanies({}, ({ data }) => {
                setAllCompanies(data.companies)
            })
        }
    }, [])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const onCustomFilterChange = (e, name) => {
        const value = e.target.value;
        let _filters = { ... filters };

        _filters[name].value = value;

        onFilter({
            filters: _filters
        });
    }

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>

                <div>
                    <SelectField
                        options={companyTypes.map(type => {
                            return {
                                label: type.name,
                                value: type.id
                            }
                        })}
                        value={filters['companies.type_id'].value}
                        onChange={(e) => {
                            return onCustomFilterChange(e, 'companies.type_id')
                        }}
                        placeholder="Filter by type"
                    />
                </div>

                {! prefiltered && (
                    <>
                        {canViewClients && (
                            <div>
                                <SelectField
                                    options={clients.map(client => {
                                        return {
                                            label: client.name,
                                            value: client.id
                                        }
                                    })}
                                    value={filters['companies.client_id'].value}
                                    onChange={(e) => {
                                        return onCustomFilterChange(e, 'companies.client_id')
                                    }}
                                    placeholder="Filter by client"
                                />
                            </div>
                        )}

                        <div>
                            <SelectField
                                options={allCompanies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.id
                                    }
                                })}
                                value={filters['companies.parent_id'].value}
                                onChange={(e) => {
                                    return onCustomFilterChange(e, 'companies.parent_id')
                                }}
                                placeholder="Filter by parent company"
                            />
                        </div>
                    </>
                )}
            </div>
        )
    }

    const header = renderHeader()

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null);
        const items = [
            canUpdate ? {
                label: 'Edit',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/company/${rowData.id}`)
                }
            } : {
                label: 'View',
                icon: <MdBusiness className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/company/${rowData.id}`)
                }
            },
        ]

        return (
            <>
                <Menu model={items} popup ref={menu} id="popup_menu_right" popupAlignment="right" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} className="mr-2" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
            </>
        )
    }

    return (
        <div className="mt-3">
            {canCreate && (
                <div className="pt-3 mb-3 flex justify-end">
                    <Btn href="/admin/companies/create">
                        <span className="flex gap-2 items-center">
                            <FaPlus className="w-5 h-5" />
                            <span>New Company</span>
                        </span>
                    </Btn>
                </div>
            )}

            {companies && (
                <DataTable className="text-sm" value={companies.data} lazy dataKey="id" paginator
                           filters={filters} filterDisplay="menu" onFilter={onFilter} globalFilterFields={['companies.name', 'companies.consignor_code', 'companies.license_number']}
                           first={companies.from - 1} rows={companies.per_page} totalRecords={companies.total} onPage={onPage}
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           rowClassName="cursor-pointer"
                           onRowClick={(e) => {
                               navigate(`/admin/company/${e.data.id}`)
                           }}
                           header={header}>
                    <Column field="name" sortField="companies.name" filterField="companies.name" header="Name" filter sortable />
                    <Column field="consignor_code" sortField="companies.consignor_code" filterField="companies.consignor_code" filter sortable header="Consignor Code" headerClassName="whitespace-nowrap" style={{ minWidth: '180px' }} />
                    <Column field="type.name" sortField="company_types.name" filterField="company_types.name" filter sortable header="Type" />
                    <Column field="license_number" sortField="companies.license_number" filterField="companies.license_number" filter sortable header="License Number" headerClassName="whitespace-nowrap" style={{ minWidth: '180px' }} />
                    <Column body={MenuBodyTemplate}></Column>
                </DataTable>
            )}
        </div>
    )
}

export default CompaniesIndex