import axios from 'axios'

export const loadReport = (params, cancelToken, then, error) => {
    axios.get(process.env.REACT_APP_API_URL + '/report', {
        params,
        cancelToken
    })
        .then(then)
        .catch(error)
}

export const loadFields = (report, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/reports/fields/' + report, {
        params
    }).then(then)
}
