import { createContext, useContext, useReducer } from 'react'

export const EmailContext = createContext(null)
export const EmailDispatchContext = createContext(null)

export const EmailProvider = ({ initial = [], children }) => {
    const [ notifications, dispatch ] = useReducer(emailReducer, initial)

    return (
        <EmailContext.Provider value={notifications}>
            <EmailDispatchContext.Provider value={dispatch}>
                {children}
            </EmailDispatchContext.Provider>
        </EmailContext.Provider>
    )
}

export function useEmail() {
    return useContext(EmailContext);
}

export function useEmailDispatch() {
    return useContext(EmailDispatchContext);
}

const emailReducer = (email, action) => {
    switch (action.type) {
        case 'show': {
            return {
                ...action.email,
                visible: true
            };
        }

        case 'update': {
            return {
                ...action.email,
                visible: true
            };
        }

        case 'hide': {
            return {
                ...action.email
            }
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}