import {Link, useNavigate, useParams} from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
    get,
    update,
    createPayment,
    updatePayment,
    deletePayment,
    createExpense,
    updateExpense,
    deleteExpense
} from 'services/invoices'
import Breadcrumbs from 'components/navbar/Breadcrumbs'
import { formatCurrency } from 'helpers/currency'
import { FaExternalLinkAlt } from 'react-icons/fa'
import {
    FaCcStripe,
    FaEnvelope,
    FaPencil,
    FaPlus,
    FaPrint,
    FaTrashCan
} from 'react-icons/fa6'
import ModalBillingAddress from './components/ModalBillingAddress'
import ModalShippingAddress from './components/ModalShippingAddress'
import ModalPremium from './components/ModalPremium'
import ModalTax from './components/ModalTax'
import ModalExpenses from './components/ModalExpenses'
import AddPaymentModal from './components/AddPaymentModal'
import { type } from '@testing-library/user-event/dist/type'
import Button from 'components/buttons/Button'
import SplitButton from 'components/buttons/SplitButton'
import { useEmailDispatch } from 'components/email/EmailContext'

const Edit = () => {
    const navigate = useNavigate()
    const emailDispatch = useEmailDispatch()
    const { id } = useParams()
    const [invoice, setInvoice] = useState(null)
    
    const [showBillingModal, setShowBillingModal] = useState(false)
    const [showShippingModal, setShowShippingModal] = useState(false)
    const [showPremiumModal, setShowPremiumModal] = useState(false)
    const [showTaxModal, setShowTaxModal] = useState(false)
    const [showExpensesModal, setShowExpensesModal] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)

    const [modalFieldValues, setModalFieldValues] = useState({});

    const loadInvoice = () => {
        get(id, ({ data }) => {
            setInvoice(data.invoice)
        })
    }

    useEffect(() => {
        loadInvoice()
    }, [])

    if (! invoice) {
        return
    }

    const billingAddress = invoice.addresses.filter(address => address.type === 'Billing')[0]
    const shippingAddress = invoice.addresses.filter(address => address.type === 'Shipping')[0]

    const totals = {
        qty: 0,
        bid: 0,
        premium: 0,
        tax: 0,
        expenses: 0,
        total: 0
    }

    const handleEditClick = (fieldValues, modal) => {
        setModalFieldValues(fieldValues);

        if (modal === 'billing') {
            setShowBillingModal(true);
            return;
        }

        if (modal === 'shipping') {
            setShowShippingModal(true);
            return;
        }

        if (modal === 'premium') {
            setShowPremiumModal(true);
            return;
        }

        if (modal === 'tax') {
            setShowTaxModal(true);
            return;
        }

        if (modal === 'expenses') {
            setShowExpensesModal(true);
            return;
        }
    };

    const setData = (key, value) => {
        setModalFieldValues({
            ...modalFieldValues,
            [key]: value
        });
    };

    const handleSave = async (newValues, type) => {
        // Update the invoice with the new values
        const updatedInvoice = {
            ...invoice,
            ...newValues,
        };

        update(id, {...newValues, type: type}, ({ data }) => {
            navigate('/admin/invoices/')
        })

        setInvoice(updatedInvoice);
    };

    const handleSavePremium = async (newValues) => {
        update(id, {...newValues, type: type}, ({ data }) => {
            loadInvoice()
        })
    }

    const handleSaveTax = async (newValues) => {
        update(id, {...newValues, type: type}, ({ data }) => {
            loadInvoice()
        })
    }

    const handleSaveExpenses = async (newValues) => {
        if (newValues.id) {
            updateExpense(id, newValues.id, newValues, ({ data }) => {
                loadInvoice()
            })
        } else {
            createExpense(id, newValues, ({ data }) => {
                loadInvoice()
            })
        }
    }

    const handleDeleteExpense = (expense_id) => {
        if (! window.confirm('Are you sure you want to delete?')) {
            return
        }

        deleteExpense(id, expense_id, () => {
            loadInvoice()
        })
    }

    const handleSubmitPayment = (id, data, then) => {
        if (data.id) {
            updatePayment(id, data.id, data, (response) => {
                then(response)
                loadInvoice()
            })
        } else {
            createPayment(id, data, (response) => {
                then(response)
                loadInvoice()
            })
        }
    }

    const handleDeletePayment = (payment_id) => {
        if (! window.confirm('Are you sure you want to delete?')) {
            return;
        }

        deletePayment(id, payment_id, () => {
            loadInvoice();
        });
    }

    const showRelatedExpenses = (expenses) => {
        return (
            <>
                {expenses.map(expense => (
                    <div key={expense.id} className="flex gap-4 items-center justify-between text-left py-2">
                        <div className="w-1/2">{expense.description}</div>
                        <div className="w-1/4 text-right">
                            {formatCurrency(expense.calculated_amount)}
                        </div>
                        <div className="flex gap-2 items-center">
                            <button
                                onClick={() => handleEditClick(expense, 'expenses')}
                                className="text-slate-400 hover:text-slate-600">
                                <FaPencil />
                            </button>
                            <button
                                onClick={() => handleDeleteExpense(expense.id)}
                                className="text-red-400 hover:text-red-600">
                                <FaTrashCan />
                            </button>
                        </div>
                    </div>
                ))}

                {expenses.length == 0 && (
                    <em>None</em>
                )}
            </>
        )
    }

    const sendEmail = (title, subject) => {
        emailDispatch({
            type: 'show',
            email: {
                modal: {
                    title
                },
                subject,
                invoice_id: invoice.id
            }
        })
    }

    return (
        <div>
            <ModalBillingAddress
                show={showBillingModal}
                onClose={() => setShowBillingModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSave}
                setData={setData}
            />

            <ModalShippingAddress
                show={showShippingModal}
                onClose={() => setShowShippingModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSave}
                setData={setData}
            />

            <ModalPremium
                show={showPremiumModal}
                onClose={() => setShowPremiumModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSavePremium}
                setData={setData}
            />

            <ModalTax
                show={showTaxModal}
                onClose={() => setShowTaxModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSaveTax}
                setData={setData}
            />

            <ModalExpenses
                show={showExpensesModal}
                onClose={() => setShowExpensesModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSaveExpenses}
                setData={setData}
            />

            <AddPaymentModal
                invoice={invoice}
                payment={modalFieldValues}
                visible={showPaymentModal}
                setVisible={setShowPaymentModal}
                submit={handleSubmitPayment}
            />

            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/auctions",
                        title: "Auctions"
                    },
                    {
                        href: `/admin/auction/${invoice.auction_id}`,
                        title: invoice.auction.name
                    },
                    {
                        href: `/admin/auction/${invoice.auction_id}/invoices`,
                        title: "Invoices"
                    },
                    {
                        href: "#",
                        title: `Invoice #${invoice.number}`
                    }
                ]} />
            </div>

            <div className="mt-3 w-full">
                <div className="pt-6 flex justify-center gap-12 items-start">
                    <div>
                        <div className="text-lg font-bold flex gap-2 items-center">
                            Billing Address

                            <button
                                onClick={() => handleEditClick({
                                    first_name: billingAddress?.first_name || '',
                                    last_name: billingAddress?.last_name || '',
                                    address_line1: billingAddress?.address_line1 || '',
                                    address_line2: billingAddress?.address_line2 || '',
                                    city: billingAddress?.city || '',
                                    state: billingAddress?.state || '',
                                    zip: billingAddress?.zip || ''
                                }, 'billing')}
                                className="text-slate-400 hover:text-slate-600">
                                <FaPencil className="w-4 h-4" />
                            </button>

                            <Link to={`/admin/user/${invoice.buyer_id}/edit`}
                                  className="text-slate-400 hover:text-slate-600">
                                <FaExternalLinkAlt className="w-4 h-4" />
                            </Link>
                        </div>
                        {billingAddress ? (
                            <div>
                                {billingAddress.first_name} {billingAddress.last_name}<br />
                                {billingAddress.address_line1}<br />
                                {billingAddress.address_line2 && (
                                    <div>
                                        {billingAddress.address_line2}
                                    </div>
                                )}
                                {billingAddress.city}, {billingAddress.state} {billingAddress.zip}
                            </div>
                        ) : 'N/A'}
                    </div>
                    <div>
                        <div className="text-lg font-bold flex gap-2 items-center">
                            Shipping Address

                            <button
                                onClick={() => handleEditClick({
                                    first_name: shippingAddress?.first_name || '',
                                    last_name: shippingAddress?.last_name || '',
                                    address_line1: shippingAddress?.address_line1 || '',
                                    address_line2: shippingAddress?.address_line2 || '',
                                    city: shippingAddress?.city || '',
                                    state: shippingAddress?.state || '',
                                    zip: shippingAddress?.zip || ''
                                }, 'shipping')}
                                className="text-slate-400 hover:text-slate-600">
                                <FaPencil className="w-4 h-4" />
                            </button>
                        </div>
                        {shippingAddress ? (
                            <div>
                                {shippingAddress.first_name} {shippingAddress.last_name}<br />
                                {shippingAddress.address_line1}<br />
                                {shippingAddress.address_line2 && (
                                    <div>
                                        {shippingAddress.address_line2}
                                    </div>
                                )}
                                {shippingAddress.city}, {shippingAddress.state} {shippingAddress.zip}
                            </div>
                        ) : 'N/A'}
                    </div>
                    <div>
                        {invoice.dates.created}
                    </div>
                    <div className={"uppercase text-2xl font-bold " + (invoice.status === 'paid' ? 'text-green-600' : 'text-red-600')}>
                        {invoice.status}
                    </div>
                    <div className="flex gap-2 items-center">
                        <SplitButton
                            items={[
                                { name: 'Email Unpaid', onClick: () => sendEmail('Send Unpaid Email Notification to Buyer', 'Your invoice is unpaid') },
                                { name: 'Email Past Due', onClick: () => sendEmail('Send Past Due Notification to Buyer', 'Your invoice is past due') },
                                { name: 'Email Payment Due', onClick: () => sendEmail('Send Payment Due Notification to Buyer', 'Your invoice payment is due') },
                                { name: 'Email Paid Receipt', onClick: () => sendEmail('Send Paid Receipt Notification to Buyer', 'Your invoice is unpaid') },
                            ]}
                            onClick={() => sendEmail('Email Invoice', 'Your invoice is ready')}
                        >
                            <FaEnvelope className="w-5 h-5" />
                        </SplitButton>

                        <Button color="outline" href={`${process.env.REACT_APP_BASE_URL}/admin/invoices/${invoice.id}/download`} target="_blank">
                            <FaPrint className="w-5 h-5" />
                        </Button>
                    </div>
                </div>
                <table className="mt-6 w-full text-right">
                    <thead>
                    <tr className="bg-slate-200">
                        <th className="px-4 py-2 text-left">Description</th>
                        <th className="px-4 py-2">Qty.</th>
                        <th className="px-4 py-2">Bid</th>
                        <th className="px-4 py-2">Premium</th>
                        <th className="px-4 py-2">Tax</th>
                        <th className="px-4 py-2">Expenses</th>
                        <th className="px-4 py-2">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {invoice.items.map(item => {
                        totals.qty += 1 * item.qty
                        totals.bid += 1 * item.bid
                        totals.premium += 1 * item.premium
                        totals.tax += 1 * item.tax
                        totals.expenses += 1 * item.expenses
                        totals.total += 1 * item.total

                        return (
                            <tr key={item.id}>
                                <td className="px-4 py-2 text-left">
                                    <b>
                                        <Link
                                            to={`/admin/lot/${item.lot_id}`}
                                            className="text-slate-400 hover:text-slate-600"
                                        >{item.title}</Link>
                                    </b><br />
                                    {item.description}
                                </td>
                                <td className="px-4 py-2 align-top">{item.qty}</td>
                                <td className="px-4 py-2 align-top">{formatCurrency(item.bid, 2)}</td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {formatCurrency(item.premium, 2)}
                                    </div>

                                    <button onClick = {() => handleEditClick({
                                            premium: item.premium,
                                            feeType: item.feeType || 'flat',
                                            item
                                        }, 'premium')}
                                        title="Override Premium"
                                        className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                    >
                                        <span>Edit</span>
                                        <FaPencil className="w-3 h-3" />
                                    </button>
                                </td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {formatCurrency(item.tax, 2)}
                                    </div>

                                    <button onClick = {() => handleEditClick({
                                            tax: item.tax,
                                            item
                                        }, 'tax')}
                                            title="Edit Tax Rate"
                                            className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                    >
                                        <span>Edit</span>
                                        <FaPencil className="w-3 h-3" />
                                    </button>
                                </td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {showRelatedExpenses(item.related_expenses)}
                                    </div>

                                    <button onClick={() => handleEditClick({
                                            item_id: item.id,
                                            expense_group_id: '',
                                            amount: '',
                                            description: '',
                                            type: 'flat',
                                            item
                                        }, 'expenses')}
                                            title="Add Lot Expense"
                                            className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                    >
                                        <span>Add</span>
                                        <FaPlus className="w-3 h-3" />
                                    </button>
                                </td>
                                <td className="px-4 py-2 align-top">{formatCurrency(item.total, 2)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th className="px-4 py-2 text-left">Totals</th>
                        <th className="px-4 py-2">{totals.qty}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.bid, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.premium, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.tax, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.expenses, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.total, 2)}</th>
                    </tr>
                    </tfoot>
                </table>
                <div className="mt-6 flex flex-col items-end gap-4 text-right">
                    <div className="w-1/4">
                        <div className="text-lg font-bold flex gap-2 items-center justify-between">
                            <span>
                                Expenses
                            </span>

                            <button onClick={() => handleEditClick({
                                expense_group_id: '',
                                amount: '',
                                description: '',
                                type: 'flat'
                            }, 'expenses')}
                                title="Add Expense"
                                className="text-white text-sm inline-flex gap-1 items-center bg-slate-400 hover:bg-slate-600 rounded px-1.5 py-1"
                            >
                                <FaPlus className="w-4 h-4" />
                            </button>
                        </div>

                        {showRelatedExpenses(invoice.related_expenses)}
                    </div>

                    <div className="w-1/4">
                        <div className="text-lg font-bold flex gap-2 items-center justify-between">
                            <span>
                                Payments
                            </span>

                            <button onClick={() => {
                                setModalFieldValues({
                                    payment_type_id: '',
                                    payment_type_name: '',
                                    amount: invoice.balance || 0.00
                                });
                                setShowPaymentModal(true);
                            }}
                                    title="Add Payment"
                                    className="text-white text-sm inline-flex gap-1 items-center bg-slate-400 hover:bg-slate-600 rounded px-1.5 py-1"
                            >
                                <FaPlus className="w-4 h-4" />
                            </button>
                        </div>

                        {invoice.related_payments.map(payment => (
                            <div key={payment.id} className={"flex gap-4 items-center justify-between text-left py-2 " + (payment.deleted_at ? 'line-through' : '')}>
                                <div className="w-1/2 flex gap-2 items-center">
                                    {payment.type.name}

                                    {payment.transaction?.stripe_url && (
                                        <a href={payment.transaction.stripe_url} target="_blank"
                                           className={payment.transaction.stripe_url}>
                                            <FaCcStripe className="text-3xl text-[#5433FF]" />
                                        </a>
                                    )}
                                </div>
                                <div className="w-1/4 text-right">
                                    {formatCurrency(payment.amount)}
                                </div>
                                <div className={"flex gap-2 items-center " + (payment.deleted_at ? 'invisible' : '')}>
                                    <button
                                        onClick={() => {
                                            setModalFieldValues({...payment});
                                            setShowPaymentModal(true);
                                        }}
                                        className="text-slate-400 hover:text-slate-600">
                                        <FaPencil />
                                    </button>
                                    <button
                                        onClick={() => handleDeletePayment(payment.id)}
                                        className="text-red-400 hover:text-red-600">
                                        <FaTrashCan />
                                    </button>
                                </div>
                            </div>
                        ))}

                        {invoice.related_payments.length == 0 && (
                            <em>None</em>
                        )}
                    </div>

                    <table className="w-1/4">
                        <tbody>
                        <tr>
                            <td>Subtotal:</td>
                            <td>{formatCurrency(invoice.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td>Expenses:</td>
                            <td>{formatCurrency(invoice.expenses, 2)}</td>
                        </tr>
                        <tr>
                            <td>Taxes:</td>
                            <td>{formatCurrency(invoice.taxes, 2)}</td>
                        </tr>
                        <tr className="border border-0 border-b border-slate-300">
                            <th>Invoice Total:</th>
                            <th>{formatCurrency(invoice.total, 2)}</th>
                        </tr>
                        <tr>
                            <td>Payments:</td>
                            <td>{formatCurrency(invoice.payments, 2)}</td>
                        </tr>
                        <tr>
                            <td>Balance:</td>
                            <td>{formatCurrency(invoice.balance, 2)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Edit