import CheckboxField from 'components/fields/CheckboxField'
import CurrencyField from 'components/fields/CurrencyField'
import InputField from 'components/fields/InputField'
import TextField from 'components/fields/TextField'
import BaseSettings from '../components/BaseSettings'

const MaxChargeValue = () => {
    const settings = [
        'max_charge_value',
        'hide_disabled_pay_button',
        'disabled_pay_button_text',
        'default_max_charge_warning_message'
    ]

    return (
        <BaseSettings only={settings} expandedKeys={['finance', 'invoices']}>
            {(settings, updateSetting) => (
                <div>
                    <div className="mb-4">
                        <CurrencyField
                            id="max_charge_value"
                            label="Maximum Charge Value"
                            value={settings.max_charge_value?.value || ''}
                            onChange={(e) => updateSetting('max_charge_value', e.value)}
                        />
                    </div>
                    {! settings.hide_disabled_pay_button?.value && (
                        <div className="mb-4">
                            <InputField
                                id="disabled_pay_button_text"
                                label="Disabled Pay Button Text"
                                value={settings.disabled_pay_button_text?.value || ''}
                                onChange={(e) => updateSetting('disabled_pay_button_text', e.target.value)}
                            />
                        </div>
                    )}
                    <div className="mb-4 mx-4">
                        <CheckboxField
                            label="Hide Disabled Pay Button"
                            checked={settings.hide_disabled_pay_button?.value || false}
                            onChange={(e) => updateSetting('hide_disabled_pay_button', e.target.checked)}
                        />
                    </div>
                    <div className="mb-4">
                        <TextField
                            id="default_max_charge_warning_message"
                            label="Default Max Charge Warning Message"
                            value={settings.default_max_charge_warning_message?.value || ''}
                            onChange={(e) => updateSetting('default_max_charge_warning_message', e.target.value)}
                        />
                    </div>
                </div>
            )}
        </BaseSettings>
    )
}

export default MaxChargeValue