import {useEffect, useMemo, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from 'components/buttons/Button'
import { index } from 'services/portals'
import { InputText } from 'primereact/inputtext'
import { TabView, TabPanel } from 'primereact/tabview'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import {
    FaMagnifyingGlass,
    FaPlus,
} from 'react-icons/fa6'
import {debounce} from 'lodash'

const PortalsIndex = () => {
    const navigate = useNavigate()
    const [ portals, setPortals ] = useState([])
    const [ filters, setFilters ] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        domain: { value: null, matchMode: FilterMatchMode.CONTAINS },
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })

    useEffect(() => {
        index({}, ({ data }) => {
            setPortals(data.portals)
        })
    }, [])

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </div>
        )
    }

    const header = renderHeader()

    return (
        <div className="mt-3">
            <div className="pt-3 mb-3 flex justify-end">
                <Button href="/admin/portals/create">
                    <span className="flex gap-2 items-center">
                        <FaPlus className="w-5 h-5" />
                        <span>New Portal</span>
                    </span>
                </Button>
            </div>

            <DataTable className="text-sm" value={portals} dataKey="id" name="test"
                       filters={filters} filterDisplay="menu" globalFilterFields={['name', 'domain']}
                       onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                       rowClassName="cursor-pointer"
                       onRowClick={(e) => {
                           navigate(`/admin/portal/${e.data.id}`)
                       }}
                       header={header}>
                <Column field="name" header="Name" filter sortable />
                <Column field="domain" header="Domain" filter sortable />
            </DataTable>
        </div>
    )
}

export default PortalsIndex