import { useEffect, useState } from 'react'
import { all as allAuctions } from 'services/auctions'
import { assign, bulkAssign } from 'services/items'
import ModalForm from 'components/modal/ModalForm'
import SelectField from 'components/fields/SelectField'
import InputField from 'components/fields/InputField'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const AssignModal = ({ item, items, resetItems, open, setOpen }) => {
    const notify = useNotificationsDispatch()
    const [ auctions, setAuctions ] = useState([])
    const [ data, setData ] = useState({
        auction_id: null,
        qty: 1,
        lot_number: null,
        next_lot_number: true,
        item_ids: []
    })

    useEffect(() => {
        allAuctions({}, ({ data }) => {
            setAuctions(data.auctions)
        })
    }, [])

    useEffect(() => {
        setValue('item_ids', items)
    }, [items])

    const setValue = (k, v) => {
        setData({
            ...data,
            [k]: v
        })
    }

    return (
        <ModalForm
            title="Assign to Auction"
            open={open}
            setOpen={setOpen}
            onSubmit={(e, after) => {
                const callback = () => {
                    if (resetItems) {
                        resetItems()
                    }

                    after()

                    notify({
                        message: 'Assigned to auction.',
                        title: 'Success!',
                        type: 'success',
                    })
                }

                if (item) {
                    assign(item.id, data, callback)
                } else {
                    bulkAssign(data, callback)
                }
            }}
        >
            <div className="flex flex-col gap-4 justify-center mt-6 mb-2">
                <SelectField
                    options={auctions?.map(auction => {
                        return {
                            label: auction.name,
                            value: auction.id
                        }
                    })}
                    value={data.auction_id || ''}
                    onChange={(e) => setValue('auction_id', e.target.value)}
                    label="Auction"
                />

                <InputField
                    label="Quantity"
                    value={data.qty}
                    onChange={(e) => setValue('qty', e.target.value)}
                />

                <div>
                    <InputField
                        label="Lot Number"
                        value={data.lot_number || ''}
                        disabled={data.next_lot_number}
                        onChange={(e) => setValue('lot_number', e.target.value)}
                    />

                    <label className="flex gap-2 items-center mt-2">
                        <input type="checkbox"
                               checked={data.next_lot_number}
                               onChange={(e) => setValue('next_lot_number', e.target.checked)}
                        />
                        <span>
                                Use Next Available Lot Number
                            </span>
                    </label>
                </div>
            </div>
        </ModalForm>
    )
}

export default AssignModal