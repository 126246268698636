import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { removeFromAuction } from 'services/auctions'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const RemoveFromAuctionModal = ({ registration, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()

    const remove = () => {
        removeFromAuction(registration.id, () => {
            setVisible(false)

            notify({
                message: 'User has been removed from auction.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(registration)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Remove Bidder From Auction
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={remove}>
                OK
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'remove'}
                header={header} footer={footer}
                onHide={() => setVisible(false)}>
            Are you sure you want to remove this bidder from the auction?
        </Dialog>
    )
}

export default RemoveFromAuctionModal