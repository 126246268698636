import {
    FaCircleXmark
} from 'react-icons/fa6'
import FilterType from './FilterType'
import { FilterMatchMode } from 'primereact/api'
import {
    BooleanFilterModes, FilterModes
} from './FilterModes'

const FilterPill = ({ filter, k, remove }) => {
    let label = filter.label
    let value = filter.value

    const bool = filter.type === FilterType.BOOLEAN
    const modes = bool ? BooleanFilterModes : FilterModes
    const modeLabel = modes.filter(m => m.value === filter.matchMode)[0].label.toLowerCase()

    if (filter.type === FilterType.SELECT) {
        value = filter.options.filter(o => o.value == value)[0]?.label
    }

    if (filter.type === FilterType.BOOLEAN) {
        if (filter.matchMode === FilterMatchMode.EQUALS) {
            label = 'Is ' + label
        } else {
            label = 'Is Not ' + label
        }
    } else {
        label += ' ' + modeLabel + ' ' + value
    }

    return (
        <div className="bg-slate-200 hover:bg-slate-300 hover:text-red-600 pointer-events-none rounded-lg cursor-pointer flex gap-2 items-center px-3 py-1">
            {label}

            <FaCircleXmark
                className="pointer-events-auto"
                onClick={() => remove(filter, k)}
            />
        </div>
    )
}

const AdditionalFiltersDisplay = ({ filters, setFilters }) => {
    const active = Object.entries(filters).filter(([k, filter]) => {
        return filter.matchMode
    })

    const remove = (filter, k) => {
        const updated = {
            ...filters
        }

        updated[k] = {
            ...filter,
            matchMode: false,
            value: null
        }

        setFilters(updated)
    }

    return (
        <div className="flex gap-4 items-center">
            {active.map(([ k, filter ]) => {
                return (
                    <FilterPill
                        k={k}
                        key={k}
                        filter={filter}
                        remove={remove}
                    />
                )
            })}
        </div>
    )
}

export default AdditionalFiltersDisplay