import { useEffect, useState } from 'react'
import { all } from 'services/auctions'
import SelectField from 'components/fields/SelectField'

const AuctionFilter = ({ value, setFilter }) => {
    const [ auctions, setAuctions ] = useState([])

    useEffect(() => {
        all({}, ({ data }) => {
            setAuctions(data.auctions)
        })
    }, [])

    return (
        <div className="flex gap-2 items-center">
            <label>
                Auction
            </label>

            <SelectField
                extra="mb-3"
                id="auction_id"
                value={value}
                onChange={(e) => {
                    setFilter('auction', e.target.value)
                }}
                options={auctions.map(auction => {
                    return {
                        label: auction.name,
                        value: auction.id
                    }
                })}
            />
        </div>
    )
}

export default AuctionFilter