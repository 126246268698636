import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { highlights as loadHighlights } from 'services/auctions';
import AuctionLayout from './components/AuctionLayout';
import AuctionPhotoGallery from '../auctions/components/AuctionPhotoGallery';
import VideoLinkGallery from '../lots/components/VideoLinkGallery';

const AuctionHighlights = () => {
  const { id } = useParams();
  const [auction, setAuction] = useState(null);

  useEffect(() => {
    loadHighlights(id, {}, ({ data }) => {
      setAuction(data.auction);
    });
  }, []);

  if (!auction) {
    return;
  }

  return (
    <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
      <div className="mt-3">
        <p className="mb-4">
          Auction highlights are pictures of the auction to give bidders a
          preview of what is in the auction. The first image is used for the
          auction's image.
        </p>

        <AuctionPhotoGallery auction={auction} />

        <VideoLinkGallery asset={auction} assetType="auction" />
      </div>
    </AuctionLayout>
  );
};

export default AuctionHighlights;
