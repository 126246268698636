import Breadcrumbs from 'components/navbar/Breadcrumbs'
import VerticalMenu from 'components/menu/VerticalMenu'
import noteTypes from 'components/notes/NoteTypes'
import { can, useAuth } from 'components/auth/AuthContext'

const CompanyLayout = ({ id, company, children }) => {
    const auth = useAuth()
    const canUpdate = can(auth, 'update_companies')
    const canManageUsers = can(auth, 'view_users')

    const menu = [
        { name: 'Dashboard', href: '/admin/company/' + id },
        canUpdate ? { name: 'Edit', href: `/admin/company/${id}/edit` } : null,
        { name: 'Auctions', href: `/admin/company/${id}/auctions` },
        { name: 'Sub-companies', href: `/admin/company/${id}/companies` },
        canManageUsers ? { name: 'Users', href: `/admin/company/${id}/users` } : null,
        { name: 'Statements', href: `/admin/company/${id}/statements` }
    ].filter(item => !!item)

    menu.forEach(item => {
        item.current = (window.location.pathname === item.href)
    })

    if (! id) {
        return (
            <div className="w-full">
                {children}
            </div>
        )
    }

    return (
        <>
            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/companies",
                        title: "Companies"
                    },
                    company.parent ? {
                        href: "/admin/company/" + company.parent.id,
                        title: company.parent.name
                    } : null,
                    {
                        href: "#",
                        title: company.name
                    }
                ].filter(el => el !== null)} />
            </div>

            <div className="pt-4">
                <div className="flex gap-6">
                    <VerticalMenu items={menu} />

                    <div className="w-full">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyLayout