import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { get } from 'services/companies'
import { can, useAuth } from 'components/auth/AuthContext'
import CompanyLayout from './components/CompanyLayout'
import {
    FaBoxesStacked,
    FaChartLine,
    FaCircleDollarToSlot,
    FaFileInvoice,
    FaFileInvoiceDollar,
    FaTags,
    FaUsers
} from 'react-icons/fa6'
import {
    MdGavel
} from 'react-icons/md'
import { AuctionStats } from '../auctions/components/AuctionStats'
import { formatCurrency } from 'helpers/currency'
import ActionPanel from 'components/panels/ActionPanel'
import DeleteCompanyModal from './components/modals/DeleteCompanyModal'

const Dashboard = () => {
    const navigate = useNavigate()

    const { id } = useParams()
    const [ company, setCompany ] = useState(null)
    const [ stats, setStats ] = useState([])
    const [ visibleModal, setVisibleModal ] = useState(null)

    const auth = useAuth()
    const canDelete = can(auth, 'delete_companies')

    useEffect(() => {
        get(id, ({ data }) => {
            setCompany(data.company)
        })
    }, [id])

    useEffect(() => {
        if (! company) {
            return
        }

        setStats(
            buildStats()
        )
    }, [company])

    const buildStats = () => {
        return [
            {
                icon: MdGavel,
                title: 'Auctions',
                value: company.counts.auctions,
                link: `/admin/company/${company.id}/auctions`
            },
            {
                icon: FaUsers,
                title: 'Users',
                value: company.counts.users,
                link: `/admin/company/${company.id}/users`
            },
            {
                icon: FaTags,
                title: 'Lots',
                value: company.counts.lots,
                sub: company.counts.lots_with_bids + ' w/ bids'
            },
            {
                icon: FaBoxesStacked,
                title: 'On Hand',
                value: company.counts.inventory_on_hand
            },
            {
                icon: FaFileInvoice,
                title: 'Statements',
                value: formatCurrency(company.stats.statement_total) || '$0.00',
                sub: (formatCurrency(company.stats.statement_balance) || '$0.00') + ' due'
            },
        ]
    }

    if (! company) {
        return
    }

    return (
        <CompanyLayout id={id} company={company}>
            <div className="mb-8">
                <AuctionStats stats={stats} />
            </div>

            <div>
                {canDelete && (
                    <ActionPanel
                        color="red"
                        title="Delete Company"
                        action="Delete"
                        onClick={() => setVisibleModal('delete')}
                    >
                        Delete this company and everything associated with it, including auctions, lots, invoices and statements
                    </ActionPanel>
                )}

                <DeleteCompanyModal
                    company={company}
                    visible={visibleModal}
                    setVisible={setVisibleModal}
                    then={() => {
                        navigate('/admin/companies')
                    }}
                />
            </div>
        </CompanyLayout>
    )
}

export default Dashboard