export const formatCurrency = (price, decimals) => {
    if (! price) {
        return
    }

    const options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals
    }

    const formatter = new Intl.NumberFormat('en-US', options)

    return formatter.format(price)
}
