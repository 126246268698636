import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ItemLayout from './components/ItemLayout'
import NoteForm from 'components/notes/NoteForm'
import { get } from 'services/items'

const ItemNotes = () => {
    const entityType = 'App\\Models\\Item'
    const { id } = useParams()
    const [ item, setItem ] = useState(null)

    useEffect(() => {
        loadItem()
    }, [id])

    const loadItem = () => {
        if (! id) {
            return
        }

        get(id, ({ data }) => {
            setItem(data.item)
        })
    }

    const afterSubmitNote = () => {
        loadItem()
    }

    if (! item) {
        return
    }

    return (
        <ItemLayout id={id} item={item}>
            <div className="mt-3">
                <NoteForm
                    note={{
                        notable_id: id,
                        notable_type: entityType,
                        options: {
                            type: 'primary'
                        }
                    }}
                    afterSubmit={afterSubmitNote}
                />

                {item.notes.map((n) => (
                    <div key={n.id}
                         className="rounded border border-gray-100 mt-4 p-2"
                    >
                        <div className="flex justify-between items-center">
                            <div className="font-bold">{n.author.first_name} {n.author.last_name}</div>
                            <div>{n.dates.created}</div>
                        </div>

                        <NoteForm
                            note={n}
                            afterSubmit={afterSubmitNote}
                        />
                    </div>
                ))}
            </div>
        </ItemLayout>
    )
}

export default ItemNotes