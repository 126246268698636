import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get, updateConfig } from 'services/lots'
import LotLayout from './components/LotLayout'
import {
    FaChartLine,
    FaCircleDollarToSlot,
    FaEye,
    FaStreetView,
} from 'react-icons/fa6'
import { can, useAuth } from 'components/auth/AuthContext'
import { AuctionStats } from '../auctions/components/AuctionStats'
import Link from 'components/buttons/Link'
import { formatCurrency } from 'helpers/currency'
import ActionPanel from 'components/panels/ActionPanel'
import AlertForm from 'components/notes/AlertForm'
import DeleteLotModal from './components/modals/DeleteLotModal'
import RecoverLotModal from './components/modals/RecoverLotModal'

const Dashboard = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ lot, setLot ] = useState(null)
    const [ stats, setStats ] = useState([])
    const [ visibleModal, setVisibleModal ] = useState(null)
    const [ canDelete, setCanDelete ] = useState(false)

    const auth = useAuth()
    const canUpdateUnrestricted = can(auth, 'update_auctions_unrestricted')

    useEffect(() => {
        get(id, ({ data }) => {
            setLot(data.lot)
        })
    }, [id])

    useEffect(() => {
        if (! lot) {
            return
        }

        if (lot.auction.status === 'pending') {
            setCanDelete(
                can('delete_lots')
            )
        } else {
            setCanDelete(canUpdateUnrestricted)
        }

        setStats(
            buildStats()
        )
    }, [lot, canUpdateUnrestricted])

    const buildStats = () => {
        return [
            {
                icon: FaChartLine,
                title: 'Bid Count',
                value: lot.counts.bids
            },
            {
                icon: FaCircleDollarToSlot,
                title: 'Top Bid',
                value: formatCurrency(lot.stats.top_bid, 2) || '$0.00'
            },
            {
                icon: FaStreetView,
                title: 'View Count',
                value: lot.counts.views
            },
            {
                icon: FaEye,
                title: 'Watch Count',
                value: lot.counts.watches
            },
        ]
    }

    const onSubmitAlert = (alert, then) => {
        const config = lot.config || {}

        config.alert = alert

        updateConfig(lot.id, {
            config
        }, () => {
            setLot({
                ...lot,
                config
            })

            if (then) {
                then()
            }
        })
    }

    if (! lot) {
        return
    }

    return (
        <LotLayout id={id} lot={lot} setLot={setLot}>
            <div>
                <AuctionStats stats={stats} />

                <p className="mt-4">
                    <b>Inventory Item:</b> <Link to={`/admin/item/${lot.item_id}`}>{lot.item.name}</Link>
                </p>

                <p className="mt-1">
                    <b>Consignor Company:</b> <Link to={`/admin/company/${lot.item.company_id}`}>{lot.item.company?.name}</Link>
                </p>

                {canUpdateUnrestricted && (
                    <div className="mt-8">
                        <AlertForm
                            alert={lot.config?.alert || {}}
                            onSubmit={onSubmitAlert}
                        />
                    </div>
                )}

                {canDelete && (lot.deleted_at ? (
                    <div className="mt-4">
                        <ActionPanel
                            color="red"
                            title="Recover Lot"
                            action="Recover"
                            onClick={() => setVisibleModal('recover')}
                        >
                            Recover this lot.
                        </ActionPanel>
                    </div>
                ) : (
                    <div className="mt-4">
                        <ActionPanel
                            color="red"
                            title="Delete Lot"
                            action="Delete"
                            onClick={() => setVisibleModal('delete')}
                        >
                            Delete this lot from the auction.
                        </ActionPanel>
                    </div>
                ))}

                <DeleteLotModal
                    lot={lot}
                    visible={visibleModal}
                    setVisible={setVisibleModal}
                    then={(lot) => {
                        navigate('/admin/auction/' + lot.auction_id + '/lots')
                    }}
                />

                <RecoverLotModal
                    lot={lot}
                    visible={visibleModal}
                    setVisible={setVisibleModal}
                    then={(lot) => {
                        setLot(lot)
                        window.scrollTo(0, 0)
                    }}
                />
            </div>
        </LotLayout>
    )
}

export default Dashboard