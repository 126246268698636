import axios from 'axios'

export const BidIncrements = {
    index: (params, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/bid-increments', {
            params
        }).then(then)
    },

    get: (id, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/bid-increments/' + id)
            .then(then)
    },

    create: (data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/bid-increments/create', data)
            .then(then)
    },

    update: (id, data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/bid-increments/' + id + '/update', data)
            .then(then)
    },

    delete: (id, then) => {
        axios.delete(process.env.REACT_APP_API_URL + '/settings/bid-increments/' + id)
             .then(then)
    },
}

export const CompanyTypes = {
    index: (params, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/company-types', {
            params
        }).then(then)
    },

    get: (id, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/company-types/' + id)
            .then(then)
    },

    create: (data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/company-types/create', data)
            .then(then)
    },

    update: (id, data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/company-types/' + id + '/update', data)
            .then(then)
    },

    delete: (id, then) => {
        axios.delete(process.env.REACT_APP_API_URL + '/settings/company-types/' + id)
             .then(then)
    },
}

export const Settings = {
    index: (params, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings', {
            params
        }).then(then)
    },

    save: (data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/save', data)
            .then(then)
    },
}

export const Company = {
    index: (then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/company').then(then)
    },

    save: (data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/company/save', data)
            .then(then)
    },
}

export const Premiums = {
    index: (params, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/premiums', {
            params
        }).then(then)
    },

    get: (id, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/premiums/' + id)
            .then(then)
    },

    create: (data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/premiums/create', data)
            .then(then)
    },

    update: (id, data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/premiums/' + id + '/update', data)
            .then(then)
    },

    delete: (id, then) => {
        axios.delete(process.env.REACT_APP_API_URL + '/settings/premiums/' + id)
             .then(then)
    },
}

export const Taxes = {
    index: (params, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/taxes', {
            params
        }).then(then)
    },

    get: (id, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/taxes/' + id)
            .then(then)
    },

    create: (data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/taxes/create', data)
            .then(then)
    },

    update: (id, data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/taxes/' + id + '/update', data)
            .then(then)
    }
}

export const PaymentTypes = {
    index: (params, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/payment-types', {
            params
        }).then(then)
    },

    get: (id, then) => {
        axios.get(process.env.REACT_APP_API_URL + '/settings/payment-types/' + id)
            .then(then)
    },

    create: (data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/payment-types/create', data)
            .then(then)
    },

    update: (id, data, then) => {
        axios.post(process.env.REACT_APP_API_URL + '/settings/payment-types/' + id + '/update', data)
            .then(then)
    }
}