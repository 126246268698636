import React, { useState, useEffect } from 'react';
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import { states } from 'services/states'

const ModalShippingAddress = ({ show, onClose, fieldValues, onSave, setData }) => {
    const [values, setValues] = useState(fieldValues);

    useEffect(() => {
        setValues(fieldValues);
    }, [fieldValues]);

    if (!show) {
        return null;
    }

    const handleSave = () => {
        values.formType = 'Shipping';
        
        onSave(values, 'Shipping');
        onClose();
    };

    const selectState = (v) => {
        setData('state', v)
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-4xl">
                <h2 className="text-xl mb-4">Edit Shipping Address</h2>

                <div className="grid grid-cols-2 gap-4">
                    <InputField
                        extra="mb-3"
                        label="First Name"
                        id="first_name"
                        value={values.first_name || ''}
                        onChange={(e) => setData('first_name', e.target.value)}
                        type="text"
                    />

                    <InputField
                        extra="mb-3"
                        label="Last Name"
                        id="last_name"
                        value={values.last_name || ''}
                        onChange={(e) => setData('last_name', e.target.value)}
                        type="text"
                    />

                    
                    <div className="col-span-2">
                        <InputField
                            extra="mb-3"
                            label="Address (line 1)"
                            id="address_line1"
                            value={values.address_line1 || ''}
                            onChange={(e) => setData('address_line1', e.target.value)}
                            type="text"
                        />
                    </div>

                    
                    <div className="col-span-2">
                        <InputField
                            extra="mb-3"
                            label="Address (line 2)"
                            id="address_line2"
                            value={values.address_line2 || ''}
                            onChange={(e) => setData('address_line2', e.target.value)}
                            type="text"
                        />
                    </div>

                    <InputField
                        extra="mb-3"
                        label="City"
                        id="city"
                        value={values.city || ''}
                        onChange={(e) => setData('city', e.target.value)}
                        type="text"
                    />
                
                    <SelectField
                        extra="mb-3"
                        label="State"
                        id="state"
                        value={values.state || ''}
                        onChange={(e) => selectState(e.target.value)}
                        options={states}
                    />

                    <InputField
                        extra="mb-3"
                        label="Zip Code"
                        id="zip"
                        value={values.zip || ''}
                        onChange={(e) => setData('zip', e.target.value)}
                        type="text"
                    />
                </div>

                <div className="flex justify-end gap-4">
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">Cancel</button>
                    <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Save</button>
                </div>
            </div>
        </div>
    );
};

export default ModalShippingAddress;