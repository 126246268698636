import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { can, useAuth } from 'components/auth/AuthContext'
import UserLayout from './components/UserLayout'
import { get, getRoles, updateAssociations } from 'services/users'
import Card from 'components/card'
import Button from 'components/buttons/Button'
import {all as allCompanies} from 'services/companies'
import { all as allClients } from 'services/clients'
import Association from './components/Association'

const UserAssociations = () => {
    const auth = useAuth()
    const canViewClients = can(auth, "view_clients")

    const clientType = "App\\Models\\Client"
    const companyType = "App\\Models\\Company"

    const navigate = useNavigate()
    const { id } = useParams()
    const [ user, setUser ] = useState(null)
    const [ roles, setRoles ] = useState([])
    const [ clients, setClients ] = useState([])
    const [ companies, setCompanies ] = useState([])
    const [ newCounter, setNewCounter ] = useState(0)

    useEffect(() => {
        loadUser()
    }, [id])

    useEffect(() => {
        getRoles({}, ({ data }) => {
            setRoles(data.roles)
        })

        allClients({}, ({ data }) => {
            setClients(data.clients)
        })

        allCompanies({}, ({ data }) => {
            setCompanies(data.companies)
        })
    }, [])

    const loadUser = () => {
        if (! id) {
            return
        }

        get(id, ({ data }) => {
            setUser(data.user)
        })
    }

    const setAssociations = (associations) => {
        setUser({
            ...user,
            associations
        })
    }

    const setAssociation = (editedAssociation) => {
        setAssociations(
            user.associations.map(association => association.id === editedAssociation.id ? editedAssociation : association)
        )
    }

    const removeAssociation = (e, association) => {
        e.preventDefault()

        setAssociations(
            user.associations.filter(a => a.id !== association.id)
        )
    }

    const addAssociation = (association) => {
        user.associations.push({
            id: 'new' + newCounter,
            ...association
        })

        setNewCounter(newCounter + 1)

        setAssociations([
            ...user.associations
        ])
    }

    const addClient = () => {
        addAssociation({
            associated_type: clientType
        })
    }

    const addCompany = () => {
        addAssociation({
            associated_type: companyType
        })
    }

    const submit = () => {
        updateAssociations(id, {
            associations: user.associations
        }, ({ data }) => {
            navigate('/admin/user/' + data.user.id)
            window.scroll(0, 0)
        })
    }

    if (! user) {
        return
    }

    const associatedClients = user.associations.filter(association => association.associated_type === clientType)
    const associatedCompanies = user.associations.filter(association => association.associated_type === companyType)

    return (
        <UserLayout id={id} user={user}>
            <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <div className="flex flex-col gap-5">
                    {canViewClients && (
                        <Card extra={"w-full !p-5"}>
                            <div className="w-full px-[8px]">
                                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                    Clients
                                </h4>
                            </div>

                            <div className="mt-7 mb-2 flex flex-col gap-3">
                                {associatedClients.map(association => (
                                    <Association
                                        association={association}
                                        setAssociation={setAssociation}
                                        removeAssociation={removeAssociation}
                                        roles={roles}
                                        clients={clients}
                                    />
                                ))}
                            </div>


                            <div className="mt-12 flex w-full justify-between">
                                <Button color="outline"
                                        onClick={addClient}
                                >
                                    Link Client
                                </Button>

                                <Button
                                    onClick={submit}
                                >Submit</Button>
                            </div>
                        </Card>
                    )}
                    <Card extra={"w-full !p-5"}>
                        <div className="w-full px-[8px]">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Companies
                            </h4>
                        </div>

                        <div className="mt-7 mb-2 flex flex-col gap-3">
                            {associatedCompanies.map(association => (
                                <Association
                                    association={association}
                                    setAssociation={setAssociation}
                                    removeAssociation={removeAssociation}
                                    roles={roles}
                                    companies={companies}
                                />
                            ))}
                        </div>


                        <div className="mt-12 flex w-full justify-between">
                            <Button color="outline"
                                    onClick={addCompany}
                            >
                                Link Company
                            </Button>

                            <Button
                                onClick={submit}
                            >Submit</Button>
                        </div>
                    </Card>
                </div>
            </div>
        </UserLayout>
    )
}

export default UserAssociations