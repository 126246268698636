import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'

const ReportOptionsModal = ({ auction, report, visible, setVisible, showFormatButton }) => {
    const [ options, setOptions ] = useState({})

    const close = () => {
        setVisible(false)
    }

    const header = (
        <div className="text-lg font-bold">
            {report.name}
        </div>
    )

    const footer = (
        <div className="flex gap-2 justify-end items-center my-4">
            <div className="mr-[-0.5em]">
                <Button size="md" color="outline" onClick={() => close()}>
                    Cancel
                </Button>
            </div>
            {showFormatButton(report, 'pdf', options)}
            {showFormatButton(report, 'excel', options)}
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer} style={{ width: "40rem" }}
                onHide={close}
        >
            {report?.options?.map(option => {
                return (
                    <div key={option.name} className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            id={option.name}
                            checked={options[option.name] || false}
                            onChange={(e) => {
                                setOptions({
                                    ...options,
                                    [option.name]: e.target.checked ? 1 : 0
                                })
                            }}
                        />
                        <label htmlFor={option.name}>
                            {option.label}
                        </label>
                    </div>
                )
            })}
        </Dialog>
    )
}

export default ReportOptionsModal