import { useEffect, useState } from 'react'
import {
    FaCircleArrowLeft,
    FaCircleArrowRight
} from 'react-icons/fa6'
import SelectField from './SelectField'

const TwoColumnMultiSelect = ({ value, onChange, options, label, selectedLabel, deselectTitle, selectTitle }) => {
    const [ unselectedOptions, setUnselectedOptions ] = useState([])
    const [ optionsToAdd, setOptionsToAdd ] = useState([])
    const [ optionsToRemove, setOptionsToRemove] = useState([])

    useEffect(() => {
        setUnselectedOptions(options.filter(option => {
            return value.filter(o => o.name === option.name).length === 0
        }))
    }, [value])

    const translateOptions = (options) => {
        return options.map(option => {
            return {
                label: option.label,
                value: option.name
            }
        })
    }

    return (
        <div className="flex gap-12 items-center">
            <div className="w-48">
                <SelectField
                    multiple
                    centered
                    label={label}
                    size={10}
                    options={translateOptions(unselectedOptions)}
                    value={optionsToAdd}
                    onChange={(e) => {
                        setOptionsToAdd(
                            Array.from(e.target.selectedOptions, option => option.value)
                        )
                    }}
                />
            </div>
            <div className="flex flex-col gap-8 items-center justify-center pt-8">
                <FaCircleArrowLeft
                    className="w-5 h-5 cursor-pointer"
                    title={deselectTitle}
                    onClick={() => {
                        onChange(
                            value.filter(option => {
                                return optionsToRemove.indexOf(option.name) === -1
                            })
                        )
                    }}
                />

                <FaCircleArrowRight
                    className="w-5 h-5 cursor-pointer"
                    title={selectTitle}
                    onClick={() => {
                        onChange(
                            [
                                ...value,
                                ...options.filter(option => {
                                    return optionsToAdd.indexOf(option.name) >= 0
                                })
                            ]

                        )
                    }}
                />
            </div>
            <div className="w-48">
                <SelectField
                    multiple
                    centered
                    label={selectedLabel}
                    size={10}
                    options={translateOptions(value)}
                    value={optionsToRemove}
                    onChange={(e) => {
                        setOptionsToRemove(
                            Array.from(e.target.selectedOptions, option => option.value)
                        )
                    }}
                />
            </div>
        </div>
    )
}

export default TwoColumnMultiSelect