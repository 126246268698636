import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get, update } from 'services/items'
import ItemForm from './components/ItemForm'
import ItemLayout from './components/ItemLayout'

const EditItem = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ item, setItem ] = useState(null)
    const [ company, setCompany ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            setItem(data.item)
            setCompany(data.item.company)
        })
    }, [])

    const submit = () => {
        update(id, item, ({ data }) => {
            navigate('/admin/item/' + data.item.id)
        })
    }

    if (! item) {
        return
    }

    return (
        <ItemLayout id={id} item={item}>
            <ItemForm item={item}
                      setItem={setItem}
                      company={company}
                      setCompany={setCompany}
                      submit={submit}
            />
        </ItemLayout>
    )
}

export default EditItem