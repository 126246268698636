import BaseSettings from './base'

export default () => {
    return (
        <BaseSettings name="company.terms">
            <p>
                Any new users registering on the front of the site must agree to these terms in order to begin to use the site.
            </p>
        </BaseSettings>
    )
}