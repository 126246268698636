import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { login } from 'store/user'

function SignInDefault() {
  const [ data, setData ] = useState({})
  const [ error, setError ] = useState(false)
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const recentlyResetPassword = searchParams.has('reset')

  const handleChange = (e) => {
    data[e.target.name] = e.target.value

    setData({
      ...data
    })
  }

  const submit = (e) => {
    e.preventDefault()

    dispatch(login(data, () => {
      navigate('/admin/dashboard')
    }, (error) => {
      setError(error.response.data.message)
    }))
  }

  return (
    <Default
      maincard={
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {/* Sign in section */}
          <form onSubmit={submit} className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
              Sign In
            </h3>

            <p className="mb-9 ml-1 text-base text-gray-600">
              Enter your email and password to sign in
            </p>

            {error ? (
                <div className="mb-4 font-medium text-sm text-red-600">
                  {error}
                </div>
            ) : recentlyResetPassword && (
                <div className="mb-4 font-medium text-sm text-green-600">
                  Your password has been reset successfully.
                </div>
            )}

            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder=""
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              autoComplete="username"
              state={error ? 'error' : ''}
            />

            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password*"
              placeholder="Min. 8 characters"
              id="password"
              name="password"
              type="password"
              onChange={handleChange}
              state={error ? 'error' : ''}
              autoComplete="current-password"
            />
            {/* Checkbox */}
            <div className="mb-4 flex items-center justify-between px-2">
              <div className="mt-2 flex items-center">
                <Checkbox
                    id="remember"
                    name="remember"
                    onChange={handleChange}
                />
                <label htmlFor="remember" className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                  Keep me logged In
                </label>
              </div>
              <a
                className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                href="/auth/forgot-password"
              >
                Forgot Password?
              </a>
            </div>
            <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Sign In
            </button>
          </form>
        </div>
      }
    />
  );
}

export default SignInDefault;
