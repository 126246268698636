import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { Settings } from 'services/settings'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import Button from 'components/buttons/Button'

const BaseSettings = ({ only, children, expandedKeys }) => {
    const notify = useNotificationsDispatch()
    const [ settings, setSettings ] = useState({})

    const updateSetting = (k, v) => {
        setSettings({
            ...settings,
            [k]: {
                value: v
            }
        })
    }

    const submit = () => {
        Settings.save({
            settings
        }, () => {
            notify({
                message: 'Settings saved.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    useEffect(() => {
        Settings.index({
            only
        }, ({ data }) => {
            setSettings(data.settings)
        })
    }, [])

    return (
        <SettingsLayout expandedKeys={expandedKeys}>
            {children(settings, updateSetting)}

            <Button
                onClick={submit}
            >
                Save
            </Button>
        </SettingsLayout>
    )
}

export default BaseSettings