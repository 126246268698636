import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import Button from 'components/buttons/Button'
import { states } from 'services/states'
import { getPrimaryRoles } from 'services/users'
import { formatDate } from 'helpers/dates'
import { useEffect, useRef, useState } from 'react'

const Information = ({ user, setUser, isProfile, submit }) => {
    const [ roles, setRoles ] = useState([])
    const [ errors, setErrors ] = useState({})

    const form = useRef()
    const password = useRef()
    const confirmPassword = useRef()

    useEffect(() => {
        getPrimaryRoles({}, ({ data }) => {
            setRoles(data.roles)
        })
    }, [])

    const setData = (k, v) => {
        setUser({
            ...user,
            [k]: v
        })

        if (k === 'password_confirmation') {
            if (password.current.value !== confirmPassword.current.value) {
                confirmPassword.current.setCustomValidity("Confirm password field doesn't match.");
            } else {
                confirmPassword.current.setCustomValidity("");
            }
        }
    }

    const validateAndSubmit = (e) => {
        e.preventDefault();

        if (form.current.reportValidity()) {
            submit(() => {
                setErrors({})
            }, ({ data }) => {
                if (data.errors) {
                    setErrors(data.errors)
                }
            });
        }
    }

    return (
        <Card extra={"w-full !p-5"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Account Information
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Here you can enter the user's account information
                </p>
            </div>

            <form ref={form} className="mt-7 mb-2 grid grid-cols-2 gap-3">
                <InputField
                    label="First Name"
                    id="first_name"
                    type="text"
                    value={user.first_name || ''}
                    onChange={(e) => setData('first_name', e.target.value)}
                    required
                />
                <InputField
                    label="Last Name"
                    id="last_name"
                    type="text"
                    value={user.last_name || ''}
                    onChange={(e) => setData('last_name', e.target.value)}
                    required
                />
                <InputField
                    label="Email Address"
                    id="email"
                    type="email"
                    value={user.email || ''}
                    onChange={(e) => setData('email', e.target.value)}
                    state={errors.email ? 'error' : ''}
                    above={errors.email ? (
                        <p className="text-red-500 text-xs mt-0.5">
                            {errors.email[0]}
                        </p>
                    ) : ''}
                    required
                />
                <InputField
                    label="Username"
                    id="username"
                    type="text"
                    value={user.username || ''}
                    onChange={(e) => setData('username', e.target.value)}
                    state={errors.username ? 'error' : ''}
                    above={errors.username ? (
                        <p className="text-red-500 text-xs mt-0.5">
                            {errors.username[0]}
                        </p>
                    ) : ''}
                />
                <InputField
                    label="Phone Number"
                    id="phone"
                    type="tel"
                    value={user.phone || ''}
                    onChange={(e) => setData('phone', e.target.value)}
                />
                <InputField
                    label="Address"
                    id="address"
                    type="text"
                    value={user.address || ''}
                    onChange={(e) => setData('address', e.target.value)}
                />
                <InputField
                    label="City"
                    id="city"
                    type="text"
                    value={user.city || ''}
                    onChange={(e) => setData('city', e.target.value)}
                />
                <SelectField
                    label="State"
                    id="state"
                    value={user.state || ''}
                    onChange={(e) => setData('state', e.target.value)}
                    options={states}
                />
                <InputField
                    label="ZIP Code"
                    id="zip"
                    type="text"
                    value={user.zip || ''}
                    onChange={(e) => setData('zip', e.target.value)}
                />

                <InputField
                    label="Date of Birth"
                    id="dob"
                    type="date"
                    value={user.dob ? formatDate(user.dob) : ''}
                    onChange={(e) => setData('dob', e.target.value)}
                />

                <InputField
                    label="Driver's License"
                    id="drivers_license"
                    type="text"
                    value={user.drivers_license || ''}
                    onChange={(e) => setData('drivers_license', e.target.value)}
                />

                <InputField
                    label="License Number (if applicable)"
                    id="license_number"
                    type="text"
                    value={user.license_number || ''}
                    onChange={(e) => setData('license_number', e.target.value)}
                />

                {! isProfile && (
                    <>
                        <SelectField
                            label="Status"
                            id="status"
                            value={user.status || ''}
                            onChange={(e) => setData('status', e.target.value)}
                            options={[
                                {
                                    label: 'Declined',
                                    value: 'declined'
                                },
                                {
                                    label: 'Pending',
                                    value: 'pending'
                                },
                                {
                                    label: 'Approved',
                                    value: 'approved'
                                },
                            ]}
                        />

                        <SelectField
                            label="Primary Role"
                            id="role_id"
                            value={user.role_id || ''}
                            onChange={(e) => setData('role_id', e.target.value)}
                            options={roles.map(role => {
                                return {
                                    label: role.name,
                                    value: role.id
                                }
                            })}
                        />
                    </>
                )}

                {! user.id && (
                    <>
                        <InputField
                            autoComplete="off"
                            ref={password}
                            label="Password (minimum 8 characters)"
                            id="password"
                            type="password"
                            onChange={(e) => setData('password', e.target.value)}
                            minLength="8"
                            required={true}
                        />

                        <InputField
                            autoComplete="off"
                            ref={confirmPassword}
                            label="Confirm Password"
                            id="password_confirmation"
                            type="password"
                            onChange={(e) => setData('password_confirmation', e.target.value)}
                            required={true}
                        />
                    </>
                )}
            </form>

            <div className="mt-12 flex w-full justify-end">
                <Button
                    onClick={validateAndSubmit}
                >Submit</Button>
            </div>
        </Card>
    );
};

export default Information;
