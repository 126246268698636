import Tooltip from './tooltip'

const ExtendedTooltip = ({ children }) => {
    return (
        <Tooltip trigger="click" extended={true}>
            <div className="bg-white text-black text-xs text-left p-4 rounded-lg h-[300px] overflow-auto">
                {children}
            </div>
        </Tooltip>
    )
}

export default ExtendedTooltip