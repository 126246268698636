import {useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from 'components/buttons/Button'
import { index, files as loadFiles } from 'services/folders'
import {
    FaPencil,
    FaPlus, FaTrashCan,
} from 'react-icons/fa6'
import CreateFolderModal from './modals/CreateFolderModal'
import EditFolderModal from './modals/EditFolderModal'
import DeleteFolderModal from './modals/DeleteFolderModal'
import { FolderIcon } from '@heroicons/react/24/solid'
import FileUploader from './components/FileUploader'

const PortalsIndex = () => {
    const navigate = useNavigate()
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ folders, setFolders ] = useState([])
    const [ trail, setTrail ] = useState([])
    const [ openFolder, setOpenFolder ] = useState(null)
    const [ activeFolder, setActiveFolder ] = useState(null)
    const [ visibleModal, setVisibleModal ] = useState(null)

    const parent = searchParams.get('parent')

    useEffect(() => {
        index({
            parent
        }, ({ data }) => {
            setFolders(data.folders)
        })

        if (parent) {
            loadFiles(parent, {}, ({ data }) => {
                setOpenFolder(data.folder)
            })
        } else {
            setOpenFolder(null)
            setTrail([])
        }
    }, [parent])

    const updateTrail = (folder) => {
        setTrail(
            trail.map(f => {
                if (f.id === folder.id) {
                    return folder
                }

                return f
            })
        )
    }

    const removeFromTrail = (folder) => {
        setTrail(
            trail.filter(f => f.id !== folder.id)
        )
    }

    const Folder = ({ folder }) => {
        return (
            <a href={`/admin/media?parent=${folder.id}`} onClick={(e) => {
                e.preventDefault()

                navigate(`/admin/media?parent=${folder.id}`)

                setTrail([
                    ...trail,
                    folder
                ])
            }} className="w-40 flex flex-col gap-4 items-center">
                <FolderIcon className="w-10 h-10" />
                {folder.name}
            </a>
        )
    }

    return (
        <div className="mt-3">
            <div className="pt-3 mb-3 flex justify-between">
                <div className="px-4">
                    {trail.length > 0 && (
                        trail.map((folder, i) => {
                            if (i === trail.length - 1) {
                                return folder.name + ' > '
                            }

                            return (
                                <a href={`/admin/media?parent=${folder.id}`} onClick={(e) => {
                                    e.preventDefault()

                                    navigate(`/admin/media?parent=${folder.id}`)

                                    setTrail(trail.slice(0, i + 1))
                                }}>{folder.name} &gt; </a>
                            )
                        })
                    )}
                </div>
                <div className="flex gap-4 items-center">
                    {openFolder && (
                        <>
                            <Button color="red" onClick={() => {
                                setActiveFolder(openFolder)
                                setVisibleModal('delete')
                            }}>
                                <span className="flex gap-2 items-center">
                                    <FaTrashCan className="w-5 h-5" />
                                    <span>Delete Folder</span>
                                </span>
                            </Button>

                            <Button color="outline" onClick={() => {
                                setActiveFolder(openFolder)
                                setVisibleModal('edit')
                            }}>
                                <span className="flex gap-2 items-center">
                                    <FaPencil className="w-5 h-5" />
                                    <span>Edit Folder</span>
                                </span>
                            </Button>
                        </>
                    )}

                    <Button onClick={() => {
                        setActiveFolder({
                            parent_id: searchParams.get('parent') || ''
                        })
                        setVisibleModal('create')
                    }}>
                        <span className="flex gap-2 items-center">
                            <FaPlus className="w-5 h-5" />
                            <span>New Folder</span>
                        </span>
                    </Button>
                </div>
            </div>

            <div className="flex gap-4">
                {folders.map(folder => (
                    <Folder
                        folder={folder}
                    />
                ))}
            </div>

            {openFolder && (
                <div className="mt-8">
                    <FileUploader
                        folder={openFolder}
                    />
                </div>
            )}

            {/*onClick={() => setVisibleModal('delete')}*/}

            <CreateFolderModal
                folder={activeFolder}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(folder) => {
                    navigate('/admin/media?parent=' + folder.id)
                    setTrail([
                        ...trail,
                        folder
                    ])
                }}
            />

            <EditFolderModal
                folder={activeFolder}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(folder) => {
                    navigate('/admin/media?parent=' + parent)
                    updateTrail(folder)
                }}
            />

            <DeleteFolderModal
                folder={activeFolder}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(folder) => {
                    navigate('/admin/media?parent=' + folder.parent_id)
                    removeFromTrail(folder)
                }}
            />
        </div>
    )
}

export default PortalsIndex