import { useEffect, useState } from 'react'
import { staggerCheck } from 'services/lots'
import StaggerLotsModal from './modals/StaggerLotsModal'
import { can, useAuth } from 'components/auth/AuthContext'

const Stagger = ({ id, auction, after, show, setShow }) => {
    const auth = useAuth()
    const canStagger = can(auth, 'stagger_lots')

    const [ needToStagger, setNeedToStagger ] = useState(false)
    const [ updatedAt, setUpdatedAt ] = useState(null)

    useEffect(() => {
        if (updatedAt) {
            staggerCheck(id, ({ data }) => {
                setNeedToStagger(data.stagger)
            })
        }
    }, [id, updatedAt])

    useEffect(() => {
        if (updatedAt !== auction.updated_at) {
            setUpdatedAt(auction.updated_at)
        }
    }, [auction])

    return (
        <>
            {needToStagger && canStagger && (
                <div className="w-full">
                    <div className="bg-yellow-50 text-yellow-700 border border-yellow-400 w-full px-3 py-2 mb-2">
                        <div>
                            <p>
                                Lots are out of sequence.{" "}
                                <span className="cursor-pointer font-bold" onClick={() => setShow(true)}>
                                    Click here to stagger.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <StaggerLotsModal
                visible={show}
                setVisible={setShow}
                auction={auction}
                afterStagger={() => {
                    if (after) {
                        after()
                    }

                    staggerCheck(id, ({ data }) => {
                        setNeedToStagger(data.stagger)
                    })
                }}
            />
        </>
    )
}

export default Stagger