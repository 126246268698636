import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { photos as loadPhotos } from 'services/lots';
import LotLayout from './components/LotLayout';
import LotPhotoGallery from '../auctions/components/LotPhotoGallery';
import { can, useAuth } from 'components/auth/AuthContext';
import VideoLinkGallery from './components/VideoLinkGallery';

const LotPhotos = () => {
  const { id } = useParams();
  const [lot, setLot] = useState(null);

  const auth = useAuth();
  const canApprove = can(auth, 'approve_lots');

  const loadLotPhotos = () => {
    loadPhotos(id, {}, ({ data }) => {
      setLot(data.lot);
    });
  };

  useEffect(() => {
    loadLotPhotos();
  }, []);

  return (
    <LotLayout id={id} lot={lot} setLot={setLot}>
      <div className="w-full">
        {lot && <LotPhotoGallery key={lot.id} lot={lot} />}
        {lot &&
          (!lot.editable || lot.pending_approvals[0]?.assets?.length > 0) && (
            <LotPhotoGallery
              key={lot.id}
              lot={lot}
              approval={canApprove ? 'admin' : 'upload'}
              pendingApproval={lot.pending_approvals[0]}
              afterApprove={() => {
                loadLotPhotos();
              }}
            />
          )}

        {lot && <VideoLinkGallery asset={lot} />}
      </div>
    </LotLayout>
  );
};

export default LotPhotos;
