import TableRow from './TableRow';
import { useLayoutEffect, useState } from 'react';

const Table = ({ data, selectedRows, setSelectedRows }) => {
    const [ checked, setChecked ] = useState(true)
    const [ indeterminate, setIndeterminate ] = useState(false)

    useLayoutEffect(() => {
        const isIndeterminate = selectedRows.length > 0 && selectedRows.length < data.length
        setChecked(selectedRows.length === data.length)
        setIndeterminate(isIndeterminate)
    }, [selectedRows])

    function toggleAll() {
        setSelectedRows(checked || indeterminate ? [] : data)
        setChecked(!checked && !indeterminate)
    }

    function handleChange(row, state) {
        setSelectedRows(
            state ? [...selectedRows, row] : selectedRows.filter(r => r !== row)
        )
    }

    return (
        <div className="">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {data.map((row, key) => (
                                <TableRow key={key} row={row} handleChange={handleChange} selectedRows={selectedRows} toggleAll={toggleAll} />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Table
