import { useEffect, useState } from 'react'
import SelectField from 'components/fields/SelectField'
import { PaymentTypes } from 'services/settings'

const PaymentTypeSelect = ({ mode, defaultTypeId, onSelect }) => {
    const [ types, setTypes ] = useState([])
    const [ selected, setSelected ] = useState(defaultTypeId)

    useEffect(() => {
        PaymentTypes.index({
            mode
        }, ({ data }) => {
            setTypes(data.paymentTypes)

            if (! defaultTypeId) {
                let keyToCheck

                if (mode === 'deposit') {
                    keyToCheck = 'deposit_default'
                } else if (mode === 'invoice') {
                    keyToCheck = 'invoice_default'
                }

                if (keyToCheck) {
                    const defaultType = data.paymentTypes
                        .filter(paymentType => paymentType[keyToCheck] ? paymentType : false)[0]

                    if (defaultType) {
                        setSelected(defaultType.id)
                    }
                }

            }
        })
    }, [])

    useEffect(() => {
        if (onSelect) {
            onSelect(selected, types.filter(t => t.id == selected)[0]?.name)
        }
    }, [selected])

    return (
        <SelectField
            label="Payment Type"
            options={types.map(type => {
                return {
                    value: type.id,
                    label: type.name
                }
            })}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
        />
    )
}

export default PaymentTypeSelect