import axios from 'axios'
import { useEmail, useEmailDispatch } from './EmailContext'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Label from '../fields/Label'

const EmailModal = () => {
    const email = useEmail()
    const emailDispatch = useEmailDispatch()

    if (! email.visible) {
        return
    }

    const setData = (k, v) => {
        emailDispatch({
            type: 'update',
            email: {
                ...email,
                [k]: v
            }
        })
    }

    const close = () => {
        emailDispatch({
            type: 'hide'
        })
    }

    const send = () => {
        axios.post(process.env.REACT_APP_API_URL + '/email/send', email)
             .then(() => {
                 close()
             })
             .catch(() => {
                 alert("Failed to send!")
             })
    }

    const header = (
        <div className="text-lg font-bold">
            {email.modal.title}
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={close}>
                Cancel
            </Button>
            <Button onClick={send}>
                Send
            </Button>
        </div>
    )

    return (
        <Dialog
            modal draggable={false} resizable={false} visible={email.visible}
            header={header} footer={footer}
            onHide={() => close()}
            style={{ width: '35rem' }}
            contentClassName="overflow-visible"
        >
            <div className="flex flex-col gap-4 justify-center">
                <div>
                    <InputField
                        label="Subject"
                        id="email_subject"
                        value={email.subject || ''}
                        onChange={(e) => setData('subject', e.target.value)}
                    />
                </div>
                <div>
                    <Label>Message</Label>

                    <ReactQuill
                        theme="snow"
                        className="mt-2"
                        value={email.message || ''}
                        onChange={(v) => setData('message', v)}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default EmailModal