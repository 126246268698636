import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { Company } from 'services/settings'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import Button from 'components/buttons/Button'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BaseSettings = ({ name, children }) => {
    const notify = useNotificationsDispatch()
    const [ settings, setSettings ] = useState({})

    const updateSetting = (v) => {
        setSettings({
            ...settings,
            [name]: {
                value: {
                    body: v
                }
            }
        })
    }

    const submit = () => {
        Company.save({
            name,
            value: {
                body: settings[name]?.value?.body
            }
        }, () => {
            notify({
                message: 'Setting saved.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    useEffect(() => {
        Company.index(({ data }) => {
            setSettings(data.settings)
        })
    }, [])

    return (
        <SettingsLayout expandedKeys={['company']}>
            {children}
            <div className="my-4">
                <ReactQuill theme="snow"
                            className="mt-2"
                            value={settings[name]?.value?.body || ''}
                            onChange={(v) => updateSetting(v)}
                />
            </div>

            <Button
                onClick={submit}
            >
                Save
            </Button>
        </SettingsLayout>
    )
}

export default BaseSettings