import ReportsLayout from '../components/ReportsLayout'

const LotsSoldReport = () => {
    return (
        <ReportsLayout
            report="bidder"
            showAuctionFilter={true}
            defaultFields={[
                'first_name',
                'last_name',
                'email',
                'phone',
                'auctions_count',
                'lots_count',
                'invoice_count',
                'invoice_total',
                'invoice_balance',
            ]}
        />
    )
}

export default LotsSoldReport