import {useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { get, photos as loadPhotos } from 'services/auctions'
import AuctionLayout from './components/AuctionLayout'
import LotPhotoGallery from './components/LotPhotoGallery'
import CenteredPagination from 'components/pagination/Centered'
import BulkUploadPhotosModal from './components/modals/BulkUploadPhotosModal'

const LotPhotos = () => {
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ lots, setLots ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
        })
    }, [])

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        loadPhotos(id, {
            page
        }, ({ data }) => {
            setLots(data.lots)
        })
    }, [id, page])

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction} buttons={
            auction?.editable && (
                <BulkUploadPhotosModal
                    auction={auction}
                    setLots={setLots}
                />
            )
        }>
            <div className="w-full">
                {lots.data && (
                    lots.data.map((lot) => (
                        <LotPhotoGallery key={lot.id} lot={lot} />
                    ))
                )}
                <CenteredPagination pagination={lots} />
            </div>
        </AuctionLayout>
    )
}

export default LotPhotos