import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LotLayout from './components/LotLayout'
import { get } from 'services/lots'
import AssetGallery from 'components/assets/AssetGallery'

const LotDocuments = () => {
    const entityType = 'App\\Models\\Lot'
    const { id } = useParams()
    const [ lot, setLot ] = useState(null)

    useEffect(() => {
        loadLot()
    }, [id])

    const loadLot = () => {
        if (! id) {
            return
        }

        get(id, ({ data }) => {
            setLot(data.lot)
        })
    }

    if (! lot) {
        return
    }

    return (
        <LotLayout id={id} lot={lot} setLot={setLot}>
            <div className="mt-3">
                <p>
                    Lot documents, such as certificates and legal documents, can be stored here so that the bidders are
                    able to access them.
                </p>

                <p className="my-4">
                    Changes here do not reflect on the inventory this was created from.
                </p>

                <AssetGallery
                    entityId={id}
                    entityType={entityType}
                    assets={lot.documents}
                    type="document"
                />
            </div>
        </LotLayout>
    )
}

export default LotDocuments