import { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import Button from 'components/buttons/Button'
import { massUpdate } from 'services/lots'
import SelectField from 'components/fields/SelectField'
import InputField from 'components/fields/InputField'
import Label from 'components/fields/Label'
import { InputNumber } from 'primereact/inputnumber'
import { all as loadCompanies } from 'services/companies'
import { BidIncrements, Premiums } from 'services/settings'
import {
    FaCircleXmark,
    FaPencil
} from 'react-icons/fa6'

const ToggleLabel = ({ children, name, toggle, toggled }) => {
    return (
        <div className="flex gap-2 items-center">
            {children}

            {toggled ? (
                <FaCircleXmark
                    className="cursor-pointer text-red-600 hover:text-red-500"
                    onClick={(e) => toggle(name)}
                />
            ) : (
                <FaPencil
                    className="cursor-pointer text-slate-600 hover:text-slate-500"
                    onClick={(e) => toggle(name)}
                />
            )}
        </div>
    )
}

const MassUpdateLotsModal = ({ auction, visible, setVisible, checkedLots, after }) => {
    const [ fields, setFields ] = useState({})
    const [ selectedOnly, setSelectedOnly ] = useState(true)
    const [ companies, setCompanies ] = useState([])
    const [ bidIncrements, setBidIncrements ] = useState([])
    const [ premiums, setPremiums ] = useState([])

    const notify = useNotificationsDispatch()
    const noLotsSelected = checkedLots.length === 0

    useEffect(() => {
        if (visible) {
            loadCompanies({}, ({ data }) => {
                setCompanies(data.companies)
            })

            BidIncrements.index({}, ({ data }) => {
                setBidIncrements(data.bidIncrements)
            })

            Premiums.index({}, ({ data }) => {
                setPremiums(data.premiums)
            })
        }
    }, [visible])

    const close = () => {
        setVisible(false)
    }

    const toggle = (name) => {
        let field = fields[name]

        if (field) {
            field = null
        } else {
            field = {
                value: ''
            }
        }

        setFields({
            ...fields,
            [name]: field
        })
    }

    const setData = (k, v) => {
        setFields({
            ...fields,
            [k]: {
                ...fields[k],
                value: v
            }
        })
    }

    const submit = () => {
        massUpdate(auction.id, {
            fields,
            lots: checkedLots,
            selected_only: selectedOnly && ! noLotsSelected ? 1 : 0
        }, () => {
            close()

            notify({
                message: 'Lots updated successfully.',
                title: 'Success!',
                type: 'success',
            })

            after()
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Mass Update Lots
        </div>
    )

    const footer = (
        <div className="flex gap-4 items-center justify-between">
            <div>
                <label className={"flex gap-2 items-center " + (noLotsSelected ? 'cursor-not-allowed' : '')}>
                    <input
                        type="checkbox"
                        checked={selectedOnly && ! noLotsSelected}
                        onChange={(e) => setSelectedOnly(e.target.checked)}
                        disabled={noLotsSelected}
                    />
                    <span className={noLotsSelected ? 'text-gray-300' : ''}>Updated Selected Only ({checkedLots.length} lots)</span>
                </label>
            </div>
            <div>
                <Button color="outline" onClick={() => close()}>
                    Cancel
                </Button>
                <Button onClick={submit}>
                    Submit
                </Button>
            </div>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer} style={{ width: "40rem" }}
                onHide={close}
        >
            <div className="grid grid-cols-2 gap-4">
                <SelectField
                    id="company_id"
                    name="company_id"
                    label={(
                        <ToggleLabel name="company_id" toggle={toggle} toggled={!!fields.company_id}>
                            Consignor
                        </ToggleLabel>
                    )}
                    value={fields.company_id?.value || ''}
                    onChange={(e) => setData('company_id', e.target.value)}
                    options={companies.map(company => {
                        return {
                            label: company.name,
                            value: company.id
                        }
                    })}
                    disabled={!fields.company_id}
                />

                <div>
                    <Label htmlFor="starting_bid">
                        <ToggleLabel name="starting_bid" toggle={toggle} toggled={!!fields.starting_bid}>
                            Starting Bid
                        </ToggleLabel>
                    </Label>
                    <div className={"p-inputgroup flex-1 mt-2 " + (!fields.starting_bid ? 'p-disabled' : '')}>
                        <span className="p-inputgroup-addon">$</span>
                        <InputNumber
                            inputId="starting_bid"
                            maxFractionDigits={2}
                            value={fields.starting_bid?.value || ''}
                            onChange={(e) => setData('starting_bid', e.value)}
                            disabled={!fields.starting_bid}
                        />
                    </div>
                </div>

                <SelectField
                    id="bid_increment"
                    name="bid_increment"
                    label={(
                        <ToggleLabel name="bid_increment" toggle={toggle} toggled={!!fields.bid_increment}>
                            Bid Increment
                        </ToggleLabel>
                    )}
                    value={fields.bid_increment?.value || ''}
                    onChange={(e) => setData('bid_increment', e.target.value)}
                    options={bidIncrements.map(bidIncrement => {
                        return {
                            value: bidIncrement.id,
                            label: bidIncrement.name
                        }
                    })}
                    disabled={!fields.bid_increment}
                />

                <SelectField
                    id="premium"
                    name="premium"
                    label={(
                        <ToggleLabel name="premium" toggle={toggle} toggled={!!fields.premium}>
                            Buyer's Premium
                        </ToggleLabel>
                    )}
                    value={fields.premium?.value || ''}
                    onChange={(e) => setData('premium', e.target.value)}
                    options={premiums.map(premium => {
                        return {
                            value: premium.id,
                            label: premium.name
                        }
                    })}
                    disabled={!fields.premium}
                />

                <SelectField
                    id="show_reserve_status"
                    name="show_reserve_status"
                    label={(
                        <ToggleLabel name="show_reserve_status" toggle={toggle} toggled={!!fields.show_reserve_status}>
                            Show Reserve Status
                        </ToggleLabel>
                    )}
                    value={fields.show_reserve_status?.value || ''}
                    onChange={(e) => setData('show_reserve_status', e.target.value)}
                    options={[
                        {
                            label: 'Yes',
                            value: 1
                        },
                        {
                            label: 'No',
                            value: 0
                        }
                    ]}
                    disabled={!fields.show_reserve_status}
                />
            </div>
        </Dialog>
    )
}

export default MassUpdateLotsModal