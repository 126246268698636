import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/items', {
        params
    }).then(then)
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/item/' + id)
         .then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/items/create', data)
        .then(then)
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/item/' + id + '/update', data)
        .then(then)
}

export const deleteItem = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/item/' + id + '/delete')
        .then(then)
}

export const photos = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/item/' + id + '/photos', {
        params
    }).then(then)
}

export const history = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/item/' + id + '/history', {
        params
    }).then(then)
}

export const assign = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/item/' + id + '/assign', data).then(then)
}

export const bulkAssign = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/items/assign', data).then(then)
}

export const airbagStatuses = [
    {
        'value': 'unknown',
        'label': 'Unknown',
    },
    {
        'value': 'not-deployed',
        'label': 'Not deployed',
    },
    {
        'value': 'deployed',
        'label': 'Deployed',
    },
]

const triStateSelect = [
    {
        'value': 'unknown',
        'label': 'Unknown',
    },
    {
        'value': 'yes',
        'label': 'Yes',
    },
    {
        'value': 'no',
        'label': 'No',
    },
]

const twoStateSelect = [
    {
        'value': 'yes',
        'label': 'Yes',
    },
    {
        'value': 'no',
        'label': 'No',
    },
]

export const runsOptions = triStateSelect
export const drivesOptions = triStateSelect
export const keyOptions = twoStateSelect
export const catalyticOptions = triStateSelect