import { Link } from 'react-router-dom'

const LinkComponent = ({ children, className, ...props }) => {
    let classes = "text-cyan-600 hover:text-cyan-500 "

    if (className) {
        classes += className
    }

    return (
        <Link {...props} className={classes}>
            {children}
        </Link>
    )
}

export default LinkComponent