import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Information from './components/Information'
import Password from './components/Password'
import { get, update } from 'services/users'
import { useSelector } from 'react-redux'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const UserProfile = () => {
    const notify = useNotificationsDispatch()
    const navigate = useNavigate()

    const state = useSelector(state => state.user)
    const authUser = state.user
    const id = authUser.id

    const [ user, setUser ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            setUser(data.user)
        })
    }, [])

    const submit = (then, fail) => {
        update(id, user, ({ data }) => {
            navigate('/admin/profile')

            notify({
                message: 'Updated successfully.',
                title: 'Success!',
                type: 'success',
            })

            window.scrollTo(0, 0)

            if (then) {
                then(data)
            }
        }, fail)
    }

    const submitPassword = (then, fail) => {
        submit(then, fail)
    }

    if (! user) {
        return
    }

    return (
        <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
            <div className="flex flex-col gap-5">
                <Information user={user}
                             setUser={setUser}
                             isProfile={true}
                             submit={submit}
                />

                <Password user={user}
                          setUser={setUser}
                          isProfile={true}
                          submit={submitPassword}
                />
            </div>
        </div>
    )
}

export default UserProfile