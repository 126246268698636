import { useEffect, useState } from 'react'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid'
import Processing from 'components/loading/Processing'
import Alert from 'components/alerts/Alert'

const Upload = ({ auction, afterSubmit }) => {
    const [ format, setFormat ]  = useState('PEAK')
    const [ failed, setFailed ] = useState(false)
    const [ showOverlay, setShowOverlay ] = useState(false)

    const handleDrop = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')

            reader.onload = () => {
                setFailed(false)

                axios.post(process.env.REACT_APP_API_URL + `/auction/${auction.id}/lots/import/submit`, {
                    'format': format,
                    'name': file.name,
                    'data': reader.result
                }).then(response => response.data).then(data => {
                    if (data.success) {
                        // setShowOverlay(true)

                        axios.get(process.env.REACT_APP_API_URL + `/auction/${auction.id}/lots/import/review/${data.hash}`).then(({ data }) => {
                            // setShowOverlay(false)

                            if (data.failed) {
                                setFailed(true)
                            } else {
                                afterSubmit(data)
                            }
                        })
                    } else {
                        setFailed(true)
                    }
                })
            }

            reader.readAsDataURL(file)
        })
    };

    return (
        <div className="py-12 relative">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="mb-10">
                    <h1 className="text-xl font-bold">1. Which format would you like to upload?</h1>

                    <p className="my-4">
                        We accept feeds from the following services, or you can use our format. (<a href="/docs/PEAK%20Sample.csv" target="_blank" className="text-blue-600 font-bold hover:text-blue-800">Click here for a sample file.</a>)
                    </p>

                    <p className="my-4">Please pick the format you'd like to upload:</p>

                    <select className="list-disc list-inside" defaultValue={format} onChange={(e) => setFormat(e.target.value)}>
                        <option>PEAK</option>
                        <option>Towbook</option>
                        <option>Tracker</option>
                        <option>Omadi</option>
                        <option>VTS</option>
                        <option>TOPS</option>
                    </select>
                </div>

                <h1 className="text-xl font-bold mb-2">2. Upload your file</h1>

                <p className="my-4">
                    All fields are optional, including VIN. Anything without a 17-digit VIN will be marked as Other.
                </p>

                <p className="my-4">
                    Before the import happens, you will have the ability to review the feed &amp; choose which lots to import.
                </p>

                <p className="my-4 font-bold">
                    You will have the ability to edit lots after import.
                </p>

                <Dropzone onDrop={handleDrop}>
                    {({getRootProps, getInputProps}) => (
                        <section className="mb-4">
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="border-gray-100 bg-gray-100 rounded-lg p-10">
                                    <div className="flex justify-center gap-10">
                                        <div>
                                            <button type="button" className="flex gap-4 justify-center shadow-lg rounded-lg border border-gray-200 bg-white hover:bg-gray-300 active:bg-gray-400 py-3 px-4" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <ArrowUpTrayIcon className="h-4 w-4 mt-1 text-gray-500" />

                                                Upload File
                                            </button>
                                        </div>

                                        <div>
                                            <p>
                                                Or drag and drop an Excel file here
                                            </p>
                                            <p className="mt-2">
                                                <strong>Accepted Formats: </strong> CSV, XLS or XLSX
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>

                { failed && (
                    <Alert title="Problem with your upload">The file you uploaded did not match the selected format. Please try again.</Alert>
                )}
            </div>
            <Processing showOverlay={showOverlay} />
        </div>
    )
}

export default Upload