import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { ban } from 'services/users'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const BanUserModal = ({ user, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()
    const [ banMessage, setBanMessage ] = useState('You have been banned. Please contact support.')

    const submitBan = () => {
        ban(user.id, {
            ban_message: banMessage
        }, ({ data }) => {
            setVisible(false)

            notify({
                message: 'User has been banned.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data.user)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Ban User
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={submitBan}>
                Confirm
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'ban'}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}>
            <p>
                Banning this user will prevent them from logging into the Buyer Portal or placing bids on ALL auctions.
            </p>

            <p className="mt-4">
                Please enter a message to be displayed to the banned user:
            </p>

            <textarea
                className="w-full mt-4"
                onChange={(e) => {
                    setBanMessage(e.target.value)
                }}
                value={banMessage}
            />
        </Dialog>
    )
}

export default BanUserModal