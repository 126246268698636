import { Dialog } from 'primereact/dialog'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import Button from 'components/buttons/Button'
import { stagger } from 'services/lots'

const StaggerLotsModal = ({ auction, visible, setVisible, afterStagger }) => {
    const notify = useNotificationsDispatch()

    const close = () => {
        setVisible(false)
    }

    const submit = () => {
        stagger(auction.id, () => {
            close()

            notify({
                message: 'Lots staggered successfully.',
                title: 'Success!',
                type: 'success',
            })

            afterStagger()
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Stagger Lots
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => close()}>
                Cancel
            </Button>
            <Button onClick={submit}>
                Stagger
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer} style={{ width: "40rem" }}
                onHide={close}>
            <p>
                This will re-stagger all lots, adjusting the end times so that lots close in order by lot number (or sale order)
            </p>
        </Dialog>
    )
}

export default StaggerLotsModal