import { useEffect, useState } from 'react'
import DateFilter from './filters/DateFilter'
import DateRangeFilter from './filters/DateRangeFilter'
import Fields from './filters/Fields'
import AuctionFilter from './filters/AuctionFilter'
import CheckboxFilter from './filters/CheckboxFilter'
import StateFilter from './filters/StateFilter'
import { loadFields, loadReport } from 'services/reports'
import { PanelMenu } from 'primereact/panelmenu'
import { useDebounce } from 'use-debounce'
import axios from 'axios'
import Button from 'components/buttons/Button'
import {
    FaFileExcel,
    FaFilePdf
} from 'react-icons/fa6'
import { useAuth, can } from 'components/auth/AuthContext'

const ReportsLayout = (
    {
        children,
        report,
        showFilters = true,
        showAuctionFilter = false,
        showOnlySoldLotsFilter = false,
        defaultFields = [],
        expandedKeys = ['auctions']
    }) => {
    const auth = useAuth()
    const canViewGlobalReports = can(auth, 'view_global_reports')
    const showStateFilter = canViewGlobalReports

    const [ filters, setFilters ] = useState({})
    const [ fields, setFields ] = useState([])
    const [ fieldOptions, setFieldOptions ] = useState([])
    const [ data, setData ] = useState([])

    const [ debouncedFilters ] = useDebounce(filters, 500)
    const [ debouncedFields ] = useDebounce(fields, 100)

    useEffect(() => {
        if (! report) {
            return
        }

        loadFields(report, {}, ({ data }) => {
            setFieldOptions(data)

            if (defaultFields.length > 0) {
                setFields(data.filter(
                    field => defaultFields.indexOf(field.name) >= 0
                ))
            }
        })
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source();

        loadReportData(source)

        return () => {
            source.cancel(
                "Canceled because of component unmount or filter change"
            );
        };
    }, [debouncedFields, debouncedFilters])

    const loadReportData = (source) => {
        loadReport({
            report,
            filters: debouncedFilters,
            fields: debouncedFields
        }, source.token, ({ data }) => {
            setData(data.data)
        }, (e) => {
            if (axios.isCancel(source)) {
                return;
            }

            setData([])
        })
    }

    const setFilter = (k, v) => {
        setFilters({
            ...filters,
            [k]: v
        })
    }

    const expanded = (key) => {
        return expandedKeys.indexOf(key) >= 0
    }

    const download = (format) => {
        const params = new URLSearchParams({
            report,
            format,
            download: true,
            filters: JSON.stringify(filters),
            fields: JSON.stringify(fields)
        })

        const href = `${process.env.REACT_APP_BASE_URL}/admin/report?` + params.toString()

        window.open(href, '_blank')
    }

    const [ items ] = useState(canViewGlobalReports ? [
        {
            expanded: expanded('auctions'),
            label: 'Auctions',
            items: [
                {
                    label: 'Bidder Report',
                    url: '/admin/reports/auctions/bidder'
                },
                {
                    label: 'Lots Sold Report',
                    url: '/admin/reports/auctions/lots-sold'
                },
                {
                    label: 'Revenue Report',
                    url: '/admin/reports/auctions/revenue'
                },
            ]
        },
        {
            expanded: expanded('companies'),
            label: 'Companies',
            items: [
                {
                    label: 'Census Report',
                    url: '/admin/reports/companies/census'
                },
            ]
        },
    ] : [
        {
            expanded: expanded('auctions'),
            label: 'Auctions',
            items: [
                {
                    label: 'Lots Sold Report',
                    url: '/admin/reports/auctions/lots-sold'
                },
            ]
        },
    ])

    return (
        <div className="flex gap-6 mt-6">
            <PanelMenu model={items}
                       multiple
                       className="w-60 text-sm mr-4"
            />

            <div className="w-full overflow-hidden">
                {showFilters && (
                    <>
                        <div className="pb-4 mb-4 border-solid border-b border-slate-300">
                            <DateFilter
                                month={filters.month || ''}
                                day={filters.day || ''}
                                year={filters.year || ''}
                                setFilter={setFilter}
                            />
                            <DateRangeFilter
                                from={filters.from || ''}
                                to={filters.to || ''}
                                setFilter={setFilter}
                            />

                            {showAuctionFilter && (
                                <AuctionFilter
                                    value={filters.auction || ''}
                                    setFilter={setFilter}
                                />
                            )}

                            {showStateFilter && (
                                <StateFilter
                                    value={filters.state || []}
                                    setFilter={setFilter}
                                />
                            )}

                            {showOnlySoldLotsFilter && (
                                <CheckboxFilter
                                    label="Only Sold Lots"
                                    name="only_sold"
                                    checked={filters.only_sold || false}
                                    setFilter={setFilter}
                                />
                            )}
                        </div>
                        <div className="w-full pb-4 mb-4 border-solid border-b border-slate-300">
                            <p className="italic mb-2">Select fields to include on the report:</p>

                            <Fields
                                options={fieldOptions}
                                selected={fields}
                                setSelected={setFields}
                            />
                        </div>
                    </>
                )}

                {children}

                {report && data.length === 0 && (
                    <div className="mt-4">
                        <em>No results</em>
                    </div>
                )}

                {data.length > 0 && (
                    <div className="w-full pt-4">
                        <div className="flex gap-4 items-center">
                            <Button onClick={() => download('csv')} color="green-outline" size="md">
                                <FaFileExcel className="w-5 h-5 text-green-600" /> Download Excel
                            </Button>

                            <Button onClick={() => download('pdf')} color="red-outline" size="md">
                                <FaFilePdf className="w-5 h-5 text-red-600" /> Download PDF
                            </Button>
                        </div>

                        <table className="w-full text-left pt-4">
                            <thead>
                            <tr>
                                {fields.map(field => (
                                    <th key={field.name} className="py-2">{field.label}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((row, i) => (
                                <tr key={i}>
                                    {fields.map(field => (
                                        <td key={field.name} className="py-2">
                                            {field.name === 'company_name' ? (
                                                <a href={`/admin/company/${row.company_id}`} target="_blank">{row[field.name]}</a>
                                            ) : (
                                                row[field.name]
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ReportsLayout