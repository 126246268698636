import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const Tooltip = ({ children, position, size = 'md', extended = false, trigger }) => {
    const [ visible, setVisible ] = useState(false)

    const show = () => {
        setVisible(true)
    }

    const hide = () => {
        setVisible(false)
    }

    const styles = position ? position : {top: '20px', left: '-150px'}

    const sizeClass = size === 'sm' ? 'w-4 h-4' : 'w-5 h-5'

    let focus, blur, enter, leave

    if (trigger === 'click') {
        focus = show
        blur = hide
    } else {
        enter = show
        leave = hide
    }

    return (
        <>
            <div className="relative" tabIndex={1000} onMouseEnter={enter} onMouseLeave={leave} onFocus={focus} onBlur={blur}>
                <div className="absolute z-10" style={styles}>
                    <Transition
                        show={visible}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className={
                            "bg-slate-800 text-white text-sm text-center font-normal shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                            + " " + (extended ? 'p-2' : 'p-4')
                        }
                             style={{width: '300px'}}>
                            {children}
                        </div>
                    </Transition>
                </div>

                <div>
                    <InformationCircleIcon className={"ml-0.5 " + sizeClass} aria-hidden="true" />
                </div>
            </div>
        </>
    )
}

export default Tooltip