const CheckboxFilter = ({ label, name, checked, setFilter }) => {
    return (
        <div className="flex gap-2 items-center mt-4">
            <label htmlFor={"checkbox_" + name}>
                {label}
            </label>

            <input
                id={"checkbox_" + name}
                type="checkbox"
                checked={checked}
                onChange={(e) => setFilter(name, e.target.checked ? 1 : 0)}
            />
        </div>
    )
}

export default CheckboxFilter