import AuctionLayout from 'views/admin/auctions/components/AuctionLayout'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, consignors as getConsignors } from 'services/auctions'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Button from 'components/buttons/Button'
import Link from 'components/buttons/Link'

const AuctionConsignors = () => {
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ consignors, setConsignors ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()

    useEffect(() => {
        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })
    }, [searchParams])

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
        })
    }, [])

    useEffect(() => {
        loadConsignors()
    }, [sort])

    const loadConsignors = () => {
        getConsignors(id, {
            by: sort.by,
            dir: sort.dir
        }, ({ data }) => {
            setConsignors(data.consignors)
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const userFieldTemplate = (user, value) => {
        return (
            <Link to={`/admin/user/${user?.id}/edit`}>
                {value}
            </Link>
        )
    }

    const companyFieldTemplate = (company, value) => {
        return (
            <Link to={`/admin/company/${company?.id}/edit`}>
                {value}
            </Link>
        )
    }

    if (! auction) {
        return
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
            {consignors.length > 0 && (
                <DataTable className="text-sm" value={consignors} lazy dataKey="id"
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           tableStyle={{ minWidth: '75rem' }}>
                    <Column field="consignor_code"
                            body={(consignor) => companyFieldTemplate(consignor, consignor.consignor_code)}
                            header="Consignor Code" sortable bodyClassName="font-bold" />
                    <Column sortField="owner.last_name"
                            body={(consignor) => userFieldTemplate(consignor.owner, consignor.owner?.name)}
                            header="Company Owner" sortable bodyClassName="font-bold" />
                    <Column field="name"
                            body={(consignor) => companyFieldTemplate(consignor, consignor.name)}
                            header="Company" sortable bodyClassName="font-bold" />
                    <Column body={(consignor) => {
                        return (
                            <Button href={`/admin/auction/${auction.id}/lots?company_id=${consignor.id}`}>
                                Lots
                            </Button>
                        )
                    }} style={{ width: '15%' }} />
                </DataTable>
            )}
        </AuctionLayout>
    )
}

export default AuctionConsignors