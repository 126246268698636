import Loader from './Loader'

const Processing = ({ message = 'Processing', showOverlay = false }) => {
    return (
        <div className={'absolute top-0 bg-black bg-opacity-70 w-full h-full ' + (showOverlay ? '' : 'hidden')}>
            <h1 className="absolute flex justify-center items-center gap-2 left-0 right-0 w-full h-full text-6xl text-white font-bold">
                <span className="-mt-4">
                    {message}
                </span>
                <Loader />
            </h1>
        </div>
    )
}

export default Processing