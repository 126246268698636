import Breadcrumbs from 'components/navbar/Breadcrumbs'
import VerticalMenu from 'components/menu/VerticalMenu'
import NoteDisplay from 'components/notes/NoteDisplay'
import { can, useAuth } from 'components/auth/AuthContext'
import { allNotes } from 'services/users'

const UserLayout = ({ id, user, children, buttons }) => {
    const auth = useAuth()
    const canUpdate = can(auth, 'update_users')

    const menu = [
        { name: 'Dashboard', href: '/admin/user/' + id },
        canUpdate ? { name: 'Edit', href: `/admin/user/${id}/edit` } : null,
        { name: 'Associations', href: `/admin/user/${id}/associations` },
        { name: 'Bids', href: `/admin/user/${id}/bids` },
        { name: 'Invoices', href: `/admin/user/${id}/invoices` },
        { name: 'Credit Cards', href: `/admin/user/${id}/credit-cards` },
        { name: 'Notes', href: `/admin/user/${id}/notes` },
    ].filter(item => !!item)

    menu.forEach(item => {
        item.current = (window.location.pathname === item.href)
    })

    if (! id) {
        return (
            <div className="w-full">
                {children}
            </div>
        )
    }

    return (
        <>
            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/users",
                        title: "Users"
                    },
                    {
                        href: "#",
                        title: user.name
                    }
                ]} />
            </div>

            <div className="pt-4">
                <div className="flex gap-6">
                    <VerticalMenu items={menu} />

                    <div className="w-full">
                        <div className="w-full">
                            <NoteDisplay notes={allNotes(user)} />
                        </div>

                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserLayout