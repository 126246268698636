import LotLayout from './components/LotLayout'
import LotForm from './components/LotForm'
import Card from 'components/card'
import Button from 'components/buttons/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, update, approve, reject, approveChanges, rejectChanges, listModified } from 'services/lots'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import { can, useAuth } from 'components/auth/AuthContext'

const Edit = () => {
    const navigate = useNavigate()
    const notify = useNotificationsDispatch()
    const { id } = useParams()
    const [ lot, setLot ] = useState(null)
    const [ modified, setModified ] = useState([])
    const [ auction, setAuction ] = useState(null)

    const auth = useAuth()
    const canApproveLots = can(auth, 'approve_lots')

    useEffect(() => {
        get(id, ({ data }) => {
            setLot(data.lot)
            setAuction(data.lot.auction)
        })
    }, [])

    useEffect(() => {
        if (! lot) {
            return
        }

        setModified(
            listModified(lot)
        )
    }, [lot])

    const submit = (add = false, then, fail) => {
        update(id, lot, ({ data }) => {
            if (add) {
                navigate(`/admin/auction/${data.lot.auction_id}/lots/create`)
                window.scrollTo(0, 0)
            } else {
                navigate(`/admin/auction/${data.lot.auction_id}/lots`)
            }

            notify({
                message: 'Lot updated.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data)
            }
        }, fail)
    }

    const approveLot = () => {
        approve(id, ({ data }) => {
            setLot(data.lot)

            notify({
                message: 'Lot approved.',
                title: 'Success!',
                type: 'success',
            })
        });
    }

    const rejectLot = () => {
        reject(id, () => {
            navigate(`/admin/auction/${auction.id}/lots`)
        });
    }

    const handleApproveChanges = () => {
        approveChanges(id, ({ data }) => {
            setLot(data.lot)

            notify({
                message: 'Lot changes approved & applied.',
                title: 'Success!',
                type: 'success',
            })
        });
    }

    const handleRejectChanges = () => {
        rejectChanges(id, ({ data }) => {
            setLot(data.lot)

            notify({
                message: 'Lot changes rejected.',
                title: 'Success!',
                type: 'success',
            })
        });
    }

    return (
        <LotLayout id={id} lot={lot} setLot={setLot}>
            {lot && (
                <div>
                    {lot.pending_approvals.length > 0 && (
                        <Card extra={"w-1/2 mt-3 px-6 py-6"}>
                            {/* Header */}
                            <div className="w-full px-[8px] mb-4">
                                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                    Change Approval Required
                                </h4>
                                <p className="mt-1 text-base text-gray-600">
                                    This auction is active. Requested changes must be approved by an admin before going live.
                                </p>
                            </div>

                            <div>
                                <ul className="px-2">
                                    {modified.map((attr, i) => (
                                        <li key={(attr.item ? 'item' : 'lot') + '-' + i}>
                                            <span className="capitalize">{attr.k}</span>: {attr.v}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {canApproveLots && (
                                <div className="flex gap-4 items-center justify-end">
                                    <Button color="red-outline" onClick={handleRejectChanges}>
                                        Reject
                                    </Button>
                                    <Button onClick={handleApproveChanges}>
                                        Approve
                                    </Button>
                                </div>
                            )}
                        </Card>
                    )}
                    {lot.approval_required && ! lot.approved_at && ! lot.rejected_at && (
                        <Card extra={"w-1/2 mt-3 px-6 py-6"}>
                            {/* Header */}
                            <div className="w-full px-[8px] mb-4">
                                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                    Approval Required
                                </h4>
                                <p className="mt-1 text-base text-gray-600">
                                    Lot submitted after the auction went live must be approved by an admin.
                                </p>
                            </div>

                            {canApproveLots && (
                                <div className="flex gap-4 items-center justify-end">
                                    <Button color="red-outline" onClick={rejectLot}>
                                        Reject
                                    </Button>
                                    <Button onClick={approveLot}>
                                        Approve
                                    </Button>
                                </div>
                            )}
                        </Card>
                    )}

                    <LotForm lot={lot}
                             setLot={setLot}
                             auction={auction}
                             setAuction={setAuction}
                             submit={submit}
                             canApproveLots={canApproveLots}
                             modified={modified}
                    />
                </div>
            )}
        </LotLayout>
    )
}

export default Edit