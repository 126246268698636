import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { Taxes } from 'services/settings'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import Label from 'components/fields/Label'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'

const defaultTax = {
    amount: 0
}

const SettingsTaxes = () => {
    const [ visible, setVisible ] = useState(false)
    const [ dialogTitle, setDialogTitle ] = useState('Create Sales Tax')
    const [ taxes, setTaxes ] = useState([])
    const [ tax, setTax ] = useState(defaultTax)

    const loadTaxes = () => {
        Taxes.index({}, ({ data }) => {
            setTaxes(data.taxes)
        })
    }

    const deleteTax = (t) => {
        Taxes.delete(t.id, () => {
            loadTaxes()
        })
    }

    useEffect(() => {
        loadTaxes()
    }, [])

    const headerElement = (
        <div className="text-lg font-bold">
            {dialogTitle}
        </div>
    )

    const footerContent = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={() => saveTax()}>
                Save
            </Button>
        </div>
    )

    const ActionsTemplate = (rowData) => {
        return (
            <div className="flex gap-2 items-center">
                <Button
                    onClick={() => {
                        setTax(rowData)
                        setDialogTitle('Edit Sales Tax')
                        setVisible(true)
                    }}
                >Edit</Button>
                <Button
                    color="red"
                    onClick={() => {
                        if (window.confirm("Are you sure you want to delete?")) {
                            deleteTax(rowData)
                        }
                    }}>Delete</Button>
            </div>
        )
    }

    const AmountTemplate = (rowData) => {
        let amount = rowData.amount

        return (
            <>
                {amount}%
            </>
        )
    }

    const setData = (k, v) => {
        setTax({
            ...tax,
            [k]: v
        })
    }
    const saveTax = () => {
        const callback = () => {
            setVisible(false)
            setTax(defaultTax)
            loadTaxes()
        }

        if (tax.id) {
            Taxes.update(tax.id, tax, callback)
        } else {
            Taxes.create(tax, callback)
        }
    }

    return (
        <SettingsLayout expandedKeys={['finance', 'invoices']}>
            <div className="flex w-full justify-end">
                <Button
                    onClick={() => {
                        setDialogTitle('Create Sales Tax')
                        setVisible(true)
                    }}
                >
                    Create
                </Button>
            </div>

            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={headerElement} footer={footerContent}
                    style={{ width: '50rem' }}
                    onHide={() => setVisible(false)}>
                <div className="grid grid-cols-2 gap-2 items-start">
                    <InputField
                        id="tax_name"
                        label="Name"
                        value={tax.name || ''}
                        onChange={(e) => setData('name', e.target.value)}
                    />

                    <div className="col-span-2 mt-2">
                        <Label htmlFor="tax_amount">
                            Amount
                        </Label>
                        <div className="p-inputgroup flex-1 mt-2">
                            <InputNumber
                                inputId="tax_amount"
                                value={tax.amount || ''}
                                maxFractionDigits={2}
                                onChange={(e) => setData('amount', e.value)}
                            />
                            <span className="p-inputgroup-addon">%</span>
                        </div>
                    </div>
                </div>
            </Dialog>

            <DataTable className="text-sm" value={taxes} lazy dataKey="id">
                <Column field="name" header="Name" />
                <Column header="Amount" body={AmountTemplate} />
                <Column body={ActionsTemplate} />
            </DataTable>
        </SettingsLayout>
    )
}

export default SettingsTaxes