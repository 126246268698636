import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/notes', {
        params
    }).then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/notes/create', data)
        .then(then)
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/note/' + id + '/update', data)
        .then(then)
}

export const deleteNote = (id, data, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/note/' + id + '/delete', data)
        .then(then)
}