import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { photos as loadPhotos } from 'services/items'
import ItemLayout from './components/ItemLayout'
import ItemPhotoGallery from './components/ItemPhotoGallery'

const ItemPhotos = () => {
    const { id } = useParams()
    const [ item, setItem ] = useState(null)

    useEffect(() => {
        loadPhotos(id, {}, ({ data }) => {
            setItem(data.item)
        })
    }, [id])

    return (
        <ItemLayout id={id} item={item}>
            <div className="w-full">
                {item && (
                    <ItemPhotoGallery key={item.id} item={item} />
                )}
            </div>
        </ItemLayout>
    )
}

export default ItemPhotos