import { createContext, useContext, useReducer } from 'react'

export const NotificationsContext = createContext(null)
export const NotificationsDispatchContext = createContext(null)

export const NotificationsProvider = ({ initial = [], children }) => {
    const [ notifications, dispatch ] = useReducer(notificationsReducer, initial)

    return (
        <NotificationsContext.Provider value={notifications}>
            <NotificationsDispatchContext.Provider value={dispatch}>
                {children}
            </NotificationsDispatchContext.Provider>
        </NotificationsContext.Provider>
    )
}

export function useNotifications() {
    return useContext(NotificationsContext);
}

export function useNotificationsDispatch() {
    return useContext(NotificationsDispatchContext);
}

const notificationsReducer = (notifications, action) => {
    switch (action.type) {
        case 'success': {
            notifications.push(action)

            return [
                ...notifications
            ];
        }

        case 'remove': {
            return [
                ...notifications.filter(notification => notification !== action.notification)
            ]
        }

        case 'reset': {
            return []
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
