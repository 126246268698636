import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import SortablePhoto from 'views/admin/auctions/components/SortablePhoto'
import axios from 'axios'
import UploadLimits from 'components/uploads/UploadLimits'

const ItemPhotoGallery = ({ item }) => {
    const [ assets, setAssets ] = useState(item.assets)
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (e) => {
        const { active, over } = e

        if (active.id !== over.id) {
            setAssets((assets) => {
                const oldIndex = assets.findIndex(asset => asset.id === active.id)
                const newIndex = assets.findIndex(asset => asset.id === over.id)

                const resorted = arrayMove(assets, oldIndex, newIndex)

                axios.post(process.env.REACT_APP_API_URL + `/item/${item.id}/photos/sort`, {
                    'order': resorted.map(asset => asset.id)
                })

                return resorted
            })
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData()

        acceptedFiles.forEach((file) => {
            formData.append("files[]", file)
        })

        axios.post(process.env.REACT_APP_API_URL + `/item/${item.id}/photos/upload`, formData)
            .then((response) => {
                setAssets(response.data.item.assets)
            })
    }, [])

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        }
    })

    const onDelete = (asset) => {
        axios.delete(process.env.REACT_APP_API_URL + `/item/${item.id}/photos/${asset.id}/delete`)
            .then((response) => {
                setAssets(response.data.item.assets)
            })
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext items={assets}>
                <div className="border border-gray-200 rounded-lg px-4 py-4 mb-2">
                    <div className="text-lg font-bold">{item.name}</div>

                    <div {...getRootProps()} className="bg-gray-200 rounded-lg px-4 pb-4 mt-2">
                        <div onClick={open}>
                            <input {...getInputProps()} />

                            <p className="text-center py-4">
                                { isDragActive ? (
                                    <>
                                        Drop the files here...
                                    </>
                                ) : (
                                    <>
                                        Drag &amp; drop your files or <span className="underline cursor-pointer">Browse</span>
                                    </>
                                )}
                            </p>

                            <UploadLimits />
                        </div>

                        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                            {assets.map((asset) => (
                                <SortablePhoto key={asset.id} asset={asset} onDelete={onDelete} />
                            ))}
                        </div>
                    </div>
                </div>
            </SortableContext>
        </DndContext>
    )
}

export default ItemPhotoGallery