import { useEffect, useState } from 'react'
import { all as allAuctions } from 'services/auctions'
import { transfer } from 'services/lots'
import ModalForm from 'components/modal/ModalForm'
import SelectField from 'components/fields/SelectField'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const AuctionTransferModal = ({ lots, resetLots, open, setOpen }) => {
    const notify = useNotificationsDispatch()
    const [ auctions, setAuctions ] = useState([])
    const [ data, setData ] = useState({
        lot_ids: []
    })

    useEffect(() => {
        allAuctions({}, ({ data }) => {
            setAuctions(data.auctions)
        })
    }, [])

    useEffect(() => {
        setValue('lot_ids', lots)
    }, [lots])

    const setValue = (k, v) => {
        setData({
            ...data,
            [k]: v
        })
    }

    return (
        <ModalForm
            title="Transfer To Auction"
            submitAction="Transfer"
            open={open}
            setOpen={setOpen}
            onSubmit={(e, after) => {
                transfer(data, () => {
                    if (resetLots) {
                        resetLots(data.auction_id)
                    }

                    after()

                    notify({
                        message: 'Transferred to auction.',
                        title: 'Success!',
                        type: 'success',
                    })
                })
            }}
        >
            <div className="flex flex-col gap-4 justify-center mt-6 mb-2">
                <SelectField
                    options={auctions?.map(auction => {
                        return {
                            label: auction.name,
                            value: auction.id
                        }
                    })}
                    value={data.auction_id || ''}
                    onChange={(e) => setValue('auction_id', e.target.value)}
                    label="Auction"
                />
            </div>
        </ModalForm>
    )
}

export default AuctionTransferModal