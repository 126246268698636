import { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import { FaPencil } from 'react-icons/fa6'
import { update } from 'services/locations'
import Location from '../Location'

const EditLocationModal = ({ location, after }) => {
    const [ visible, setVisible ] = useState(false)
    const [ data, setData ] = useState(null)
    const notify = useNotificationsDispatch()

    useEffect(() => {
        setData({
            ...location,
            editing: true
        })
    }, [location])

    const submit = () => {
        update(location.id, data, ({ data }) => {
            setVisible(false)

            notify({
                message: 'Location has been updated.',
                title: 'Success!',
                type: 'success',
            })

            if (after) {
                after(data.location)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Edit Location
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={submit}>
                Submit
            </Button>
        </div>
    )

    return (
        <div className="-mt-0.5">
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer} style={{ width: "40rem" }}
                    onHide={() => setVisible(false)}>
                <div className="mt-4">
                    <Location
                        location={data}
                        setLocation={setData}
                        hideButtons={true}
                    />
                </div>
            </Dialog>

            <Button size="sm" onClick={() => {
                setVisible(true)
            }}>
                <span className="flex gap-2 items-center">
                    <FaPencil className="w-4 h-4" />
                    <span>Edit Location</span>
                </span>
            </Button>
        </div>
    )
}

export default EditLocationModal