import {useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PortalLayout from './components/PortalLayout'
import { get } from 'services/portals'
import ActionPanel from 'components/panels/ActionPanel'
import DeletePortalModal from './modals/DeletePortalModal'

const PortalDashboard = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ portal, setPortal ] = useState({})
    const [ visibleModal, setVisibleModal ] = useState(null)

    useEffect(() => {
        if (id) {
            get(id, ({ data }) => {
                setPortal(data.portal)
            })
        }
    }, [])

    return (
        <PortalLayout id={id} portal={portal}>
            {/* Matt asked for deleting portals to be impossible to do via the admin */}
            {/*<ActionPanel*/}
            {/*    color="red"*/}
            {/*    title="Delete Portal"*/}
            {/*    action="Delete"*/}
            {/*    onClick={() => setVisibleModal('delete')}*/}
            {/*>*/}
            {/*    Delete this portal, deactivating the domain*/}
            {/*</ActionPanel>*/}

            {/*<DeletePortalModal*/}
            {/*    portal={portal}*/}
            {/*    visible={visibleModal}*/}
            {/*    setVisible={setVisibleModal}*/}
            {/*    then={() => {*/}
            {/*        navigate('/admin/portals')*/}
            {/*    }}*/}
            {/*/>*/}
        </PortalLayout>
    )
}

export default PortalDashboard