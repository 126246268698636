import { states } from 'services/states'

const StateFilter = ({ value, setFilter }) => {
    return (
        <div className="flex gap-2 items-center">
            <label>
                State
            </label>

            <select
                multiple
                size="4"
                onChange={(e) => {
                    setFilter(
                        'state', Array.from(e.target.selectedOptions, option => option.value)
                    )
                }}
                value={value}
            >
                {states.map((state) => (
                    <option key={state.value} value={state.value}>{state.label}</option>
                ))}
            </select>
        </div>
    )
}

export default StateFilter