import {useCallback, useEffect, useState} from 'react'
import { Dialog } from 'primereact/dialog'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import Button from 'components/buttons/Button'
import { setLots } from 'services/lots'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import {
    FaCheck,
    FaXmark
} from 'react-icons/fa6'
import pluralize from 'pluralize'

const SetLotsModal = ({ auction, visible, setVisible, after }) => {
    const [ data, setData ] = useState({})

    const notify = useNotificationsDispatch()

    const close = () => {
        setVisible(false)
        setData({})
    }

    const submit = () => {
        setLots(auction.id, {
            ...data,
            process: true
        }, () => {
            close()

            notify({
                message: 'Lots updated successfully.',
                title: 'Success!',
                type: 'success',
            })

            after()
        })
    }

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData()

        acceptedFiles.forEach((file) => {
            formData.append("files[]", file)
        })

        axios.post(process.env.REACT_APP_API_URL + `/auction/${auction.id}/lots/set`, formData)
             .then(({ data }) => {
                 setData(data)
             })
    }, [])

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        multiple: false,
        noClick: true
    })

    const header = (
        <div className="text-lg font-bold">
            Set Lots
        </div>
    )

    const footer = (
        <div className="flex gap-4 items-center justify-between">
            <div>
                <Button href={`${process.env.REACT_APP_BASE_URL}/admin/auction/${auction.id}/lots/export?set_lots=1`} target="_blank">
                    Export Lots
                </Button>
            </div>
            <div>
                <Button color="outline" onClick={() => close()}>
                    Cancel
                </Button>
                <Button onClick={submit}>
                    Submit
                </Button>
            </div>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer} style={{ width: "40rem" }}
                onHide={close}
        >
            {data.counts ? (
                <>
                    {data.counts.matched ? (
                        <div className="flex gap-4 items-center bg-green-50 text-green-700 border border-green-400 px-4 py-2 mb-1 rounded-lg">
                            <FaCheck
                                className="w-5 h-5"
                            />
                            <div>{data.counts.matched} valid {pluralize('lot', data.counts.matched)} detected within file.</div>
                        </div>
                    ) : ''}
                    {data.counts.unmatched ? (
                        <div className="flex gap-4 items-center bg-red-50 text-red-700 border border-red-400 px-4 py-2 mb-1 rounded-lg">
                            <FaXmark
                                className="w-5 h-5"
                            />
                            <div>{data.counts.unmatched} invalid {pluralize('lot', data.counts.unmatched)} detected within file.</div>
                        </div>
                    ) : ''}
                </>
            ) : (
                <div {...getRootProps()} className="bg-gray-200 rounded-lg px-4">
                    <div onClick={open}>
                        <input {...getInputProps()} />

                        <p className="text-center py-4">
                            { isDragActive ? (
                                <>
                                    Drop the file here...
                                </>
                            ) : (
                                <>
                                    Drag &amp; drop your Excel file or <span className="underline cursor-pointer">Browse</span>
                                </>
                            )}
                        </p>
                    </div>
                </div>
            )}
        </Dialog>
    )
}

export default SetLotsModal