import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Card from 'components/card'
import Button from 'components/buttons/Button'
import AuctionForm from './components/AuctionForm'
import { create, get } from 'services/auctions'
import { can, useAuth } from 'components/auth/AuthContext'
import Breadcrumbs from 'components/navbar/Breadcrumbs'

const Create = ({ template = false }) => {
    const auth = useAuth()
    const canCreateTemplates = can(auth, 'create_templates')
    const canUpdateUnrestricted = can(auth, 'update_auctions_unrestricted')

    const { id } = useParams()
    const navigate = useNavigate()
    const [ auction, setAuction ] = useState({
        name: '',
        status: 'pending',
        timezone: 'America/Denver',
        is_template: template ? 1 : 0,
        counts: {},
        config: {
            require_terms_approval: true
        }
    })
    const [ company, setCompany ] = useState(null)

    const setData = (k, v) => {
        setAuction({
            ...auction,
            [k]: v
        })
    }

    const submit = (e, template = false) => {
        if (template) {
            auction.is_template = 1
        }

        create(auction, ({ data }) => {
            navigate('/admin/auction/' + data.auction.id)
        })
    }

    const submitTemplate = (e) => {
        submit(e, true)
    }

    useEffect(() => {
        if (id) {
            // Clone the auction

            get(id, ({ data }) => {
                setAuction({
                    ...data.auction,
                    id: null,
                    is_template: false,
                    is_global: false,
                    template_id: id,
                    clone: id,
                    clone_lots: false,
                    status: 'pending'
                })
                setCompany(data.auction.company)
            })
        }
    }, [id])

    return (
        <>
            {id && (
                <div className="ml-[11px] mt-[-72px] fixed z-10">
                    <Breadcrumbs trail={[
                        {
                            href: "#",
                            title: "Copy Auction"
                        }
                    ]} />
                </div>
            )}

            <div className="pt-4">
                <div className="flex gap-6">
                    <Card extra={"w-1/2 mt-3 px-6 py-6"}>
                        {/* Header */}
                        <div className="w-full px-[8px] mb-4">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Create Auction
                            </h4>
                            <p className="mt-1 text-base text-gray-600">
                                Basic information related to the auction
                            </p>
                        </div>

                        <AuctionForm
                            auction={auction}
                            company={company}
                            setAuction={setAuction}
                            setCompany={setCompany}
                            setData={setData}
                        />

                        {(canUpdateUnrestricted || auction.template_id) && (
                            <div className="flex w-full justify-end gap-4 items-center">
                                {template ? (
                                    <Button onClick={submit}>
                                        Create Template
                                    </Button>
                                ) : (
                                    <>
                                        {canCreateTemplates && (
                                            <Button onClick={submitTemplate} color="outline">
                                                Create as Template
                                            </Button>
                                        )}

                                        <Button onClick={submit}>
                                            Create
                                        </Button>
                                    </>
                                )}
                            </div>
                        )}
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Create