import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'services/clients'
import ClientLayout from './components/ClientLayout'
import CompaniesIndex from '../companies/index'

const ClientCompanies = () => {
    const { id } = useParams()
    const [ client, setClient ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            setClient(data.client)
        })
    }, [])

    if (! client) {
        return
    }

    return (
        <ClientLayout id={id} client={client}>
            <div className="w-full">
                <CompaniesIndex client={client} />
            </div>
        </ClientLayout>
    )
}

export default ClientCompanies