import Btn from 'components/buttons/Button'
import StatementsTable from './components/StatementsTable'
import { index } from 'services/statements'

import {
    FaPlus,
} from 'react-icons/fa6'

export default () => {
    return (
        <div className="mt-3">
            <div className="pt-3 mb-3 flex justify-end hidden">
                <Btn href="/admin/statements/create">
                    <span className="flex gap-2 items-center">
                        <FaPlus className="w-5 h-5" />
                        <span>New Statement</span>
                    </span>
                </Btn>
            </div>

            <StatementsTable loadStatements={index} />
        </div>
    )
}