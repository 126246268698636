import React, { useEffect, useState } from 'react'
import { getNotifications } from 'services/auth'
import {
    markNotificationRead,
    markAllNotificationsRead,
    useAuthDispatch
} from 'components/auth/AuthContext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from 'components/buttons/Button'
import { FaCheck } from 'react-icons/fa6'

const Notifications = () => {
    const [ notifications, setNotifications ] = useState({})
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)

    const navigate = useNavigate()
    const authDispatch = useAuthDispatch()

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )
    }, [searchParams])

    useEffect(() => {
        loadNotifications()
    }, [page])

    const loadNotifications = () => {
        getNotifications({
            page
        }, ({ data }) => {
            setNotifications(data)
        })
    }

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const markRead = (id) => {
        markNotificationRead(authDispatch, id, () => {
            loadNotifications()
        })
    }

    return (
        <div className="mt-3">
            <div className="pt-3 mb-3 flex justify-end">
                <Button onClick={() => {
                    markAllNotificationsRead(authDispatch, () => {
                        loadNotifications()
                    })
                }}>
                    <div className="flex gap-2 items-center">
                        <FaCheck className="w-4 h-4" />
                        Mark all as read
                    </div>
                </Button>
            </div>
            <DataTable className="text-sm" value={notifications.data} lazy dataKey="id"
                       paginator first={notifications.from - 1} rows={notifications.per_page} totalRecords={notifications.total} onPage={onPage}
                       rowClassName="cursor-pointer"
                       onRowClick={(e) => {
                           const notification = e.data

                           markRead(notification.id)

                           if (notification.lot) {
                               navigate('/admin/lot/' + notification.lot.id + '/edit')
                           } else if (notification.auction) {
                               navigate('/admin/auction/' + notification.auction.id + '/edit')
                           } else if (notification.user) {
                               navigate('/admin/user/' + notification.user.id)
                           }
                       }}
            >
                <Column field="data.title" header="Title" />
                <Column
                    header="Date"
                    field="created_at"
                    body={(notification) => {
                        return new Date(notification.created_at).toLocaleString()
                    }}
                />
                <Column
                    header="Context"
                    body={(notification) => {
                        return (
                            <div>
                                {notification.lot && (
                                    <div>
                                      Lot #{notification.lot.number}: {notification.lot.name}
                                    </div>
                                )}
                                {notification.auction && (
                                    <div>
                                      Auction: {notification.auction.name}
                                    </div>
                                )}
                            </div>
                        )
                    }}
                />
                <Column
                    header="Actions"
                    body={(notification) => {
                        return (
                            <>
                                {! notification.read_at && (
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            markRead(notification.id)
                                        }}
                                    >
                                        <div className="flex gap-2 items-center">
                                            <FaCheck className="w-4 h-4" />
                                            Mark as read
                                        </div>
                                    </Button>
                                )}
                            </>
                        )
                    }}
                />
            </DataTable>
        </div>
    )
}

export default Notifications