import ReportsLayout from '../components/ReportsLayout'

const RevenueReport = () => {
    return (
        <ReportsLayout
            report="revenue"
            defaultFields={[
                'company_name',
                'consignor_code',
                'state',
                'total_payments',
                'total_premium',
                'no_sale_fees',
                'auctions_count',
                'lots_count'
            ]}
        />
    )
}

export default RevenueReport