import { useEffect, useState } from 'react'
import { returnToInventory } from 'services/lots'
import ModalForm from 'components/modal/ModalForm'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const ReturnToInventoryModal = ({ lots, resetLots, open, setOpen }) => {
    const notify = useNotificationsDispatch()
    const [ data, setData ] = useState({
        lot_ids: []
    })

    useEffect(() => {
        setValue('lot_ids', lots)
    }, [lots])

    const setValue = (k, v) => {
        setData({
            ...data,
            [k]: v
        })
    }

    return (
        <ModalForm
            title="Return to Inventory"
            open={open}
            setOpen={setOpen}
            onSubmit={(e, after) => {
                returnToInventory(data, () => {
                    if (resetLots) {
                        resetLots()
                    }

                    after()

                    notify({
                        message: 'Returned to inventory.',
                        title: 'Success!',
                        type: 'success',
                    })
                })
            }}
        >
            Are you sure you want to remove the lots from this auction and return to inventory?
        </ModalForm>
    )
}

export default ReturnToInventoryModal