import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/clients', {
        params
    }).then(then)
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/client/' + id)
        .then(then)
}

export const all = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/clients/all', {
        params
    }).then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/clients/create', data)
        .then(then)
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/client/' + id + '/update', data)
        .then(then)
}