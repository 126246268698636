import React, { useState, useEffect } from 'react';
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'

const ModalTax = ({ show, onClose, fieldValues, onSave, setData }) => {
    const [values, setValues] = useState(fieldValues);

    const optionsTaxes = [
        { value: 'private-property', label: 'Private Property Impound (0%)' },
    ];

    const optionsTypes = [
        { value: 'exclusive', label: 'Exclusive' },
        { value: 'inclusive', label: 'Inclusive' },
        { value: 'exempt', label: 'Exempt' },
    ];

    useEffect(() => {
        setValues(fieldValues);
    }, [fieldValues]);

    if (!show) {
        return null;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSave = () => {
        // add type to values
        values.formType = 'Tax';

        onSave(values);
        onClose();
    };

    const selectTax = (v) => {
        setData('tax', v)
    }

    const selectTaxType = (v) => {
        setData('taxType', v)
    }

    console.log(fieldValues);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-xl">
                <h2 className="text-xl mb-4">Tax</h2>
                

                <div className="grid grid-cols-1 gap-4">
                    <SelectField
                        extra="mb-3"
                        label="Tax"
                        id="tax"
                        value={values.tax || ''}
                        onChange={(e) => selectTax(e.target.value)}
                        options={optionsTaxes}
                    />

                    <InputField
                        extra="mb-3"
                        label="Tax amount (%)"
                        id="percentage"
                        value={values.percentage || ''}
                        onChange={(e) => setData('percentage', e.target.value)}
                        type="text"
                    />

                    <SelectField
                        extra="mb-3"
                        label="Tax Type"
                        id="taxType"
                        value={values.taxType || ''}
                        onChange={(e) => selectTaxType(e.target.value)}
                        options={optionsTypes}
                    />

                </div>

                <div className="flex justify-end gap-4">
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">Cancel</button>
                    <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Save</button>
                </div>
            </div>
        </div>
    );
};

export default ModalTax;