import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { deleteCompany } from 'services/companies'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import InputField from 'components/fields/InputField'

const DeleteCompanyModal = ({ company, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()
    const [ deleteConfirmation, setDeleteConfirmation ] = useState('')
    const [ error, setError ] = useState(false)

    const onClick = () => {
        if (deleteConfirmation === 'DELETE') {
            setError(false)
        } else {
            setError(true)
            return
        }

        deleteCompany(company.id, () => {
            setVisible(false)

            notify({
                message: 'Company has been deleted.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(company)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Delete Company
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button color="red" onClick={onClick}>
                Delete
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'delete'}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}
        >
            <p>Are you sure you want to delete this company?</p>

            <h3 className="flex items-center gap-2 font-bold mt-4">
                <ExclamationTriangleIcon className="w-5 h-5 text-red-600" />
                <span>WARNING!</span>
            </h3>

            <p>All associated information, such as auctions, lots, invoices and statements, will also be deleted. To confirm, please type "DELETE" below.</p>

            <InputField
                onChange={(e) => setDeleteConfirmation(e.target.value)}
                value={deleteConfirmation}
            />

            {error && (
                <p className="italic text-red-600 mt-2">
                    Please acknowledge before continuing.
                </p>
            )}
        </Dialog>
    )
}

export default DeleteCompanyModal