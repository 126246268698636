import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { FaFile, FaFilePdf, FaDownload } from 'react-icons/fa6'

const SortableAsset = ({ asset, onDelete }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: asset.id
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const isPdf = asset.url.toLowerCase().indexOf('pdf') > 0

    return (
        <div ref={setNodeRef} className="relative" style={style} {...attributes} {...listeners}>
            <div className="absolute flex w-full justify-end">
                <div onClick={() => onDelete(asset)} className="p-2">
                    <XCircleIcon className="w-5 h-5 text-black" />
                </div>
            </div>

            <div>
                {asset.type === 'photo' ? (
                    <img src={asset.url} alt="" className="h-48 object-cover" />
                ) : (
                    <span className="flex flex-col items-center justify-center h-48 gap-4">
                        {isPdf ? (
                            <FaFilePdf className="w-16 h-16" />
                        ) : (
                            <FaFile className="w-16 h-16" />
                        )}

                        <p className="text-center">{asset.meta?.name || 'Document'}</p>
                    </span>
                )}

                <a href={asset.download_url} target="_blank" className="flex gap-2 justify-center items-center text-sm mt-4">
                    <FaDownload className="w-4 h-4" />
                    <span>Download</span>
                </a>
            </div>
        </div>
    );
}

export default SortableAsset