import { useState, useEffect } from 'react';
import Table from './components/Table';
import axios from 'axios'

const Review = ({ auction, data, hash, afterSubmit }) => {
    const [ selectedRows, setSelectedRows ] = useState(data.filter(row => {
        return row.attrs?.accept
    }))

    const [ disabled, setDisabled ] = useState(false)

    useEffect(() => {
        setDisabled(selectedRows.length === 0)
    }, [selectedRows])

    function handleSubmit(e) {
        e.preventDefault()

        axios.post(process.env.REACT_APP_API_URL + `/auction/${auction.id}/lots/import/${hash}`, {
            selected: selectedRows
        }).then(({ data }) => {
            afterSubmit(data)
        })
    }

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-800 leading-tight">Bulk Add Lots: Review</h2>

            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <p className="mt-8 mb-4">
                    Please review the data before importing. You will have the ability to edit lots after import.
                </p>

                <p className="mb-4">
                    Any rows highlighted in <strong className="bg-green-100 text-green-600">green</strong> are consistent with data we found in the NHTSA VIN database.
                </p>

                <p className="mb-4">
                    Please double check any rows highlighted in <strong className="bg-red-100 text-red-600">red</strong>. These records are inconsistent. Click the checkbox next to the row to import anyway.
                </p>

                <p className="mb-4">
                    Any rows highlighted in <strong className="bg-yellow-100 text-yellow-600">yellow</strong> are VINs that already exist &amp; will automatically be moved to this auction if you choose.
                </p>

                <p className="mb-4">
                    Any rows highlighted in <strong className="bg-gray-200 text-gray-600">gray</strong> cannot be imported. Either they've already been imported or have duplicate lot numbers. These will need to be corrected on your feed, so you'll have to go back and make corrections before proceeding.
                </p>

                <p className="mb-4">
                    Any lots without a standard 17-character VIN will be marked as <b>Other</b>. You will need to manually select these for import using the checkboxes.
                </p>

                <form onSubmit={handleSubmit}>
                    <Table data={data} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />

                    <div className="mt-8">
                        <button
                            type="submit"
                            disabled={disabled}
                            title={disabled ? 'There are no rows selected' : ''}
                            className={(disabled ? 'bg-blue-100 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-500' ) + " block rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
                        >
                            Import Selected Rows
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Review