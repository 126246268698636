import { createContext, useContext, useEffect, useReducer } from 'react'
import {
    getPermissions,
    getUnreadNotifications,
    markNotificationRead as postMarkNotificationRead,
    markAllNotificationsRead as postMarkAllNotificationsRead
} from 'services/auth'

export const AuthContext = createContext(null)
export const AuthDispatchContext = createContext(null)

export const AuthProvider = ({ initial = {}, children }) => {
    const [ auth, dispatch ] = useReducer(authReducer, initial)

    useEffect(() => {
        updatePermissions(dispatch)
        updateNotifications(dispatch)

        const intervalId = setInterval(
            () => {
                updateNotifications(dispatch)
            }
        , 5000)

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    return (
        <AuthContext.Provider value={auth}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthContext.Provider>
    )
}

export const updatePermissions = (dispatch, then) => {
    getPermissions({}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'update',
            permissions: data
        })
    })
}

export const updateNotifications = (dispatch, then) => {
    getUnreadNotifications({}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'updateNotifications',
            notifications: data
        })
    })
}

export const markNotificationRead = (dispatch, id, then) => {
    postMarkNotificationRead(id, {}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'updateNotifications',
            notifications: data
        })
    })
}

export const markAllNotificationsRead = (dispatch, then) => {
    postMarkAllNotificationsRead({}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'updateNotifications',
            notifications: data
        })
    })
}

export function useAuth() {
    return useContext(AuthContext);
}

export function useAuthDispatch() {
    return useContext(AuthDispatchContext);
}

export function can(auth, permission) {
    if (auth.permissions?.scoped) {
        let scopedMatch = false

        auth.permissions?.scoped.forEach((scope) => {
            scopedMatch = scope.permissions[permission]
        })

        if (scopedMatch) {
            return true
        }
    }

    return auth.permissions?.global[permission]
}

const authReducer = (auth, action) => {
    switch (action.type) {
        case 'update': {
            return {
                ...auth,
                permissions: action.permissions
            };
        }

        case 'updateNotifications': {
            return {
                ...auth,
                notifications: action.notifications
            }
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
