import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

const SortableVideo = ({ asset, onDelete, onEdit }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: asset.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className="relative"
      style={style}
      {...attributes}
      {...listeners}
    >
      <img
        src={asset.url}
        alt=""
        className="h-auto w-full cursor-move object-contain"
      />

      <div className="px-1 py-2 text-center text-sm font-bold">
        {asset.name}
      </div>

      <div className="flex items-center justify-center gap-4 pt-2">
        <FaPencilAlt className="h-5 w-5" onClick={() => onEdit(asset)} />

        <FaTrashAlt className="h-5 w-5" onClick={() => onDelete(asset.id)} />
      </div>
    </div>
  );
};

export default SortableVideo;
