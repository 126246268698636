import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumbs from 'components/navbar/Breadcrumbs'
import Information from './components/Information'
import { get, create, update } from 'services/clients'
import ClientLayout from './components/ClientLayout'

const EditClient = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ client, setClient ] = useState({})

    useEffect(() => {
        if (id) {
            get(id, ({ data }) => {
                setClient(data.client)
            })
        }
    }, [])

    const submit = () => {
        if (id) {
            update(id, client, ({ data }) => {
                navigate('/admin/clients')
            })
        } else {
            create(client, ({ data }) => {
                navigate('/admin/clients')
            })
        }
    }

    return (
        <ClientLayout id={id} client={client}>
            <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <div className="flex flex-col gap-5">
                    <Information client={client}
                                 setClient={setClient}
                                 submit={submit} />
                </div>
            </div>
        </ClientLayout>
    )
}

export default EditClient