import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialUser = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        user: initialUser,
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload
            localStorage.setItem('user', JSON.stringify(action.payload))
        },
        logoutSuccess: (state, action) =>  {
            state.user = null
            localStorage.removeItem('user')
        },
    },
});

export default slice.reducer

// Actions
export const { loginSuccess, logoutSuccess } = slice.actions

export const checkAuth = (then, fail) => async dispatch => {
    return await axios.get(process.env.REACT_APP_API_URL + '/user').then(response => {
        handleLogin(response, dispatch, then)
    }).catch(error => {
        if (fail) {
            fail(error)
        }
    })
}

const handleLogin = (response, dispatch, then) => {
    const permissions = response.data.permissions

    if (permissions.admin.can_access) {
        dispatch(loginSuccess(response.data))
        then()
    } else {
        window.location.href = permissions.admin.redirect_to_buyer
    }
}

export const login = (data, then, fail) => async dispatch => {
    return await axios.get(process.env.REACT_APP_BASE_URL + '/sanctum/csrf-cookie').then(response => {
        axios.post(process.env.REACT_APP_BASE_URL + '/login', data).then(response => {
            axios.get(process.env.REACT_APP_API_URL + '/user').then(response => {
                handleLogin(response, dispatch, then)
            })
        }).catch(error => {
            if (fail) {
                fail(error)
            }
        })
    })
}

export const logout = (then) => async dispatch => {
    axios.post(process.env.REACT_APP_BASE_URL + '/logout').then(response => {
        dispatch(logoutSuccess())
        then()
    }).catch(error => {
        console.log(error)
    })
}

export const impersonate = (user, then, fail) => async dispatch => {
    axios.post(process.env.REACT_APP_BASE_URL + '/admin/impersonate/' + user.id).then(response => {
        dispatch(checkAuth(then, fail))
    })
}

export const stopImpersonating = (then, fail) => async dispatch => {
    axios.post(process.env.REACT_APP_BASE_URL + '/impersonate/stop').then(response => {
        dispatch(checkAuth(then, fail))
    })
}