import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from 'services/clients'
import ClientLayout from './components/ClientLayout'
import { MdBusiness } from 'react-icons/md'
import { FaUsers } from 'react-icons/fa6'
import { AuctionStats } from '../auctions/components/AuctionStats'

const ClientDashboard = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ client, setClient ] = useState(null)
    const [ stats, setStats ] = useState([])

    useEffect(() => {
        if (id) {
            get(id, ({ data }) => {
                setClient(data.client)
            })
        }
    }, [id])

    useEffect(() => {
        if (! client) {
            return
        }

        setStats(
            buildStats()
        )
    }, [client])

    const buildStats = () => {
        return [
            {
                icon: MdBusiness,
                title: 'Companies',
                value: client.counts.companies,
                link: `/admin/client/${client.id}/companies`
            },
            {
                icon: FaUsers,
                title: 'Users',
                value: client.counts.users,
                link: `/admin/client/${client.id}/users`
            }
        ]
    }

    if (! client) {
        return
    }

    return (
        <ClientLayout id={id} client={client}>
            <div>
                <AuctionStats stats={stats} />
            </div>
        </ClientLayout>
    )
}

export default ClientDashboard