import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Information from './components/Information'
import AuctionSettings from './components/AuctionSettings'
import Password from './components/Password'
import UserLayout from './components/UserLayout'
import { can, useAuth } from 'components/auth/AuthContext'
import { get, create, update } from 'services/users'

const EditUser = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ user, setUser ] = useState({})

    const auth = useAuth()
    const canManageSettings = can(auth, 'manage_user_settings')

    useEffect(() => {
        if (id) {
            get(id, ({ data }) => {
                setUser(data.user)
            })
        }
    }, [])

    const submit = (then, fail) => {
        if (id) {
            update(id, user, ({ data }) => {
                navigate('/admin/user/' + id)

                if (then) {
                    then(data)
                }
            }, fail)
        } else {
            create(user, ({ data }) => {
                navigate('/admin/user/' + data.user.id)

                if (then) {
                    then(data)
                }
            }, fail)
        }
    }

    const submitPassword = (then, fail) => {
        submit(then, fail)
    }

    return (
        <UserLayout id={id} user={user}>
            <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <div className="flex flex-col gap-5">
                    <Information user={user}
                                 setUser={setUser}
                                 submit={submit}
                    />

                    {canManageSettings && (
                        <AuctionSettings user={user}
                                         setUser={setUser}
                                         submit={submit}
                        />
                    )}

                    {user.id && (
                        <Password user={user}
                                  setUser={setUser}
                                  submit={submitPassword}
                        />
                    )}
                </div>
            </div>
        </UserLayout>
    )
}

export default EditUser