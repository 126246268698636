import { useAuth } from 'components/auth/AuthContext'

const UploadLimits = () => {
    const auth = useAuth()

    if (! auth.permissions?.limits?.upload) {
        return
    }

    const uploadLimits = auth.permissions.limits.upload

    return (
        <p className="text-center -mt-2 text-xs">
            Limit {uploadLimits.files} files / {uploadLimits.size}
        </p>
    )
}

export default UploadLimits