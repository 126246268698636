import { Link } from 'react-router-dom'

export const AuctionStats = ({ stats, condensed = false }) => {
    return (
        <div className={(condensed ? 'flex gap-4' : 'grid gap-4 grid-cols-8')}>
            {stats.map((stat, i) => (
                <AuctionStat stat={stat} key={i} />
            ))}
        </div>
    )
}

export const AuctionStat = ({ stat }) => {
    const Wrapper = stat.link ? Link : ({ children, className }) => {
        return (
            <div className={className}>{children}</div>
        )
    }

    return (
        <Wrapper to={stat.link} className="block py-4 w-36 rounded-lg bg-gradient-to-b from-slate-900 to-slate-700 text-white flex flex-col gap-1 items-center relative">
            <stat.icon className="w-6 h-6" />

            <div className="uppercase text-2xs">
                {stat.title}
            </div>

            <div className="mt-2 text-xl font-bold">
                {stat.value}
            </div>

            <div className="text-2xs">
                {stat.sub}
            </div>

            {stat.link && (
                <div className="absolute w-full h-full top-0 rounded-lg bg-gradient-to-bl from-slate-400 via-transparent via-25%"></div>
            )}
        </Wrapper>
    )
}