import { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import {
    BooleanFilterModes,
    FilterModes,
    SelectFilterModes
} from './FilterModes'
import FilterType from './FilterType'

const AdditionalFiltersModal = ({ visible, setVisible, filters, setFilters }) => {
    const [ local, setLocal ] = useState({})

    useEffect(() => {
        setLocal({
            ...filters
        })
    }, [filters])

    const close = () => {
        setVisible(false)
    }

    const apply = () => {
        setFilters({
            ...local
        })
        close()
    }

    const header = (
        <div className="text-lg font-bold">
            Additional Filters
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Close
            </Button>
            <Button onClick={apply}>
                Apply
            </Button>
        </div>
    )

    const updateFilter = (filter, k) => {
        const updated = {
            ...local
        }

        updated[k] = filter

        setLocal(updated)
    }

    const updateFilterMode = (mode, filter, k) => {
        updateFilter({
            ...filter,
            matchMode: mode
        }, k)
    }

    const updateFilterValue = (value, filter, k) => {
        updateFilter({
            ...filter,
            value
        }, k)
    }

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer} style={{ width: "40%" }}
                onHide={close}
        >
            {Object.entries(local).map(([ k, filter ]) => {
                const bool = filter.type === FilterType.BOOLEAN
                const select = filter.type === FilterType.SELECT
                const modes = bool ? BooleanFilterModes : (
                    select ? SelectFilterModes : FilterModes
                )

                return (
                    <div key={k} className="flex gap-4 items-center my-4">
                        <div className="w-32">{filter.label}</div>

                        <SelectField
                            extra="w-40"
                            options={modes}
                            value={filter.matchMode || ''}
                            onChange={(e) => updateFilterMode(e.target.value, filter, k)}
                        />

                        <div className="w-60">
                            {select ? (
                                <SelectField
                                    extra="w-full"
                                    options={filter.options}
                                    value={filter.value || ''}
                                    onChange={(e) => updateFilterValue(e.target.value, filter, k)}
                                />
                            ) : ! bool && (
                                <InputField
                                    value={filter.value || ''}
                                    onChange={(e) => updateFilterValue(e.target.value, filter, k)}
                                />
                            )}
                        </div>
                    </div>
                )
            })}
        </Dialog>
    )
}

export default AdditionalFiltersModal