import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { CompanyTypes } from 'services/settings'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const defaultType = {
    name: ''
}

const SettingsCompanyTypes = () => {
    const [ visible, setVisible ] = useState(false)
    const [ dialogTitle, setDialogTitle ] = useState('Create Company Type')
    const [ companyTypes, setCompanyTypes ] = useState([])
    const [ companyType, setCompanyType ] = useState(defaultType)

    const loadCompanyTypes = () => {
        CompanyTypes.index({}, ({ data }) => {
            setCompanyTypes(data.types)
        })
    }

    const deleteCompanyType = (type) => {
        CompanyTypes.delete(type.id, () => {
            loadCompanyTypes()
        })
    }

    useEffect(() => {
        loadCompanyTypes()
    }, [])

    const headerElement = (
        <div className="text-lg font-bold">
            {dialogTitle}
        </div>
    )

    const footerContent = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={() => saveCompanyType()}>
                Save
            </Button>
        </div>
    )

    const ActionsTemplate = (rowData) => {
        return (
            <div className="flex gap-2 items-center">
                <Button
                    onClick={() => {
                        setCompanyType(rowData)
                        setDialogTitle('Edit Company Type')
                        setVisible(true)
                    }}
                >
                    Edit
                </Button>
                <Button
                    color="red"
                    onClick={() => {
                        if (window.confirm("Are you sure you want to delete?")) {
                            deleteCompanyType(rowData)
                        }
                    }}
                >
                    Delete
                </Button>
            </div>
        )
    }

    const setData = (k, v) => {
        setCompanyType({
            ...companyType,
            [k]: v
        })
    }

    const saveCompanyType = () => {
        const callback = () => {
            setVisible(false)
            setCompanyType(defaultType)
            loadCompanyTypes()
        }

        if (companyType.id) {
            CompanyTypes.update(companyType.id, companyType, callback)
        } else {
            CompanyTypes.create(companyType, callback)
        }
    }

    return (
        <SettingsLayout expandedKeys={['auctions']}>
            <div className="flex w-full justify-end">
                <Button
                    onClick={() => {
                        setDialogTitle('Create Bid Increment')
                        setVisible(true)
                    }}
                >
                    Create
                </Button>
            </div>

            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={headerElement} footer={footerContent}
                    style={{ width: '50rem' }}
                    onHide={() => setVisible(false)}>
                <div className="grid grid-cols-2 gap-2 items-start">
                    <InputField
                        id="type_name"
                        label="Name"
                        value={companyType.name || ''}
                        onChange={(e) => setData('name', e.target.value)}
                    />
                </div>
            </Dialog>

            <DataTable className="text-sm" value={companyTypes} lazy dataKey="id">
                <Column field="name" header="Name" />
                <Column body={ActionsTemplate} />
            </DataTable>
        </SettingsLayout>
    )
}

export default SettingsCompanyTypes