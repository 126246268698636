import SelectField from 'components/fields/SelectField'

const DateFilter = ({ year, month, day, setFilter }) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    const days = []
    const years = []
    const currentYear = (new Date()).getFullYear()

    for (let i = 1; i <= 31; i++) {
        days.push({
            value: i,
            label: i
        })
    }

    // No need to list more years - we don't have data before then
    for (let i = currentYear; i >= 2022; i--) {
        years.push({
            value: i,
            label: i
        })
    }

    return (
        <div className="flex gap-2 items-center mb-4">
            <label>
                Date
            </label>

            <div className="flex gap-2 items-center">
                <SelectField
                    options={years}
                    placeholder="Year"
                    extra="w-20"
                    value={year}
                    onChange={(e) => {
                        setFilter('year', e.target.value)
                    }}
                />

                {year && (
                    <SelectField
                        options={months.map((option, k) => {
                            return {
                                value: k + 1,
                                label: option
                            }
                        })}
                        placeholder="Month"
                        extra="w-32"
                        value={month}
                        onChange={(e) => {
                            setFilter('month', e.target.value)
                        }}
                    />
                )}

                {year && month && (
                    <SelectField
                        options={days}
                        placeholder="Day"
                        extra="w-20"
                        value={day}
                        onChange={(e) => {
                            setFilter('day', e.target.value)
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default DateFilter