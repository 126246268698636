import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { Settings } from 'services/settings'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'

const BaseSettings = ({ name, children, value }) => {
    const notify = useNotificationsDispatch()
    const [ settings, setSettings ] = useState({})

    const updateSetting = (v) => {
        setSettings({
            ...settings,
            [name]: {
                value: v
            }
        })
    }

    const submit = () => {
        Settings.save({
            name,
            value: settings[name]?.value
        }, () => {
            notify({
                message: 'Setting saved.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    useEffect(() => {
        Settings.index({}, ({ data }) => {
            setSettings(data.settings)
        })
    }, [])

    useEffect(() => {
        updateSetting(value)
    }, [value])

    return (
        <SettingsLayout expandedKeys={['auctions']}>
            {children(settings[name]?.value, updateSetting)}

            <Button
                onClick={submit}
            >
                Save
            </Button>
        </SettingsLayout>
    )
}

const SettingsArchive = () => {
    return (
        <BaseSettings name="archive_window">
            {(value, updateSetting) => (
                <div className="mb-4">
                    <InputField
                        label="Archive Window (days)"
                        value={value}
                        onChange={(e) => updateSetting(e.target.value)}
                    />
                </div>
            )}
        </BaseSettings>
    )
}

export default SettingsArchive