import Breadcrumbs from 'components/navbar/Breadcrumbs'
import VerticalMenu from 'components/menu/VerticalMenu'
import { can, useAuth } from 'components/auth/AuthContext'

const ClientLayout = ({ id, client, children }) => {
    const auth = useAuth()
    const canUpdate = can(auth, 'update_clients')

    const menu = [
        { name: 'Dashboard', href: '/admin/client/' + id },
        canUpdate ? { name: 'Edit', href: `/admin/client/${id}/edit` } : null,
        { name: 'Companies', href: `/admin/client/${id}/companies` },
        { name: 'Users', href: `/admin/client/${id}/users` },
    ].filter(item => !!item)

    menu.forEach(item => {
        item.current = (window.location.pathname === item.href)
    })

    if (! id) {
        return (
            <div className="w-full">
                {children}
            </div>
        )
    }

    return (
        <>
            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/clients",
                        title: "Clients"
                    },
                    {
                        href: "#",
                        title: client.name
                    }
                ].filter(el => el !== null)} />
            </div>

            <div className="pt-4">
                <div className="flex gap-6">
                    <VerticalMenu items={menu} />

                    {children}
                </div>
            </div>
        </>
    )
}

export default ClientLayout