import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { XCircleIcon } from '@heroicons/react/24/solid'
import {
    FaArrowRotateLeft,
    FaArrowRotateRight
} from 'react-icons/fa6'
import Button from 'components/buttons/Button'

const SortablePhoto = ({ asset, onDelete, onRotate, onApprove, approval }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: asset.id
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const rotate = (degrees) => {
        onRotate(asset, degrees)
    }

    return (
        <div ref={setNodeRef} className="relative" style={style} {...attributes} {...listeners}>
            <div className="absolute flex w-full justify-end">
                <div onClick={() => onDelete(asset)} className="p-2">
                    <XCircleIcon className="w-5 h-5 text-black" />
                </div>
            </div>

            <img src={asset.url} alt="" className="h-48 object-contain mx-auto" />

            <div className="flex gap-4 items-center justify-center pt-2">
                <FaArrowRotateLeft
                    className="w-5 h-5"
                    onClick={() => rotate(90)}
                />

                {approval === 'admin' && (
                    <Button size="sm" color="outline" onClick={() => onApprove(asset)}>
                        Approve
                    </Button>
                )}

                <FaArrowRotateRight
                    className="w-5 h-5"
                    onClick={() => rotate(-90)}
                />
            </div>
        </div>
    );
}

export default SortablePhoto