import Btn from 'components/buttons/Button'
import InvoicesTable from './components/InvoicesTable'
import { index } from 'services/invoices'

import {
    FaPlus,
} from 'react-icons/fa6'

export default () => {
    return (
        <div className="mt-3">
            <div className="pt-3 mb-3 flex justify-end hidden">
                <Btn href="/admin/invoices/create">
                    <span className="flex gap-2 items-center">
                        <FaPlus className="w-5 h-5" />
                        <span>New Invoice</span>
                    </span>
                </Btn>
            </div>

            <InvoicesTable loadInvoices={index} />
        </div>
    )
}