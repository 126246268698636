import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export default ({ pagination }) => {
    const translateUrl = (url) => {
        if (! url) {
            return url
        }

        return url.replace(pagination.path, '')
    }

    return (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                <Link
                    to={translateUrl(pagination.prev_page_url)}
                    className={(pagination.prev_page_url ? 'text-gray-300 hover:border-gray-100 hover:text-gray-500' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700') + " inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium "}
                >
                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Previous
                </Link>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {pagination.links?.map((link, i) => {
                    if (link.label === '...') {
                        return (
                            <span key={i} className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                              {link.label}
                            </span>
                        )
                    }

                    if (link.label.indexOf('Prev') >= 0 || link.label.indexOf('Next') >= 0) {
                        return
                    }

                    return (
                        <Link
                            key={i}
                            to={translateUrl(link.url)}
                            className={classNames(
                                {
                                    "border-slate-500 text-slate-600": link.active,
                                    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300": ! link.active
                                },
                                "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            )}
                        >
                            {link.label}
                        </Link>
                    )
                })}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <Link
                    to={translateUrl(pagination.next_page_url)}
                    className={(pagination.next_page_url ? 'text-gray-300 hover:border-gray-100 hover:text-gray-500' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700') + " inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium"}
                >
                    Next
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Link>
            </div>
        </nav>
    )
}
