import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import { FaPlus } from 'react-icons/fa6'
import { create } from 'services/locations'
import Location from '../Location'

const AddLocationModal = ({ company, after }) => {
    const defaultLocation = {
        company_id: company?.id,
        editing: true
    }
    const [ visible, setVisible ] = useState(false)
    const [ data, setData ] = useState(defaultLocation)
    const notify = useNotificationsDispatch()

    const submit = () => {
        create(data, ({ data }) => {
            setVisible(false)

            notify({
                message: 'Location has been added.',
                title: 'Success!',
                type: 'success',
            })

            if (after) {
                after(data.location)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Add Location
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={submit}>
                Submit
            </Button>
        </div>
    )

    return (
        <div className="-mt-0.5">
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer} style={{ width: "40rem" }}
                    onHide={() => setVisible(false)}>
                <div className="mt-4">
                    <Location
                        location={data}
                        setLocation={setData}
                        hideButtons={true}
                    />
                </div>
            </Dialog>

            <Button size="sm" onClick={() => {
                setData(defaultLocation)
                setVisible(true)
            }}>
                <span className="flex gap-2 items-center">
                    <FaPlus className="w-4 h-4" />
                    <span>Add Location</span>
                </span>
            </Button>
        </div>
    )
}

export default AddLocationModal