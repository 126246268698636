import { useState } from 'react'
import Upload from 'views/admin/lots/import/Upload'
import Review from 'views/admin/lots/import/Review'
import { Dialog } from 'primereact/dialog'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const ImportLotsModal = ({ auction, visible, setVisible, afterImport }) => {
    const [ step, setStep ] = useState('upload')
    const [ data, setData ] = useState({})
    const notify = useNotificationsDispatch()

    const close = () => {
        setVisible(false)
        setStep('upload')
        setData({})
    }

    const header = (
        <div className="text-lg font-bold">
            Import Lots
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} style={{ width: "75%" }}
                onHide={close}>
            {step === 'upload' && (
                <Upload
                    auction={auction}
                    afterSubmit={(data) => {
                        setData(data)
                        setStep('review')
                    }}
                />
            )}
            {step === 'review' && (
                <Review
                    auction={auction}
                    data={data.data}
                    hash={data.hash}
                    afterSubmit={(data) => {
                        close()

                        notify({
                            message: 'Lots imported successfully.',
                            title: 'Success!',
                            type: 'success',
                        })

                        afterImport()
                    }}
                />
            )}
        </Dialog>
    )
}

export default ImportLotsModal