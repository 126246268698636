import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import Button from 'components/buttons/Button'
import { useEffect, useState } from 'react'
import { Premiums, Taxes } from 'services/settings'
import {states} from '../../../../services/states'
import Label from '../../../../components/fields/Label'
import {InputNumber} from 'primereact/inputnumber'

const AuctionSettings = ({ user, setUser, submit }) => {
    const [ premiums, setPremiums ] = useState([])
    const [ taxes, setTaxes ] = useState([])

    const setData = (k, v) => {
        setUser({
            ...user,
            [k]: v
        })
    }

    useEffect(() => {
        Premiums.index({}, ({ data }) => {
            setPremiums(data.premiums.map(premium => {
                return {
                    value: premium.id,
                    label: premium.name
                }
            }))
        })

        Taxes.index({}, ({ data }) => {
            setTaxes(data.taxes.map(tax => {
                return {
                    value: tax.id,
                    label: tax.name
                }
            }))
        })
    }, [])

    return (
        <Card extra={"w-full !p-5"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Auction Settings
                </h4>
            </div>

            <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
                <div className="grid grid-cols-2 col-span-2 gap-2 items-center mx-2 mb-4">
                    <label className="flex gap-2 items-center" title="If marked as tax exempt, any new invoices for this user will be set to tax exempt by default">
                        <input
                            type="checkbox"
                            checked={user.tax_exempt || false}
                            onChange={(e) => setData('tax_exempt', e.target.checked)}
                        />
                        <span>Tax Exempt</span>
                    </label>
                    <label className="flex gap-2 items-center" title="If marked as batch charge exempt, when batch payments are processed invoices for this user will be skipped">
                        <input
                            type="checkbox"
                            checked={user.batch_exempt || false}
                            onChange={(e) => setData('batch_exempt', e.target.checked)}
                        />
                        <span>Batch Charge Exempt</span>
                    </label>
                    <label className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            checked={user.cc_not_required || false}
                            onChange={(e) => setData('cc_not_required', e.target.checked)}
                        />
                        <span>Don't require credit card to bid</span>
                    </label>
                    <label className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            checked={user.deposit_not_required || false}
                            onChange={(e) => setData('deposit_not_required', e.target.checked)}
                        />
                        <span>Don't require deposit to bid</span>
                    </label>
                    <label className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            checked={user.approval_not_required || false}
                            onChange={(e) => setData('approval_not_required', e.target.checked)}
                        />
                        <span>Don't require approval to bid</span>
                    </label>
                </div>

                <InputField
                    label="Permanent Bidder Number"
                    id="permanent_bidder_number"
                    type="text"
                    value={user.permanent_bidder_number || ''}
                    onChange={(e) => setData('permanent_bidder_number', e.target.value)}
                />

                <SelectField
                    label="Premium"
                    id="premium_id"
                    value={user.premium_id || ''}
                    onChange={(e) => setData('premium_id', e.target.value)}
                    options={premiums}
                    />

                <SelectField
                    label="Sales Tax"
                    id="tax_id"
                    value={user.tax_id || ''}
                    onChange={(e) => setData('tax_id', e.target.value)}
                    options={taxes}
                    />

                <div>
                    <Label htmlFor="tax_amount">
                        Tax Amount
                    </Label>
                    <div className="p-inputgroup flex-1 mt-2">
                        <InputNumber
                            inputId="tax_amount"
                            maxFractionDigits={2}
                            value={user.tax_amount || ''}
                            onChange={(e) => setData('tax_amount', e.value)}
                        />
                        <span className="p-inputgroup-addon">%</span>
                    </div>
                </div>

                <div>
                    <Label htmlFor="max_charge">
                        Max Charge
                    </Label>
                    <div className="p-inputgroup flex-1 mt-2">
                        <span className="p-inputgroup-addon">$</span>
                        <InputNumber
                            inputId="max_charge"
                            maxFractionDigits={2}
                            value={user.max_charge || ''}
                            onChange={(e) => setData('max_charge', e.value)}
                        />
                    </div>
                </div>

                <div>
                    <Label htmlFor="bidding_threshold">
                        Bidding Threshold
                    </Label>
                    <div className="p-inputgroup flex-1 mt-2">
                        <span className="p-inputgroup-addon">$</span>
                        <InputNumber
                            inputId="bidding_threshold"
                            maxFractionDigits={2}
                            value={user.bidding_threshold || ''}
                            onChange={(e) => setData('bidding_threshold', e.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="mt-12 flex w-full justify-end">
                <Button
                    onClick={() => submit()}
                >Submit</Button>
            </div>
        </Card>
    );
};

export default AuctionSettings;
