import LotLayout from './components/LotLayout'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, bids as getBids, updateBid, revertToBid, revertAllBids } from 'services/lots'
import { useAuth, can } from 'components/auth/AuthContext'
import Button from 'components/buttons/Button'
import Badge from 'components/dataDisplay/Badge'
import Link from 'components/buttons/Link'
import ModalForm from 'components/modal/ModalForm'
import { FaPencil } from 'react-icons/fa6'
import { TbHomeStats } from 'react-icons/tb'
import { formatCurrency } from 'helpers/currency'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const LotBids = () => {
    const { id } = useParams()
    const [ lot, setLot ] = useState(null)
    const [ bids, setBids ] = useState([])
    const [ maxBid, setMaxBid ] = useState(null)
    const [ updatingBid, setUpdatingBid ] = useState(null)
    const [ updatingBidError, setUpdatingBidError ] = useState(false)
    const [ showUpdateBidModal, setShowUpdateBidModal ] = useState(false)
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()
    const notify = useNotificationsDispatch()
    const auth = useAuth()
    const canEdit = can(auth, 'update_active_auctions')

    useEffect(() => {
        setSort({
            by: searchParams.get('by') || 'bids.created_at',
            dir: searchParams.get('dir') || 1
        })
    }, [searchParams])

    useEffect(() => {
        reloadLot()
    }, [id])

    useEffect(() => {
        reloadBids()
    }, [id, sort])

    const reloadLot = () => {
        get(id, ({ data }) => {
            setLot(data.lot)
        })
    }

    const reloadBids = () => {
        reloadLot(id)

        getBids(id, {
            by: sort.by,
            dir: sort.dir
        }, ({ data }) => {
            setBids(data.bids)
            setMaxBid(data.max_bid)
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const BidTemplate = (bid) => {
        return (
            <div className="flex gap-2 items-center">
                {canEdit && (
                    <Button color="red-outline"
                            size="sm"
                            title="Change the bid amount"
                            onClick={() => {
                                setUpdatingBid({
                                    ...bid,
                                    min: min(bid),
                                    max: max(bid)
                                })
                                setShowUpdateBidModal(true)
                            }}
                    >
                        <FaPencil className="w-3 h-3" />
                    </Button>
                )}

                <div>
                    {formatCurrency(bid.bid, 2)}
                </div>

                {bid.id === maxBid?.id && (
                    <Badge color="green" size="sm">Top Bid</Badge>
                )}
            </div>
        )
    }

    const MaxBidTemplate = (bid) => {
        return (
            <div>
                {formatCurrency(bid.max_bid, 2)}
            </div>
        )
    }

    const IpTemplate = (bid) => {
        return (
            <div>
                {bid.auto ? 'AUTOBID' : bid.ip}
            </div>
        )
    }

    const min = (bid) => {
        // Of the bids lower than the bid we're editing, we want the highest value
        const sorted = bids.filter(b => parseFloat(b.bid) < parseFloat(bid.bid)).sort((a, b) => {
            if (parseFloat(a.bid) < parseFloat(b.bid)) {
                return 1;
            } else if (parseFloat(a.bid) > parseFloat(b.bid)) {
                return -1;
            }

            return 0;
        })

        return sorted[0]?.bid || 0
    }

    const max = (bid) => {
        // Of the bids higher than the bid we're editing, we want the lowest value
        const sorted = bids.filter(b => parseFloat(b.bid) > parseFloat(bid.bid)).sort((a, b) => {
            if (parseFloat(a.bid) < parseFloat(b.bid)) {
                return -1;
            } else if (parseFloat(a.bid) > parseFloat(b.bid)) {
                return 1;
            }

            return 0;
        })

        return sorted[0]?.bid || 0
    }

    const BidderFieldTemplate = (user, value) => {
        return (
            <Link to={`/admin/user/${user.id}/edit`}>
                {value}
            </Link>
        )
    }

    const UserIDTemplate = (bid) => {
        return BidderFieldTemplate(bid.bidder, bid.bidder.id)
    }

    const BidderNameTemplate = (bid) => {
        return BidderFieldTemplate(bid.bidder, bid.bidder_name)
    }

    const FirstNameTemplate = (bid) => {
        return BidderFieldTemplate(bid.bidder, bid.bidder.first_name)
    }

    const LastNameTemplate = (bid) => {
        return BidderFieldTemplate(bid.bidder, bid.bidder.last_name)
    }

    const MenuBodyTemplate = (bid) => {
        if (bid.id === maxBid?.id || ! canEdit) {
            return
        }

        return (
            <Button color="outline" size="sm" onClick={() => revert(bid)}>
                <span className="whitespace-nowrap">Revert Here</span>
            </Button>
        )
    }

    const updateBidAmount = (amount) => {
        setUpdatingBid({
            ...updatingBid,
            bid: amount
        })
    }

    const revert = (bid) => {
        if (! window.confirm('Are you sure you want to revert the winning bid?')) {
            return
        }

        revertToBid(lot.id, bid.id, () => {
            reloadBids()

            notify({
                message: 'Bids reverted.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    const revertAll = () => {
        if (! window.confirm('Are you sure you want to revert all bids?')) {
            return
        }

        revertAllBids(lot.id, () => {
            reloadBids()

            notify({
                message: 'Bids reverted.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    if (! lot) {
        return
    }

    const buttons = (
        <>
            <Button to={`/admin/auction/${lot.auction_id}/stats`}>
                <span className="flex gap-2 items-center">
                    <TbHomeStats className="w-5 h-5" />
                    <span>Lot Stats</span>
                </span>
            </Button>
        </>
    )

    return (
        <LotLayout id={id} lot={lot} setLot={setLot} buttons={buttons}>
            <div>
                <DataTable className="text-sm" value={bids} lazy dataKey="id"
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           tableStyle={{ minWidth: '75rem' }}>
                    <Column field="bidder.id" header="User ID" sortable body={UserIDTemplate} />
                    <Column field="bidder_name" header="Bidder Number" sortable sortField="bids.bidder_name" body={BidderNameTemplate} />
                    <Column field="bidder.first_name" header="First Name" sortable body={FirstNameTemplate} />
                    <Column field="bidder.last_name" header="Last Name" sortable body={LastNameTemplate} />
                    <Column field="bid" header="Amount" sortable sortField="bids.bid" body={BidTemplate} />
                    <Column field="max_bid" header="Max Bid" sortable sortField="bids.max_bid" body={MaxBidTemplate} />
                    <Column field="dates.created" header="Time Placed" sortable sortField="bids.created_at" />
                    <Column field="ip" header="IP" sortable sortField="bids.ip" body={IpTemplate} />
                    <Column headerStyle={{ width: '4rem' }} body={MenuBodyTemplate}></Column>
                </DataTable>

                {canEdit && (
                    <div className="flex justify-end pt-4 pr-6">
                        <Button color="outline" size="sm" onClick={revertAll}>
                            <span className="whitespace-nowrap">Revert All</span>
                        </Button>
                    </div>
                )}

                <ModalForm
                    title="Update bid"
                    theme="danger"
                    open={showUpdateBidModal}
                    setOpen={setShowUpdateBidModal}
                    onDismiss={() => {
                        setUpdatingBid(null)
                    }}
                    onSubmit={(e, after) => {
                        if (! updatingBid) { // We want it to highlight but not prevent submission
                            return
                        }

                        updateBid(lot.id, updatingBid.id, {
                            bid: updatingBid.bid
                        }, () => {
                            reloadBids()

                            after()

                            notify({
                                message: 'Bid updated.',
                                title: 'Success!',
                                type: 'success',
                            })
                        })
                    }}
                >
                    <p className="text-sm text-gray-500">
                        Enter a new bid amount.
                    </p>

                    {updatingBid?.max ? (
                        <p className="text-xs mt-2">
                            A bid was placed after this, so the bid amount cannot be more than <b>{formatCurrency(updatingBid.max, 2)}</b>
                        </p>
                    ) : ''}

                    {updatingBid?.min ? (
                        <p className="text-xs mt-2">
                            A bid was placed before this, so the bid amount cannot be less than <b>{formatCurrency(updatingBid.min, 2)}</b>
                        </p>
                    ) : ''}

                    <div className="mt-4">
                        <div className="p-inputgroup flex-1 mt-2">
                            <span className={"p-inputgroup-addon " + (updatingBidError ? 'border-red-500' : '')}>$</span>
                            <InputNumber
                                inputClassName={(updatingBidError ? 'border-red-500' : '')}
                                value={updatingBid?.bid}
                                maxFractionDigits={2}
                                onChange={(e) => {
                                    updateBidAmount(e.value)
                                    setUpdatingBidError(
                                        (updatingBid.max && e.value > updatingBid.max)
                                        || (updatingBid.min && e.value < updatingBid.min)
                                    )
                                }}
                            />
                        </div>
                    </div>
                </ModalForm>
            </div>
        </LotLayout>
    )
}

export default LotBids