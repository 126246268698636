import { Dialog } from 'primereact/dialog';
import Button from 'components/buttons/Button';
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext';
import InputField from 'components/fields/InputField';
import axios from 'axios';
import { useState } from 'react';

const AddVideoLinkModal = ({
  children,
  assetId,
  setVideos,
  visible,
  setVisible,
  form,
  setFormData,
  assetType = 'lot',
}) => {
  const notify = useNotificationsDispatch();
  const [formErrors, setFormErrors] = useState({});

  const close = () => {
    setVisible(false);
  };

  const addVideoLink = () => {
    let url = `${process.env.REACT_APP_API_URL}/${assetType}/${assetId}/video-links`;

    if (form && form.id) {
      url = `${url}/${form.id}`;
    }

    axios
      .post(url, form)
      .then(({ data }) => {
        setVideos(data[assetType].video_links);

        close();

        notify({
          message: 'New video link has been added',
          title: 'Success!',
          type: 'success',
        });
      })
      .catch((err) => {
        setFormErrors(err.response.data.errors);

        notify({
          message: 'Sorry you have errors in your submission!',
          title: 'Error!',
          type: 'remove',
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    setFormErrors({});
    addVideoLink();
  };

  const header = <div className="text-lg font-bold">Add New Video Link</div>;

  const footer = (
    <div>
      <Button color="outline" onClick={() => close()}>
        Cancel
      </Button>
      <Button onClick={submit}>Submit</Button>
    </div>
  );

  return (
    <div>
      <Dialog
        modal
        draggable={false}
        resizable={false}
        visible={visible}
        header={header}
        footer={footer}
        onHide={() => close()}
        style={{ width: '25rem' }}
        contentClassName="overflow-visible"
      >
        <div>
          <InputField
            extra="mb-3"
            label="Name"
            id="name"
            name="name"
            value={form.name || ''}
            onChange={(e) => setFormData('name', e.target.value)}
            type="text"
            below={formErrors.name ? formErrors.name[0] : null}
          />

          <InputField
            extra="mb-3"
            label="Url"
            id="url"
            name="url"
            value={form.url || ''}
            onChange={(e) => setFormData('url', e.target.value)}
            type="text"
            below={formErrors.url ? formErrors.url[0] : null}
          />
        </div>
      </Dialog>

      {children}
    </div>
  );
};

export default AddVideoLinkModal;
