import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/users', {
        params
    }).then(then)
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/user/' + id)
        .then(then)
}

export const allNotes = (user) => {
    let notes = user.notes

    if (user.suspended_at) {
        if (user.status === 'banned') {
            notes = [
                {
                    id: 'banned',
                    body: user.ban_message ? ('User is banned: ' + user.ban_message) : 'User is banned',
                    options: {
                        notify: true,
                        type: 'error'
                    }
                },
                ...user.notes
            ]
        } else if (user.status === 'pending') {
            notes = [
                {
                    id: 'banned',
                    body: user.pending_reason ? ('User is pending review for the following reasons: ' + user.pending_reason) : 'User is pending review',
                    options: {
                        notify: true,
                        type: 'warning'
                    }
                },
                ...user.notes
            ]
        }
    }

    return notes
}

export const create = (data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/users/create', data)
        .then(then).catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const update = (id, data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/user/' + id + '/update', data)
        .then(then).catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const deleteUser = (id, then, fail) => {
    axios.delete(process.env.REACT_APP_API_URL + '/user/' + id + '/delete')
        .then(then).catch(error => {
        if (fail) {
            fail(error.response)
        }
    })
}

export const ban = (id, data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/user/' + id + '/ban', data)
        .then(then).catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const unban = (id, data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/user/' + id + '/unban', data)
        .then(then).catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const updateAssociations = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/user/' + id + '/update-associations', data)
        .then(then)
}

export const getCreditCards = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/user/' + id + ' /credit-cards')
        .then(then)
}

export const getRoles = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/users/roles', {
        params
    }).then(then)
}

export const getPrimaryRoles = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/users/roles?primary=1', {
        params
    }).then(then)
}

export const bids = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/user/' + id + '/bids', {
        params
    }).then(then)
}

export const invoices = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/user/' + id + '/invoices', {
        params
    }).then(then)
}