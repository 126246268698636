import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAuth, can } from 'components/auth/AuthContext'
import { index } from 'services/clients'
import CenteredPagination from 'components/pagination/Centered'
import Button from 'components/buttons/Button'
import { FaPlus } from 'react-icons/fa6'

export default () => {
    const auth = useAuth()
    const canCreate = can(auth, 'create_clients')

    const [ clients, setClients ] = useState([])
    const [ searchParams] = useSearchParams()
    const [ page, setPage ] = useState(1)

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        index({ page }, ({ data }) => {
            setClients(data.clients)
            console.log('clients', data)
        })
    }, [page])

    return (
        <div className="mt-3">
            {canCreate && (
                <div className="pt-3 mb-3 flex justify-end">
                    <Button href="/admin/clients/create">
                    <span className="flex gap-2 items-center">
                        <FaPlus className="w-5 h-5" />
                        <span>New Client</span>
                    </span>
                    </Button>
                </div>
            )}

            {clients && (
                <div>
                    <table className="w-full text-left">
                        <thead>
                        <tr className="uppercase text-xs tracking-widest">
                            <th className="py-4">Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {clients.data?.map(client => {
                            return (
                                <tr key={client.id}>
                                    <td className="w-96 py-4 font-bold">
                                        <a href={`/admin/client/${client.id}`}>{client.name}</a>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {clients.last_page > 1 && (
                        <CenteredPagination
                            pagination={clients}
                        />
                    )}
                </div>
            )}
        </div>
    )
}