import { useCallback, useEffect, useState} from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { update } from 'services/folders'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import InputField from 'components/fields/InputField'

const EditFolderModal = ({ folder, visible, setVisible, then }) => {
    const input = useCallback(node => {
        if (visible === 'create') {
            node?.focus()
        }
    }, [visible])
    const notify = useNotificationsDispatch()
    const [ name, setName ] = useState('')

    useEffect(() => {
        setName(folder?.name || '')
    }, [folder])

    useEffect(() => {
        if (visible === 'edit') {
            input.current?.focus()
        }
    }, [visible])

    const onClick = () => {
        update(folder.id, {
            ...folder,
            name
        }, ({ data }) => {
            setVisible(false)

            notify({
                message: 'Folder has been updated.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data.folder)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Edit Folder Name
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button color="red" onClick={onClick}>
                Submit
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'edit'}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}
        >
            <InputField
                ref={input}
                label="Name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
        </Dialog>
    )
}

export default EditFolderModal