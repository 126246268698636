import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Label from '../fields/Label'
import Combobox from 'components/fields/Combobox'
import noteTypes from './NoteTypes'
import { useEffect, useState } from 'react'
import Button from '../buttons/Button'
import { create, deleteNote, update } from 'services/notes'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const NoteForm = ({ notableId, notableType, children, afterSubmit, ...props }) => {
    const [ note, setNote ] = useState(props.note)
    const [ editing, setEditing ] = useState(! note.id)
    const notify = useNotificationsDispatch()

    const setNoteAttr = (k, v) => {
        setNote({
            ...note,
            [k]: v
        })
    }

    const setNoteOption = (k, v) => {
        setNoteAttr('options', {
            ...note.options,
            [k]: v
        })
    }

    const onSubmit = () => {
        const callback = () => {
            afterSubmit()

            notify({
                message: 'Note saved.',
                title: 'Success!',
                type: 'success',
            })
        }

        if (note.id) {
            update(note.id, note, callback)
            setEditing(false)
        } else {
            create(note, callback)
        }
    }

    useEffect(() => {
        // Automatically save if Notify attribute changes
        if (note.options.notify !== props.note.options.notify && typeof note.options.notify !== 'undefined') {
            onSubmit()
        }
    }, [note.options.notify])

    if (! editing) {
        return (
            <>
                <div className="my-2"
                     dangerouslySetInnerHTML={{
                         __html: note.body
                     }}
                />

                <div className="flex justify-end items-center gap-2">
                    <label
                        className="flex gap-1 items-center"
                    >
                        <input
                            type="checkbox"
                            checked={note.options.notify || false}
                            onChange={(e) => {
                                setNoteOption('notify', e.target.checked)
                            }}
                        />
                        <span>Notify</span>
                    </label>

                    <Button
                        size="md"
                        onClick={() => {
                            setEditing(true)
                        }}
                    >
                        Edit
                    </Button>

                    <Button
                        color="red"
                        size="md"
                        onClick={() => {
                            if (! window.confirm("Are you sure you want to delete?")) {
                                return;
                            }

                            deleteNote(note.id, {}, () => {
                                afterSubmit()
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </>
        )
    }

    return (
        <>
            <Label>Note Type</Label>

            <Combobox
                options={noteTypes}
                defaultValue={note.options.type}
                onChange={(option) => {
                    setNoteOption('type', option.value)
                }}
            />

            <div style={{
                height: '300px',
                marginBottom: '60px'
            }}>
                <ReactQuill theme="snow"
                            className="mt-2 h-full"
                            value={note.body || ''}
                            onChange={(v) => {
                                setNoteAttr('body', v)
                            }}
                />
            </div>

            <div className="flex justify-end">
                {note.id ? (
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            size="md"
                            onClick={onSubmit}
                        >
                            Save
                        </Button>

                        <Button
                            color="outline"
                            size="md"
                            onClick={() => {
                                setEditing(false)
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <Button
                        onClick={onSubmit}
                    >Add Note</Button>
                )}
            </div>
        </>
    )
}

export default NoteForm