import { CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const TableRow = ({ row, handleChange, toggleAll, selectedRows }) => {
    const change = function (e, row) {
        if (e.target.value === '') {
            // This is the header row
            toggleAll(e.target.checked)
        } else {
            handleChange(row, e.target.checked)
        }
    }

    const disabled = row.attrs.block

    let value = ''
    let bg = ''

    if (! row.headers) {
        // TODO: What if there isn't a VIN?
        value = row.mapped.vin

        if (row.attrs.block) {
            bg = 'bg-gray-200'
        } else if (row.attrs.duplicate) {
            bg = 'bg-yellow-100'
        } else if (! row.attrs.accept) {
            bg = 'bg-red-100'
        } else {
            bg = 'bg-green-100'
        }
    }

    const cellClasses = function (cell) {
        let classes = ''

        // already_imported ?
        if (cell.duplicate) {
            classes = 'text-yellow-600 font-bold'
        } else if (cell.matches) {
            classes = 'text-green-600 font-bold'
        } else if (cell.nhtsa) {
            classes = 'text-red-600 font-bold'
        }

        return classes;
    }

    const cellTooltip = function (cell) {
        let tooltip = ''

        if (cell.duplicate) {
            if (cell.key == 'vin') {
                tooltip = 'This VIN already exists, so the vehicle record will be moved to this auction.'
            } else if (cell.key == 'lot') {
                tooltip = 'This lot number already exists, so this lot will be skipped.'
            }
        } else if (cell.nhtsa) {
            if (cell.ok) {
                tooltip = 'This value matches what we found in the NHTSA database.'
            } else {
                tooltip = 'This value does not match what we found in the NHTSA database.'
            }
        }

        return tooltip
    }

    return (
        <tr className={bg}>
            <td>
                <input type="checkbox" value={value} checked={selectedRows.includes(row)} disabled={disabled} onChange={(e) => {
                    change(e, row)
                }} className={"form-checkbox h-5 w-5 text-blue-600 " + (disabled ? 'cursor-not-allowed' : '') } />
            </td>

            {row.cells.map((cell, i) => (
                <td key={i} className="px-3 py-3.5 whitespace-nowrap">
                    <div className={"flex gap-1 items-center " + cellClasses(cell)}>
                        {cell.value}

                        {cell.matches ? (
                            <CheckBadgeIcon className="h-4 w-4" title={cellTooltip(cell)} />
                        ) : (
                            ! cell.ok && ! row.headers && (
                                <ExclamationTriangleIcon className="h-4 w-4" title={cellTooltip(cell)} />
                            )
                        )}
                    </div>

                    {
                        cell.nhtsa && ! cell.matches && (
                            <p className="text-xs text-red-600 font-bold">
                                <span>Expected: </span>
                                <span>{cell.nhtsa}</span>
                            </p>
                        )
                    }

                    {
                        cell.exists && (
                            <p className="text-xs text-yellow-600 font-bold">
                                <span>{cell.already_imported ? 'Already Imported' : 'On Another Auction - Will Be Moved'}</span>
                            </p>
                        )
                    }
                </td>
            ))}
        </tr>
    )
}

export default TableRow
