import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import Link from 'components/buttons/Link'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import InputField from 'components/fields/InputField'
import Label from 'components/fields/Label'
import SelectField from 'components/fields/SelectField'
import { formatDatetime, formatTime } from 'helpers/dates'

const AdvancedEndTimeExtensionModal = ({ auction, setConfig }) => {
    const [ visible, setVisible ] = useState(false)
    const notify = useNotificationsDispatch()

    const submit = () => {
        setVisible(false)
        notify({
            message: 'Threshold settings updated. Be sure to save the auction.',
            title: 'Success!',
            type: 'success',
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Custom End Time Extension
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={submit}>
                Submit
            </Button>
        </div>
    )

    const setThresholdValue = (i, k, v) => {
        auction.config.end_time_ext[i][k] = v

        setConfig('end_time_ext', [
            ...auction.config.end_time_ext
        ])
    }

    const addThreshold = () => {
        setConfig('end_time_ext', [
            ...auction.config?.end_time_ext || [],
            {
                ends_at: getNextEndsAt(auction.config?.end_time_ext?.length || 0),
                threshold: '',
                amount: '',
                method: ''
            }
        ])
    }

    const getNextEndsAt = (i) => {
        const basis = formatDatetime(auction.ends_at || '')
        const date = new Date(basis)

        date.setMinutes(date.getMinutes() + i);

        return auction.is_template ? formatTime(date.toString()) : formatDatetime(date.toString())
    }

    const removeThreshold = (threshold) => {
        setConfig('end_time_ext', auction.config.end_time_ext.filter(t => t !== threshold))
    }

    return (
        <div className="-mt-0.5">
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer}
                    onHide={() => setVisible(false)}>

                <div className="grid grid-cols-5 gap-2 mt-2 items-center">
                    <div>
                        <Label>
                            End Time
                        </Label>
                    </div>
                    <div>
                        <Label>
                            End Time Threshold (seconds)
                        </Label>
                    </div>
                    <div>
                        <Label>
                            End Time Amount (seconds)
                        </Label>
                    </div>
                    <div>
                        <Label>
                            End Time Method
                        </Label>
                    </div>
                    <div></div>

                    {auction.config?.end_time_ext?.map((threshold, i) => {
                        return (
                            <div className="col-span-5 grid grid-cols-5 gap-2 mt-2 items-center" key={i}>
                                <div>
                                    <InputField
                                        extra="mb-3"
                                        value={threshold.ends_at || ''}
                                        onChange={(e) => setThresholdValue(i, 'ends_at', e.target.value)}
                                        placeholder=""
                                        type={auction.is_template ? "time" : "datetime-local"}
                                    />
                                </div>

                                <div>
                                    <InputField
                                        extra="mb-3"
                                        value={threshold.threshold || ''}
                                        onChange={(e) => setThresholdValue(i, 'threshold', e.target.value)}
                                        type="number"
                                    />
                                </div>

                                <div>
                                    <InputField
                                        extra="mb-3"
                                        value={threshold.amount || ''}
                                        onChange={(e) => setThresholdValue(i, 'amount', e.target.value)}
                                        type="number"
                                    />
                                </div>

                                <div>
                                    <SelectField
                                        value={threshold.method || 'add'}
                                        onChange={(e) => setThresholdValue(i, 'method', e.target.value)}
                                        noDefault={true}
                                        options={[
                                            {
                                                value: 'add',
                                                label: 'Add'
                                            },
                                            {
                                                value: 'reset',
                                                label: 'Reset'
                                            }
                                        ]}
                                    />
                                </div>

                                <div className="pt-2 flex gap-4">
                                    <div className={i === (auction.config?.end_time_ext?.length - 1) ? '' : 'invisible'}>
                                        <Button onClick={() => addThreshold()}>
                                            + Add
                                        </Button>
                                    </div>

                                    <Button color="red" onClick={() => removeThreshold(threshold)}>
                                        &mdash; Remove
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Dialog>

            <Link className="text-xs" onClick={() => {
                if (! auction.config?.end_time_ext) {
                    addThreshold()
                }
                setVisible(true)
            }}>
                Advanced
            </Link>
        </div>
    )
}

export default AdvancedEndTimeExtensionModal