import UserLayout from './components/UserLayout'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, invoices } from 'services/users'
import InvoicesTable from '../invoices/components/InvoicesTable'

const Invoices = () => {
    const { id } = useParams()
    const [ user, setUser ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            setUser(data.user)
        })
    }, [])

    if (! user) {
        return
    }

    return (
        <UserLayout id={id} user={user}>
            <InvoicesTable loadInvoices={(params, then) => invoices(id, params, then)} />
        </UserLayout>
    )
}

export default Invoices