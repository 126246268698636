import ReportsLayout from '../components/ReportsLayout'

const CensusReport = () => {
    return (
        <ReportsLayout
            report="census"
            defaultFields={['company_name', 'consignor_code', 'state', 'email', 'phone']}
            expandedKeys={['companies']}
        />
    )
}

export default CensusReport