import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PortalLayout from './components/PortalLayout'
import { logos as loadLogos } from 'services/portals'
import LogoUploader from './components/LogoUploader'

const PortalLogo = () => {
    const { id } = useParams()
    const [ portal, setPortal ] = useState({})

    useEffect(() => {
        if (id) {
            loadLogos(id, {}, ({ data }) => {
                setPortal(data.portal)
            })
        }
    }, [])

    if (! portal.id) {
        return
    }

    return (
        <PortalLayout id={id} portal={portal}>
            <LogoUploader
                portal={portal}
            />
        </PortalLayout>
    )
}

export default PortalLogo