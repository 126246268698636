import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import UserLayout from './components/UserLayout'
import { getCreditCards } from 'services/users'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Card from 'components/card'
import CreditCard from 'components/payments/CreditCard'
import StripeSetupForm from 'components/payments/StripeSetupForm'
import Button from 'components/buttons/Button'

const UserCreditCards = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ user, setUser ] = useState(null)
    const [ cards, setCards ] = useState([])
    const [ config, setConfig ] = useState({})
    const [ showForm, setShowForm ] = useState(false)

    const [ stripePromise, setStripePromise ] = useState(null)

    useEffect(() => {
        loadCreditCards()
    }, [id])

    const loadCreditCards = () => {
        if (! id) {
            return
        }

        getCreditCards(id, ({ data }) => {
            setUser(data.user)
            setCards(data.cards)
            setConfig(data.config)

            setStripePromise(
                loadStripe(data.config.stripe_key)
            )
        })
    }

    const removeCardFromState = (paymentMethodId) => {
        setCards(currentCards => currentCards.filter(card => card.id !== paymentMethodId));
    };

    const stripeOptions = {
        mode: 'setup',
        currency: 'usd'
    }

    const onCancel = () => {
        setShowForm(false)
    }

    const onSubmit = (result) => {
        setShowForm(false)
        window.scroll(0, 0)

        setTimeout(() => {
            // Wait a sec. Adding a card is not instant
            navigate(0)
        }, 300)
    }

    if (! user) {
        return
    }

    return (
        <UserLayout id={id} user={user}>
            <Elements stripe={stripePromise} options={stripeOptions}>
                <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                    <Card extra={"w-full !p-5"}>
                        {/* Header */}
                        <div>
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Credit Cards
                            </h4>
                            <p className="mt-1 text-base text-gray-600">
                                Store payment information to be used for bidding on auctions.
                            </p>
                            <p className="mt-1 text-xs text-gray-600">
                                Please note: <b>To make changes to an existing card, you will need to DELETE the card and then ADD it again.</b>
                            </p>
                        </div>

                        <div className="pt-5">
                            {cards.length > 0 ? (
                                <div>
                                    {cards.map((card, i) => {
                                        return (
                                            <div key={i} className="my-2">
                                                <CreditCard card={card} onRemoveCard={removeCardFromState} />
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div className="my-2 italic">
                                    No cards on file
                                </div>
                            )}

                            {showForm ? (
                                <div>
                                    <h2 className="text-lg font-medium leading-6 text-gray-900 mt-6 mb-4">Add a Card</h2>

                                    <StripeSetupForm
                                        config={{
                                            return_url: window.location.href,
                                            user_id: id
                                        }}
                                        onCancel={onCancel}
                                        onSubmit={onSubmit}
                                    />
                                </div>
                            ) : (
                                <div className="mt-6">
                                    <Button onClick={() => setShowForm(true)}>Add Card</Button>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </Elements>
        </UserLayout>
    )
}

export default UserCreditCards