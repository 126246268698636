import { useEffect, useState } from 'react'
import { opsReport } from 'services/auctions'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const OpsReport = () => {
    const [ data, setData ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ sort, setSort ] = useState({
        by: 'ends_at',
        dir: 1
    })
    const navigate = useNavigate()

    useEffect(() => {
        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })
    }, [searchParams])

    useEffect(() => {
        opsReport(sort, ({ data }) => {
            setData(data.data)
        })
    }, [sort])

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const auctionLinkTemplate = (data) => {
        if (! data.auction_link) {
            return ''
        }

        return (
            <Link to={data.auction_link} target="_blank">Link</Link>
        )
    }

    return (
        <div className="mt-6">
            {data.length > 0 && (
                <DataTable className="text-sm" value={data} lazy dataKey="id"
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           rowClassName="cursor-pointer"
                           onRowClick={(e) => {
                               navigate(`/admin/auction/${e.data.id}`)
                           }}
                           tableStyle={{ minWidth: '75rem' }}>
                    <Column field="state" header="State" sortable />
                    <Column field="company" header="Company" sortable bodyClassName="font-bold" />
                    <Column field="city" header="City" sortable />
                    <Column field="zip_code" header="ZIP Code" sortable />
                    <Column field="registered" header="Registered" sortable />
                    <Column field="close_date" sortField="ends_at" header="Close Date" sortable />
                    <Column field="close_time" header="Close Time" sortable />
                    <Column field="auction_link" header="Auction Link" body={auctionLinkTemplate} />
                </DataTable>
            )}
        </div>
    )
}

export default OpsReport