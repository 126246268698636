import axios from 'axios'
import Button from 'components/buttons/Button'
import CreditCardDisplay from './CreditCardDisplay'

export default ({ card, onRemoveCard }) => {
    const deleteCard = async (paymentMethodId) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/payment/delete-card', {
                paymentMethodId: paymentMethodId,
            });

            onRemoveCard(card.id);

            console.log(response);
        } catch (error) {
            console.error('Error deleting card', error);
            // Handle errors here (e.g., network errors)
        }
    }

    return (
        <div className="py-2 mb-2 border-b border-gray-100 flex items-center justify-between max-w-lg">
            <CreditCardDisplay card={card} />

            <div className="flex gap-2 items-center">
                <Button size="sm" color="red" onClick={() => deleteCard(card.id)}>
                    Delete
                </Button>
            </div>
        </div>
    )
}
