const noteTypes = [
    {
        name: 'Primary',
        value: 'primary',
        classes: 'bg-slate-50 text-slate-700 border border-slate-400'
    },
    {
        name: 'Success',
        value: 'success',
        classes: 'bg-green-50 text-green-700 border border-green-400'
    },
    {
        name: 'Error',
        value: 'error',
        classes: 'bg-red-50 text-red-700 border border-red-400'
    },
    {
        name: 'Warning',
        value: 'warning',
        classes: 'bg-yellow-50 text-yellow-700 border border-yellow-400'
    },
    {
        name: 'Info',
        value: 'info',
        classes: 'bg-blue-50 text-blue-700 border border-blue-400'
    },
    {
        name: 'Secondary',
        value: 'secondary',
        classes: 'bg-gray-50 text-gray-700 border border-gray-700'
    },
    {
        name: 'Light',
        value: 'light',
        classes: 'text-gray-700'
    },
    {
        name: 'Dark',
        value: 'dark',
        classes: 'bg-gray-200 text-black border border-gray-800'
    },
]

export default noteTypes