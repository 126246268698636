import axios from 'axios'
import { useState } from 'react'
import { useStripe, useElements, AddressElement, PaymentElement } from '@stripe/react-stripe-js'
import Button from 'components/buttons/Button'

export default ({ config, onCancel, onSubmit, formRef }) => {
    const stripe = useStripe()
    const elements = useElements()

    const [ errorMessage, setErrorMessage ] = useState()

    const [ loading, setLoading ] = useState(false)

    const handleError = (error) => {
        setLoading(false);
        setErrorMessage(error.message);
    }

    const submit = async (e) => {
        e.preventDefault()

        if (! stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setLoading(true)

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit()

        if (submitError) {
            handleError(submitError)
            setLoading(false); // Make sure to reset loading state if there's an error
            return
        }

        // Create the SetupIntent and obtain clientSecret
        const res = await axios.post(process.env.REACT_APP_API_URL + "/payment/create-intent/" + config.user_id);

        const {client_secret: clientSecret} = await res.data;

        // Confirm the SetupIntent using the details collected by the Payment Element
        const result = await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
                return_url: config.return_url,
            },
            redirect: 'if_required'
        }).then((result) => {
            if (result.error) {
                // This point is only reached if there's an immediate error when
                // confirming the setup. Show the error to your customer (for example, payment details incomplete)
                handleError(result.error);
            } else {
                // Your customer is redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer is redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                if ( result.setupIntent.status === 'succeeded') {
                    const updateResult= axios.post(process.env.REACT_APP_API_URL + "/payment/update-payment-method", {
                        paymentMethodId: result.setupIntent.payment_method,
                        cardNickname: document.getElementById('cardNickname').value,
                    });
                    console.log(updateResult)
                }

                console.log(result)
                onSubmit(result)
            }
        });
    }

    return (
        <form ref={formRef} onSubmit={submit}>
            <div className="flex flex-col gap-4">
                <div>
                    <label htmlFor="cardNickname" className="block text-sm font-medium leading-6 text-gray-900">
                        Card Nickname
                    </label>
                    <div className="mt-2">
                        <input type="text" name="cardNickname" id="cardNickname"
                               className="block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6" placeholder="e.g., My Personal Card"/>
                    </div>
                </div>

                <AddressElement options={{mode: 'billing'}} />

                <PaymentElement />
            </div>
            {config.mode === 'payment' ? (
                <>
                    {! config.hide_cancel && (
                        <div className="mt-4 flex gap-4">
                            <Button onClick={onCancel} color="white">
                                Cancel
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <div className="mt-4 flex gap-4 justify-end">
                    <Button onClick={onCancel} color="white">
                        Cancel
                    </Button>
                    <Button type="submit" disabled={!stripe || loading}>
                        Submit
                    </Button>
                </div>
            )}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
}
