import { useNotifications } from './NotificationsContext'
import Notification from './Notification'

export default () => {
    const notifications = useNotifications()

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="pointer-events-none w-96 fixed right-0 bottom-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-10"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {notifications.map((notification, i) => (
                        <Notification key={i} notification={notification} />
                    ))}
                </div>
            </div>
        </>
    )
}
