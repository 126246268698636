import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import SortableFile from './SortableFile'
import UploadLimits from 'components/uploads/UploadLimits'

const FileUploader = ({ folder }) => {
    const [ files, setFiles ] = useState([])

    useEffect(() => {
        setFiles(folder.files)
    }, [folder])

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (e) => {
        const { active, over } = e

        if (active.id !== over.id) {
            setFiles((files) => {
                const oldIndex = files.findIndex(asset => asset.id === active.id)
                const newIndex = files.findIndex(asset => asset.id === over.id)

                const resorted = arrayMove(files, oldIndex, newIndex)

                axios.post(process.env.REACT_APP_API_URL + `/folder/${folder.id}/files/sort`, {
                    'order': resorted.map(asset => asset.id)
                })

                return resorted
            })
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData()

        acceptedFiles.forEach((file) => {
            formData.append("files[]", file)
        })

        axios.post(process.env.REACT_APP_API_URL + `/folder/${folder.id}/files/upload`, formData)
            .then((response) => {
                setFiles(response.data.folder.files)
            })
    }, [])

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true
    })

    const onDelete = (asset) => {
        axios.delete(process.env.REACT_APP_API_URL + `/folder/${folder.id}/files/${asset.id}/delete`)
            .then((response) => {
                setFiles(response.data.folder.files)
            })
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext items={files}>
                <div {...getRootProps()} className="bg-gray-200 rounded-lg px-4 pb-4 mt-2">
                    <div onClick={open}>
                        <input {...getInputProps()} />

                        <p className="text-center py-4">
                            { isDragActive ? (
                                <>
                                    Drop the files here...
                                </>
                            ) : (
                                <>
                                    Drag &amp; drop your files or <span className="underline cursor-pointer">Browse</span>
                                </>
                            )}
                        </p>

                        <UploadLimits />
                    </div>

                    <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                        {files.map((asset) => (
                            <SortableFile key={asset.id} asset={asset} onDelete={onDelete} />
                        ))}
                    </div>
                </div>
            </SortableContext>
        </DndContext>
    )
}

export default FileUploader