import AuctionLayout from './components/AuctionLayout'
import AuctionTransferModal from '../lots/components/modals/AuctionTransferModal'
import ReturnToInventoryModal from '../lots/components/modals/ReturnToInventoryModal'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { get, lots as getLots, stats } from 'services/auctions'
import { update } from 'services/lots'
import Btn from 'components/buttons/Button'
import SplitButton from 'components/buttons/SplitButton'
import Link from 'components/buttons/Link'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatCurrency } from 'helpers/currency'
import { formatDate } from 'helpers/dates'
import {
    FaBoxesStacked,
    FaEllipsisVertical,
    FaPencil,
    FaPlus,
    FaRegStar,
    FaStar
} from 'react-icons/fa6'
import Countdown from 'components/auction/Countdown'
import { MdGavel } from 'react-icons/md'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import ImportLotsModal from './components/modals/ImportLotsModal'
import SetLotsModal from './components/modals/SetLotsModal'
import MassUpdateLotsModal from './components/modals/MassUpdateLotsModal'
import { can, useAuth } from 'components/auth/AuthContext'

const Lots = ({ mode = 'index' }) => {
    const statsMode = (mode === 'stats')
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ lots, setLots ] = useState([])
    const [ checkedLots, setCheckedLots ] = useState([])
    const [ allChecked, setAllChecked ] = useState(false)
    const [ showDeleted, setShowDeleted ] = useState(false)
    const [ showReturnToInventoryModal, setShowReturnToInventoryModal ] = useState(false)
    const [ showAuctionTransferModal, setShowAuctionTransferModal ] = useState(false)
    const [ showImportLotsModal, setShowImportLotsModal ] = useState(false)
    const [ showSetLotsModal, setShowSetLotsModal ] = useState(false)
    const [ showMassUpdateLotsModal, setShowMassUpdateLotsModal ] = useState(false)
    const [ showStaggerLotsModal, setShowStaggerLotsModal ] = useState(false)
    const [ anyApprovalRequired, setAnyApprovalRequired ] = useState(false)
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()

    const auth = useAuth()
    const canStagger = can(auth, 'stagger_lots')
    const canImport = can(auth, 'import_lots')
    const canMassUpdate = can(auth, 'mass_update_lots')
    const canExport = can(auth, 'export_lots')

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
        })
    }, [])

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        const default_by = statsMode ? 'max_bid' : (auction?.config?.sort_by_sale_order ? 'sale_order' : 'number')
        const default_dir = statsMode ? 1 : -1

        setSort({
            by: searchParams.get('by') || default_by,
            dir: searchParams.get('dir') || default_dir
        })

        window.scrollTo(0, 0)
    }, [searchParams, auction])

    const loadLots = statsMode ? stats : getLots

    const updateLots = () => {
        if (! auction) {
            // We need to wait for the auction so we can load the config
            return
        }

        loadLots(id, {
            page,
            by: sort.by,
            dir: sort.dir,
            company_id: searchParams.get('company_id'),
            show_deleted: showDeleted ? 1 : 0
        }, ({ data }) => {
            setLots(data.lots)
        })
    }

    const resetLots = () => {
        updateLots()

        setCheckedLots([])
    }

    useEffect(() => {
        updateLots()
    }, [page, sort, showDeleted])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onRowClick = (e) => {
        navigate(
            `/admin/lot/${e.data.id}/edit`
        )
    }

    const loading = false

    const imageBodyTemplate = (lot) => {
        return lot.image?.src ? (
            <img src={lot.image?.src} alt={lot.image?.alt} className="min-w-[8rem] max-h-[8rem] object-cover" />
        ) : '';
    }

    const winningBidTemplate = (lot) => {
        const bid = lot.data.winning_bid

        if (! bid) {
            return ''
        }

        return (
            <div>
                <div>
                    <span>
                        {formatCurrency(bid.bid, 2)}
                    </span>

                    {lot.reserve > 0 && (
                        <span className={"ml-2 text-sm " + (lot.reserve_status === 'met' ? 'text-green-600' : 'text-red-600')}
                              title={lot.reserve_status === 'met' ? 'Reserve Met' : 'Reserve Not Met'}>
                            ({formatCurrency(lot.reserve, 2)})
                        </span>
                    )}
                </div>
                {bid.bidder && (
                    <Link to={`/admin/user/${bid.bidder.id}/edit`} target="_blank" className="font-bold">
                        {bid.bidder.first_name} {bid.bidder.last_name}<br />
                        <small>({bid.bidder.email})</small>
                    </Link>
                )}
            </div>
        )
    }

    const maxBidTemplate = (lot) => {
        return formatCurrency(lot.max_bid, 2)
    }

    const timePlacedTemplate = (lot) => {
        const bid = lot.data.winning_bid

        if (! bid) {
            return ''
        }

        return formatDate(bid.created_at)
    }

    const timeLeftTemplate = (lot) => {
        return <Countdown
            date={lot.ends_at || lot.auction.ends_at}
        />
    }

    const lotFieldTemplate = (lot, value) => {
        return (
            <Link to={`/admin/lot/${lot.id}`}>
                {value}
            </Link>
        )
    }

    const lotNumberTemplate = (lot) => {
        return lotFieldTemplate(lot, lot.number)
    }

    const lotMakeTemplate = (lot) => {
        return lotFieldTemplate(lot, lot.item.make)
    }

    const lotModelTemplate = (lot) => {
        return lotFieldTemplate(lot, lot.item.model)
    }

    const lotYearTemplate = (lot) => {
        return lotFieldTemplate(lot, lot.item.year)
    }

    const bidCountTemplate = (lot) => {
        return (
            <Link to={`/admin/lot/${lot.id}/bids`}>
                {lot.counts.bids}
            </Link>
        )
    }

    const watchCountTemplate = (lot) => {
        return (
            <Link to={`/admin/lot/${lot.id}/watches`}>
                {lot.counts.watches}
            </Link>
        )
    }

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null)
        const items = [
            {
                label: 'Edit',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/lot/${rowData.id}/edit`)
                }
            },
        ]

        return (
            <>
                <Menu model={items} popup ref={menu} id={"popup_menu_" + rowData.id} popupAlignment="left" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} onClick={(event) => menu.current.toggle(event)} aria-controls={"popup_menu_" + rowData.id} aria-haspopup />
            </>
        )
    }

    const CheckboxTemplate = (lot) => {
        return (
            <input
                type="checkbox"
                checked={checked(lot)}
                onChange={e => checkLot(lot, e.target.checked)}
            />
        )
    }

    const AllCheckboxTemplate = () => {
        return (
            <input
                type="checkbox"
                checked={allChecked}
                onChange={e => checkAll(e.target.checked)}
            />
        )
    }

    const checked = (lot) => {
        return checkedLots.indexOf(lot.id) >= 0
    }

    const checkLot = (lot, toggled) => {
        let lots = [...checkedLots]

        if (toggled) {
            if (! checked(lot)) {
                lots.push(lot.id)
            }
        } else {
            lots = lots.filter(l => l !== lot.id)
        }

        setCheckedLots(lots)
    }

    const checkAll = (toggled) => {
        if (toggled) {
            setCheckedLots(
                lots.data.map(lot => lot.id)
            )
        } else {
            setCheckedLots([])
        }
    }

    const allLotsChecked = () => {
        let all = true

        if (! lots.data) {
            return false
        }

        lots.data?.forEach((item) => {
            if (! checked(item)) {
                all = false
            }
        })

        return all
    }

    useEffect(() => {
        setAllChecked(
            allLotsChecked()
        )
    }, [checkedLots])

    const FeatureTemplate = (rowData) => {
        return (
            <div className="cursor-pointer px-2" onClick={(e) => updateFeatured(e, rowData.id, ! rowData.is_featured)}>
                {rowData.is_featured ? (
                    <FaStar
                        className="w-5 h-5"
                        title="Currently featured. Click to toggle off."
                    />
                ) : (
                    <FaRegStar
                        className="w-5 h-5"
                        title="Not currently featured. Click to toggle on."
                    />
                )}
            </div>
        )
    }

    const updateFeatured = (e, id, is_featured) => {
        e.preventDefault()

        update(id, {
            is_featured
        }, ({ data }) => {
            setLots({
                ...lots,
                data: lots.data.map(lot => {
                    if (lot.id === id) {
                        return {
                            ...lot,
                            is_featured
                        }
                    }

                    return lot
                })
            })
        })
    }

    const field = (lot, name) => {
        let display = lot[name]

        if (lot.pending_approvals.length > 0) {
            lot.pending_approvals.forEach((approval) => {
                display = displayChanges(lot[name], approval.data[name])
            })
        }

        return display
    }

    const itemField = (lot, name) => {
        let display = lot.item[name]

        if (lot.pending_approvals.length > 0) {
            lot.pending_approvals.forEach((approval) => {
                display = displayChanges(lot.item[name], approval.data.item[name])
            })
        }

        return display
    }

    const configField = (lot, name) => {
        let display = lot.config ? lot.config[name] : ''

        if (lot.pending_approvals.length > 0) {
            lot.pending_approvals.forEach((approval) => {
                display = displayChanges(lot.config[name], approval.data.config[name])
            })
        }

        return display
    }

    const displayChanges = (before, after) => {
        if (before != after) {
            return (
                <span>
                    <s>{before}</s>
                    <br />
                    <span className="text-2xs">NEW: <span>{after}</span></span>
                </span>
            )
        }

        return before
    }

    let table

    if (lots) {
        if (statsMode) {
            table = (
                <DataTable className="text-sm cursor-pointer" value={lots.data} lazy dataKey="id" paginator
                           resizableColumns columnResizeMode="expand"
                           first={lots.from - 1} rows={lots.per_page} totalRecords={lots.total} onPage={onPage}
                           onRowClick={onRowClick}
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           loading={loading} tableStyle={{ minWidth: '75rem' }}>
                    <Column header="Image" body={imageBodyTemplate} bodyClassName="w-32" />
                    <Column body={lotNumberTemplate} field="number" header="Lot" sortable />
                    <Column body={lotMakeTemplate} field="item.make" header="Make" sortable />
                    <Column body={lotModelTemplate} field="item.model" header="Model" sortable />
                    <Column body={lotYearTemplate} field="item.year" header="Year" sortable />
                    <Column body={timeLeftTemplate} sortField="ends_at" header="Time Left" sortable />
                    <Column body={winningBidTemplate} sortField="max_bid" header="Top Bid" sortable />
                    <Column body={timePlacedTemplate} header="Time Placed" />
                    <Column body={maxBidTemplate} field="max_bid" header="Max Bid" sortable />
                    <Column body={bidCountTemplate} field="counts.bids" header="Bids" sortable sortField="count_bids" />
                    <Column body={watchCountTemplate} field="counts.watches" header="Watches" sortable sortField="count_watches" />
                    <Column field="counts.views" header="Views" />
                </DataTable>
            )
        } else {
            table = (
                <DataTable className="text-sm cursor-pointer" value={lots.data} lazy dataKey="id" paginator
                           resizableColumns columnResizeMode="expand"
                           first={lots.from - 1} rows={lots.per_page} totalRecords={lots.total} onPage={onPage}
                           onRowClick={onRowClick}
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           rowClassName={(data, options) => {
                               const approvalRequired = data.pending_approvals.length > 0 || (data.approval_required && ! data.approved_at && ! data.rejected_at)

                               if (approvalRequired) {
                                   setAnyApprovalRequired(true)
                               }

                               return {
                                   'bg-yellow-50 text-yellow-700': approvalRequired,
                                   'bg-red-50 text-red-700': data.deleted_at
                               }
                           }}
                           loading={loading} tableStyle={{ minWidth: '75rem' }}>
                    <Column body={MenuBodyTemplate} />
                    {auction?.editable && (
                        <Column body={FeatureTemplate} />
                    )}
                    {auction?.editable && (
                        <Column body={CheckboxTemplate} header={AllCheckboxTemplate} />
                    )}
                    <Column header="Image" body={imageBodyTemplate} bodyClassName="w-32" />
                    <Column field="number" header="Lot #" sortable body={(lot) => field(lot, 'number')} />

                    {auction?.config?.sort_by_sale_order && (
                        <Column field="config.sale_order" sortField="sale_order" header="Sale Order" sortable body={(lot) => configField(lot, 'sale_order')} />
                    )}

                    <Column field="auction.company.consignor_code" sortField="company.consignor_code" sortable header="C. Code" headerClassName="whitespace-nowrap" />
                    <Column field="item.vin" header="VIN" sortable bodyClassName="font-bold" body={(lot) => itemField(lot, 'vin')} />
                    <Column field="item.type" header="Type" sortable body={(lot) => itemField(lot, 'type')} />
                    <Column field="item.make" header="Make" sortable body={(lot) => itemField(lot, 'make')} />
                    <Column field="item.model" header="Model" sortable body={(lot) => itemField(lot, 'model')} />
                    <Column field="item.year" header="Year" sortable body={(lot) => itemField(lot, 'year')} />
                    <Column field="item.mileage" header="Mileage" sortable body={(lot) => itemField(lot, 'mileage')} />
                    <Column field="item.key" header="Key" sortable body={(lot) => itemField(lot, 'key')} />
                    <Column field="item.drives" header="Drives" sortable body={(lot) => itemField(lot, 'drives')} />
                    <Column field="item.runs" header="Runs" sortable body={(lot) => itemField(lot, 'runs')} />
                    <Column field="reserve" header="Reserve" sortable body={(lot) => field(lot, 'reserve')} />
                    {/*<Column field="description" sortField="lots.description" sortable header="Description" bodyClassName="w-96" body={(auction) => (*/}
                    {/*    <p className="line-clamp-1" title={auction.description}>*/}
                    {/*        {auction.description}*/}
                    {/*    </p>*/}
                    {/*)} />*/}
                    {/*<Column field="dates.starts" sortField="lots.starts_at" sortable header="Start" />*/}
                    {/*<Column field="dates.ends" sortField="lots.ends_at" sortable header="End" />*/}
                    <Column field="status" header="Status" />
                </DataTable>
            )
        }
    }

    const buttons = (auction?.editable && (canImport || canExport || canMassUpdate || canStagger)) ? (
        <div className="ml-2 w-full flex justify-start gap-4 items-center">
            <SplitButton
                items={[
                    canImport && { name: 'Import Lots', onClick: () => {
                        setShowImportLotsModal(true)
                    } },
                    canExport && { name: 'Export Lots', onClick: () => {
                        const params = new URLSearchParams({
                            by: sort.by || 'id',
                            dir: sort.dir,
                        })
                        const href = `${process.env.REACT_APP_BASE_URL}/admin/auction/${auction.id}/lots/export?` + params.toString()
                        window.open(href, '_blank')
                    } },
                    canImport && { name: 'Set Lots', onClick: () => {
                        setShowSetLotsModal(true)
                    } },
                    canMassUpdate && { name: 'Mass Update', onClick: () => {
                        setShowMassUpdateLotsModal(true)
                    } },
                    canStagger && { name: 'Stagger Lots', onClick: () => {
                        setShowStaggerLotsModal(true)
                    } },
                ]}
                url={`/admin/auction/${id}/lots/create`}
            >
                <span className="flex gap-2 items-center">
                    <FaPlus className="w-5 h-5" />
                    <span>New Lot</span>
                </span>
            </SplitButton>

            <div>
                <label className="flex gap-2 items-center">
                    <input
                        type="checkbox"
                        value={showDeleted}
                        onChange={e => setShowDeleted(e.target.checked)}
                    />
                    <span>Show Deleted</span>
                </label>
            </div>

            {checkedLots.length > 0 && (
                <>
                    <Btn
                        size="md"
                        onClick={() => {
                            setShowReturnToInventoryModal(true)
                        }}
                    >
                        <span className="flex gap-2 items-center">
                            <FaBoxesStacked className="w-5 h-5" />
                            <span>Return To Inventory</span>
                        </span>
                    </Btn>

                    <Btn
                        size="md"
                        onClick={() => {
                            setShowAuctionTransferModal(true)
                        }}
                    >
                        <span className="flex gap-2 items-center">
                            <MdGavel className="w-5 h-5" />
                            <span>Auction Transfer</span>
                        </span>
                    </Btn>
                </>
            )}
        </div>
    ) : (
        <div className="ml-2 w-full flex justify-start gap-4 items-center">
            <Btn href={`/admin/auction/${id}/lots/create`}>
                <span className="flex gap-2 items-center">
                    <FaPlus className="w-5 h-5" />
                    <span>New Lot</span>
                </span>
            </Btn>
        </div>
    )

    return (
        <AuctionLayout
            id={id}
            auction={auction}
            setAuction={setAuction}
            buttons={buttons}
            showStagger={showStaggerLotsModal}
            setShowStagger={setShowStaggerLotsModal}
            afterStagger={() => {
                updateLots()
            }}
        >
            {anyApprovalRequired && (
                <div className="w-full">
                    <div className="bg-yellow-50 text-yellow-700 border border-yellow-400 w-full px-3 py-2 mb-2">
                        <div>
                            <p>
                                This auction is active. Requested changes must be approved by an admin before going live.
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <div className="w-full">
                {table}
            </div>

            <AuctionTransferModal
                open={showAuctionTransferModal}
                setOpen={setShowAuctionTransferModal}
                resetLots={resetLots}
                lots={checkedLots}
            />
            <ReturnToInventoryModal
                open={showReturnToInventoryModal}
                setOpen={setShowReturnToInventoryModal}
                resetLots={resetLots}
                lots={checkedLots}
            />
            <ImportLotsModal
                visible={showImportLotsModal}
                setVisible={setShowImportLotsModal}
                auction={auction}
                afterImport={() => {
                    updateLots()
                }}
            />
            <SetLotsModal
                visible={showSetLotsModal}
                setVisible={setShowSetLotsModal}
                auction={auction}
                after={() => {
                    updateLots()
                }}
            />
            <MassUpdateLotsModal
                visible={showMassUpdateLotsModal}
                setVisible={setShowMassUpdateLotsModal}
                auction={auction}
                checkedLots={checkedLots}
                after={() => {
                    updateLots()
                }}
            />
        </AuctionLayout>
    )
}

export default Lots