import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { BidIncrements } from 'services/settings'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import Label from 'components/fields/Label'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'
import { formatCurrency } from 'helpers/currency'

const defaultBidIncrement = {
    type: 'fixed'
}

const types = [
    {
        'value': 'fixed',
        'label': 'Fixed'
    },
    {
        'value': 'scale',
        'label': 'Scale'
    }
]

const SettingsBidIncrements = () => {
    const [ visible, setVisible ] = useState(false)
    const [ dialogTitle, setDialogTitle ] = useState('Create Bid Increment')
    const [ bidIncrements, setBidIncrements ] = useState([])
    const [ bidIncrement, setBidIncrement ] = useState(defaultBidIncrement)

    const loadBidIncrements = () => {
        BidIncrements.index({}, ({ data }) => {
            setBidIncrements(data.bidIncrements)
        })
    }

    const deleteBidIncrement = (bi) => {
        BidIncrements.delete(bi.id, () => {
            loadBidIncrements()
        })
    }

    useEffect(() => {
        loadBidIncrements()
    }, [])

    useEffect(() => {
        if (bidIncrement.type === 'scale' && !bidIncrement.config?.levels) {
            addLevel()
        }
    }, [bidIncrement])

    const headerElement = (
        <div className="text-lg font-bold">
            {dialogTitle}
        </div>
    )

    const footerContent = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={() => saveBidIncrement()}>
                Save
            </Button>
        </div>
    )

    const TypeTemplate = (rowData) => {
        return types.filter(type => type.value === rowData.type)[0].label
    }

    const AmountTemplate = (rowData) => {
        let amount = rowData.config.amount

        if (amount) {
            amount = formatCurrency(amount)
        } else if (rowData.config.levels && rowData.config.levels.length > 0) {
            const lowest = rowData.config.levels.reduce((min, level) => level.amount < min ? level.amount : min, rowData.config.levels[0].amount)
            const highest = rowData.config.levels.reduce((max, level) => level.amount > max ? level.amount : max, rowData.config.levels[0].amount)

            if (lowest === highest) {
                amount = formatCurrency(lowest);
            } else {
                amount = formatCurrency(lowest) + ' to ' + formatCurrency(highest);
            }
        }

        return (
            <>
                {amount}
            </>
        )
    }

    const ActionsTemplate = (rowData) => {
        return (
            <div className="flex gap-2 items-center">
                <Button
                    onClick={() => {
                        setBidIncrement(rowData)
                        setDialogTitle('Edit Bid Increment')
                        setVisible(true)
                    }}
                >Edit</Button>
                <Button
                    color="red"
                    onClick={() => {
                        if (window.confirm("Are you sure you want to delete?")) {
                            deleteBidIncrement(rowData)
                        }
                    }}>Delete</Button>
            </div>
        )
    }

    const setData = (k, v) => {
        setBidIncrement({
            ...bidIncrement,
            [k]: v
        })
    }

    const setConfig = (k, v) => {
        const config = bidIncrement.config || {}

        setBidIncrement({
            ...bidIncrement,
            config: {
                ...config,
                [k]: v
            }
        })
    }

    const setLevelValue = (i, k, v) => {
        bidIncrement.config.levels[i][k] = v

        setConfig('levels', [
            ...bidIncrement.config.levels
        ])
    }

    const addLevel = () => {
        setConfig('levels', [
            ...bidIncrement.config?.levels || [],
            {
                min: '',
                max: '',
                amount: ''
            }
        ])
    }

    const removeLevel = (level) => {
        setConfig('levels', bidIncrement.config.levels.filter(l => l !== level))
    }

    const saveBidIncrement = () => {
        const callback = () => {
            setVisible(false)
            setBidIncrement(defaultBidIncrement)
            loadBidIncrements()
        }

        if (bidIncrement.id) {
            BidIncrements.update(bidIncrement.id, bidIncrement, callback)
        } else {
            BidIncrements.create(bidIncrement, callback)
        }
    }

    return (
        <SettingsLayout expandedKeys={['auctions']}>
            <div className="flex w-full justify-end">
                <Button
                    onClick={() => {
                        setDialogTitle('Create Bid Increment')
                        setVisible(true)
                    }}
                >
                    Create
                </Button>
            </div>

            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={headerElement} footer={footerContent}
                    style={{ width: '50rem' }}
                    onHide={() => setVisible(false)}>
                <div className="grid grid-cols-2 gap-2 items-start">
                    <InputField
                        id="bi_name"
                        label="Name"
                        value={bidIncrement.name || ''}
                        onChange={(e) => setData('name', e.target.value)}
                    />

                    <SelectField
                        id="bi_type"
                        label="Type"
                        value={bidIncrement.type || ''}
                        onChange={(e) => setData('type', e.target.value)}
                        options={types}
                    />

                    {bidIncrement.type === 'scale' ? (
                        <div className="col-span-2 grid grid-cols-4 gap-2 mt-2 items-center">
                            <div>
                                <Label>
                                    Min (inclusive)
                                </Label>
                            </div>
                            <div>
                                <Label>
                                    Max
                                </Label>
                            </div>
                            <div>
                                <Label>
                                    Amount
                                </Label>
                            </div>
                            <div></div>

                            {bidIncrement.config?.levels.map((level, i) => {
                                return (
                                    <>
                                        <div className="p-inputgroup flex-1 mt-2">
                                            <span className="p-inputgroup-addon">$</span>
                                            <InputNumber
                                                value={level.min}
                                                maxFractionDigits={2}
                                                onChange={(e) => setLevelValue(i, 'min', e.value)}
                                            />
                                        </div>
                                        <div className="p-inputgroup flex-1 mt-2">
                                            <span className="p-inputgroup-addon">$</span>
                                            <InputNumber
                                                value={level.max}
                                                maxFractionDigits={2}
                                                onChange={(e) => setLevelValue(i, 'max', e.value)}
                                            />
                                        </div>
                                        <div className="p-inputgroup flex-1 mt-2">
                                            <span className="p-inputgroup-addon">$</span>
                                            <InputNumber
                                                value={level.amount}
                                                maxFractionDigits={2}
                                                onChange={(e) => setLevelValue(i, 'amount', e.value)}
                                            />
                                        </div>
                                        <div className="pt-2">
                                            {i === (bidIncrement.config?.levels.length - 1) ? (
                                                <Button onClick={() => addLevel()}>
                                                    + Add
                                                </Button>
                                            ) : (
                                                <Button color="red" onClick={() => removeLevel(level)}>
                                                    &mdash; Remove
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="col-span-2 mt-2">
                            <Label htmlFor="starting_bid">
                                Amount
                            </Label>
                            <div className="p-inputgroup flex-1 mt-2">
                                <span className="p-inputgroup-addon">$</span>
                                <InputNumber
                                    inputId="starting_bid"
                                    maxFractionDigits={2}
                                    value={bidIncrement.config?.amount || ''}
                                    onChange={(e) => setConfig('amount', e.value)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>

            <DataTable className="text-sm" value={bidIncrements} lazy dataKey="id">
                <Column field="name" header="Name" />
                <Column field="type" header="Type" body={TypeTemplate} />
                <Column header="Amount" body={AmountTemplate} />
                <Column body={ActionsTemplate} />
            </DataTable>
        </SettingsLayout>
    )
}

export default SettingsBidIncrements