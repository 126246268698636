import React, { useState, useEffect } from 'react';
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import { index } from 'services/expensesgroups'

const ModalExpenses = ({ show, onClose, fieldValues, onSave, setData }) => {
    const [values, setValues] = useState(fieldValues);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        setValues(fieldValues);
    }, [fieldValues]);

    // set expenses groups to groups
    useEffect(() => {
        index({}, ({ data }) => {
            setGroups(data.expenses_groups)
        })
    }, []);

    
    if (!show) {
        return null;
    }

    const handleSave = () => {
        // add type to values
        values.formType = 'Expenses';

        onSave(values);
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-4xl">
                <h2 className="text-xl mb-4">{
                    values.id ? (
                        values.item_id ? 'Update Lot Expense' : 'Update Invoice Expense'
                    ) : (
                        values.item_id ? 'New Lot Expense' : 'New Invoice Expense'
                    )
                }</h2>

                <SelectField 
                    extra="mb-3"
                    label="Group"
                    id="expenses_group_id"
                    value={values.expense_group_id || ''}
                    onChange={(e) => setData('expense_group_id', e.target.value)}
                    options={groups.map(group => {
                        return {
                            value: group.id,
                            label: group.list_name
                        }
                    })}
                />

                <InputField
                    extra="mb-3"
                    label="Description"
                    id="expense_description"
                    value={values.description || ''}
                    onChange={(e) => setData('description', e.target.value)}
                    type="text"
                />

                <SelectField
                    extra="mb-3"
                    label="Type"
                    id="expense_type"
                    value={values.type || ''}
                    onChange={(e) => setData('type', e.target.value)}
                    options={[
                        {
                            value: 'flat',
                            label: 'Flat'
                        },
                        {
                            value: 'rate',
                            label: 'Rate'
                        }
                    ]}
                />
                
                <InputField
                    extra="mb-3"
                    label="Amount"
                    id="expenses"
                    value={values.amount || ''}
                    onChange={(e) => setData('amount', e.target.value)}
                    type="text"
                />

                <div className="flex justify-end gap-4">
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">Cancel</button>
                    <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Save</button>
                </div>
            </div>
        </div>
    );
};

export default ModalExpenses;