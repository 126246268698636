import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FilterMatchMode } from 'primereact/api'
import { index } from 'services/items'
import { debounce } from 'lodash'
import {
    FaEllipsisVertical,
    FaMagnifyingGlass,
    FaPencil,
    FaPlus
} from 'react-icons/fa6'
import {
    MdGavel
} from 'react-icons/md'
import { InputText } from 'primereact/inputtext'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import Btn from 'components/buttons/Button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { all as allAuctions } from 'services/auctions'
import AssignModal from './components/modals/AssignModal'

const ItemsIndex = () => {
    const navigate = useNavigate()
    const [ items, setItems ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'items.inventory_number': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'items.vin': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'items.make': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'items.model': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'items.year': { value: null, matchMode: FilterMatchMode.EQUALS },
        'items.description': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'items.qty': { value: 1, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        'qty_on_auction': { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        'qty_sold': { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')
    const [ checkedItems, setCheckedItems ] = useState([])
    const [ allChecked, setAllChecked ] = useState(false)
    const [ showAssignToAuctionModal, setShowAssignToAuctionModal ] = useState(false)

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        index({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify(filters)
        }, ({ data }) => {
            setItems(data.items)
        })
    }, [page, sort, filters])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }

    const header = renderHeader()

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null);
        const items = [
            {
                label: 'Edit',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/item/${rowData.id}`)
                }
            },
        ]

        return (
            <>
                <Menu model={items} popup ref={menu} id={"popup_menu_" + rowData.id} popupAlignment="left" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} onClick={(event) => menu.current.toggle(event)} aria-controls={"popup_menu_" + rowData.id} aria-haspopup />
            </>
        )
    }

    const ImageBodyTemplate = (item) => {
        if (! item.image?.src) {
            return
        }

        return <img src={item.image?.src} alt={item.image?.alt} className="w-full max-h-[8rem] object-cover" />
    }

    const CheckboxTemplate = (item) => {
        return (
            <input
                type="checkbox"
                checked={checked(item)}
                onChange={e => checkItem(item, e.target.checked)}
                />
        )
    }

    const AllCheckboxTemplate = () => {
        return (
            <input
                type="checkbox"
                checked={allChecked}
                onChange={e => checkAll(e.target.checked)}
            />
        )
    }

    const checked = (item) => {
        return checkedItems.indexOf(item.id) >= 0
    }

    const checkItem = (item, toggled) => {
        let items = [...checkedItems]

        if (toggled) {
            if (! checked(item)) {
                items.push(item.id)
            }
        } else {
            items = items.filter(i => i !== item.id)
        }

        setCheckedItems(items)
    }

    const checkAll = (toggled) => {
        if (toggled) {
            setCheckedItems(
                items.data.map(item => item.id)
            )
        } else {
            setCheckedItems([])
        }
    }

    const allItemsChecked = () => {
        let all = true

        if (! items.data) {
            return false
        }

        items.data?.forEach((item) => {
            if (! checked(item)) {
                all = false
            }
        })

        return all
    }

    useEffect(() => {
        setAllChecked(
            allItemsChecked()
        )
    }, [checkedItems])

    return (
        <div className="mt-3">
            <div className="pt-3 mb-3 flex justify-end gap-4 items-center">
                {checkedItems.length > 0 && (
                    <Btn
                        onClick={() => {
                            setShowAssignToAuctionModal(true)
                        }}
                    >
                        <span className="flex gap-2 items-center">
                            <MdGavel className="w-5 h-5" />
                            <span>Assign to Auction</span>
                        </span>
                    </Btn>
                )}

                <Btn href="/admin/items/create">
                    <span className="flex gap-2 items-center">
                        <FaPlus className="w-5 h-5" />
                        <span>New Item</span>
                    </span>
                </Btn>
            </div>

            {items && (
                <DataTable className="text-sm" value={items.data} lazy dataKey="id" paginator
                           filters={filters} filterDisplay="menu" onFilter={onFilter} globalFilterFields={['items.vin', 'items.description']}
                           first={items.from - 1} rows={items.per_page} totalRecords={items.total} onPage={onPage}
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           header={header}>
                    <Column body={MenuBodyTemplate}></Column>
                    <Column body={CheckboxTemplate} header={AllCheckboxTemplate}></Column>
                    <Column filterHeaderClassName="min-w-[180px]" dataType="numeric" field="inventory_number" sortField="items.inventory_number" filterField="items.inventory_number" header="Inv. Number" filter sortable />
                    <Column header="Image" body={ImageBodyTemplate} bodyClassName="w-32" />
                    <Column filterHeaderClassName="min-w-[180px]" bodyClassName="w-96" field="description" sortField="items.description" filterField="items.description" filter sortable header="Description" body={(item) => (
                        <p className="line-clamp-1" title={item.description}>
                            {item.description}
                        </p>
                    )} />
                    <Column field="user.name" sortField="users.last_name" header="Consignor" sortable />
                    <Column header="Consignor Company" body={(item) => {
                        const association = item.user?.associations[0]

                        return association?.associated?.name
                    }} />
                    <Column filterHeaderClassName="min-w-[180px]" dataType="numeric" field="qty" sortField="items.qty" filterField="items.qty" header="Qty on Hand" filter sortable />
                    <Column filterHeaderClassName="min-w-[180px]" dataType="numeric" field="qty_on_auction" sortField="qty_on_auction" filterField="qty_on_auction" header="Qty on Auction" filter sortable />
                    <Column filterHeaderClassName="min-w-[180px]" dataType="numeric" field="qty_sold" sortField="qty_sold" filterField="qty_sold" header="Qty Sold" filter sortable />
                    <Column filterHeaderClassName="min-w-[180px]" field="vin" sortField="items.vin" filterField="items.vin" header="VIN" filter sortable />
                    <Column filterHeaderClassName="min-w-[180px]" field="make" sortField="items.make" filterField="items.make" header="Make" filter sortable />
                    <Column filterHeaderClassName="min-w-[180px]" field="model" sortField="items.model" filterField="items.model" header="Model" filter sortable />
                    <Column filterHeaderClassName="min-w-[180px]" field="year" sortField="items.year" filterField="items.year" header="Year" filter sortable />
                </DataTable>
            )}

            <AssignModal
                open={showAssignToAuctionModal}
                setOpen={setShowAssignToAuctionModal}
                items={checkedItems}
                resetItems={() => {
                    checkAll(false)
                }}
            />
        </div>
    )
}

export default ItemsIndex