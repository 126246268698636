import noteTypes from '../notes/NoteTypes'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

const onlyTextColor = (classes) => {
    return classes.match(/(text-\S+) /)[1]
}

const strip_tags = (str) => {
    str = str.toString();
    return str.replace(/<\/?[^>]+>/gi, '');
}

const IconAlert = ({ note }) => {
    const type = noteTypes.filter(type => type.value === note.options.type)[0]
    const color = onlyTextColor(type ? type.classes : '')

    return (
        <ExclamationTriangleIcon
            className={'w-5 h-5 ' + color}
            title={strip_tags(note.body)}
        />
    )
}

export default IconAlert