import axios from 'axios'

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/locations/create', data)
         .then(then)
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/location/' + id + '/update', data)
         .then(then)
}

export const deleteLocation = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/location/' + id + '/delete')
         .then(then)
}