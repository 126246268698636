import {useEffect, useMemo, useRef, useState} from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { formatCurrency } from 'helpers/currency'
import { FilterMatchMode } from 'primereact/api'

import {
    FaPencil,
    FaEllipsisVertical, FaMagnifyingGlass
} from "react-icons/fa6";
import {InputText} from 'primereact/inputtext'
import {debounce} from 'lodash'

const InvoicesTable = ({ loadInvoices }) => {
    const [ invoices, setInvoices ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()
    const [ filters, setFilters ] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'invoices.number': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'auctions.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.last_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.phone': { value: null, matchMode: FilterMatchMode.CONTAINS },
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        loadInvoices({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify(filters)
        }, ({ data }) => {
            setInvoices(data.invoices)
        })
    }, [page, sort, filters])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const loading = false

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null);
        const items = [
            {
                label: 'Edit',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/invoice/${rowData.id}/edit`)
                }
            },
        ]

        return (
            <>
                <Menu model={items} popup ref={menu} id="popup_menu_right" popupAlignment="right" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} className="mr-2" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
            </>
        )
    }

    const currency = (data, field) => {
        return formatCurrency(data[field])
    }

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </div>
        )
    }

    const header = renderHeader()

    return (
        <DataTable className="text-sm" value={invoices.data} lazy dataKey="id" paginator
                   filters={filters} filterDisplay="menu" onFilter={onFilter} globalFilterFields={['invoices.number', 'auctions.name', 'users.first_name', 'users.last_name', 'users.email']}
                   first={invoices.from - 1} rows={invoices.per_page} totalRecords={invoices.total} onPage={onPage}
                   onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                   rowClassName="cursor-pointer"
                   onRowClick={(e) => {
                       navigate(`/admin/invoice/${e.data.id}/edit`)
                   }}
                   loading={loading} tableStyle={{ minWidth: '75rem' }}
                   header={header}>
            <Column field="number" header="Number" sortField="invoices.number" filterField="invoices.number" sortable filter
                    body={(data) => {
                        return (
                            <Link to={`/admin/invoice/${data.id}/edit`} className="text-slate-400 hover:text-slate-600">{data.number}</Link>
                        )
                    }}
            />
            <Column field="auction.name" sortField="auctions.name" filterField="auctions.name" sortable filter header="Auction"
                    body={(data) => {
                        return (
                            <Link to={`/admin/auction/${data.auction_id}`} className="text-slate-400 hover:text-slate-600">{data.auction.name}</Link>
                        )
                    }}
            />
            <Column field="buyer.name" sortField="users.last_name" filterField="users.last_name" sortable filter header="Buyer"
                    body={(data) => {
                        return (
                            <Link to={`/admin/user/${data.buyer_id}/edit`} className="text-slate-400 hover:text-slate-600">{data.buyer.name}</Link>
                        )
                    }}
            />
            <Column field="buyer.email" sortField="users.email" filterField="users.email" sortable filter header="Email" />
            <Column field="buyer.phone" sortField="users.phone" filterField="users.phone" sortable filter header="Phone" />
            <Column field="dates.created" sortField="invoices.created_at" sortable header="Created" />
            <Column field="counts.items" header="Lots" />
            <Column field="total" sortField="invoices.total" sortable header="Total"
                    body={(data) => currency(data, 'total')} />
            <Column field="payments" sortField="invoices.payments" sortable header="Payments"
                    body={(data) => currency(data, 'payments')} />
            <Column field="balance" sortField="invoices.balance" sortable header="Balance"
                    body={(data) => currency(data, 'balance')} />
            <Column headerStyle={{ width: '4rem' }} body={MenuBodyTemplate}></Column>
        </DataTable>
    )
}

export default InvoicesTable