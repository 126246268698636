import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import SortablePhoto from './SortablePhoto'
import axios from 'axios'
import DeleteAllLotPhotosModal from './modals/DeleteAllLotPhotosModal'
import { rotatePhoto } from 'services/lots'
import { Link } from 'react-router-dom'
import Button from 'components/buttons/Button'
import UploadLimits from 'components/uploads/UploadLimits'

const LotPhotoGallery = ({ lot, approval, pendingApproval, afterApprove }) => {
    const [ assets, setAssets ] = useState(approval ? (pendingApproval ? pendingApproval.assets : []) : lot.assets)

    useEffect(() => {
        setAssets(approval ? (pendingApproval ? pendingApproval.assets : []) : lot.assets)
    }, [lot.assets, pendingApproval?.assets])

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (e) => {
        const { active, over } = e

        if (active.id !== over.id) {
            setAssets((assets) => {
                const oldIndex = assets.findIndex(asset => asset.id === active.id)
                const newIndex = assets.findIndex(asset => asset.id === over.id)

                const resorted = arrayMove(assets, oldIndex, newIndex)

                axios.post(process.env.REACT_APP_API_URL + `/lot/${lot.id}${approval ? '/approval' : ''}/photos/sort`, {
                    'order': resorted.map(asset => asset.id)
                })

                return resorted
            })
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData()

        acceptedFiles.forEach((file) => {
            formData.append("files[]", file)
        })

        axios.post(process.env.REACT_APP_API_URL + `/lot/${lot.id}${approval ? '/approval' : ''}/photos/upload`, formData)
            .then((response) => {
                setAssets(approval ? (response.data.lot.pending_approvals[0] ? response.data.lot.pending_approvals[0].assets : []) : response.data.lot.assets)
            })
    }, [])

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        }
    })

    const onDelete = (asset) => {
        axios.delete(process.env.REACT_APP_API_URL + `/lot/${lot.id}${approval ? '/approval' : ''}/photos/${asset.id}/delete`)
            .then((response) => {
                setAssets(approval ? (response.data.lot.pending_approvals[0] ? response.data.lot.pending_approvals[0].assets : []) : response.data.lot.assets)
            })
    }

    const onApprove = (asset) => {
        axios.post(process.env.REACT_APP_API_URL + `/lot/${lot.id}/approval/photos/${asset.id}/approve`)
            .then((response) => {
                setAssets(approval ? (response.data.lot.pending_approvals[0] ? response.data.lot.pending_approvals[0].assets : []) : response.data.lot.assets)
                afterApprove()
            })
    }

    const onApproveAll = () => {
        axios.post(process.env.REACT_APP_API_URL + `/lot/${lot.id}/approval/photos/approveAll`)
            .then((response) => {
                setAssets(approval ? (response.data.lot.pending_approvals[0] ? response.data.lot.pending_approvals[0].assets : []) : response.data.lot.assets)
                afterApprove()
            })
    }

    const onRotate = (asset, degrees) => {
        rotatePhoto(lot.id, asset.id, {
            degrees
        }, (response) => {
            setAssets(approval ? (response.data.lot.pending_approvals[0] ? response.data.lot.pending_approvals[0].assets : []) : response.data.lot.assets)
        }, approval)
    }

    if (! lot.editable && ! approval) {
        return (
            <>
                <div className="border border-gray-200 rounded-lg px-4 py-4 mb-2">
                    <div className="text-lg font-bold">#{lot.number} {lot.name}</div>
                    <div className="bg-gray-200 rounded-lg px-4 py-4 mt-2">
                        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                            {assets.map((asset) => (
                                <img src={asset.url} alt="" className="h-48 object-cover" />
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext items={assets}>
                {approval && (
                    <p className="text-lg font-bold mt-4 mb-2 px-2 py-1 bg-cyan-200">Auction is live. Please upload new photos here for us to approve. Contact your rep to delete existing photos</p>
                )}
                <div className="border border-gray-200 rounded-lg px-4 py-4 mb-2">
                    <div className="flex items-center justify-between">
                        <Link to={`/admin/lot/${lot.id}`} className="text-lg font-bold">
                            {approval ? 'NEW PHOTOS FOR LOT ' : ''}#{lot.number} {lot.name}
                        </Link>
                        {assets.length > 0 && (
                            <div className="flex gap-2 items-center">
                                {approval === 'admin' && (
                                    <Button size="md" onClick={onApproveAll}>
                                        Approve All
                                    </Button>
                                )}

                                <DeleteAllLotPhotosModal
                                    lot={lot}
                                    setAssets={setAssets}
                                    approval={approval}
                                />
                            </div>
                        )}
                    </div>

                    <div {...getRootProps()} className="bg-gray-200 rounded-lg px-4 pb-4 mt-2">
                        <div onClick={open}>
                            <input {...getInputProps()} />

                            <p className="text-center py-4">
                                { isDragActive ? (
                                    <>
                                        Drop the files here...
                                    </>
                                ) : (
                                    <>
                                        Drag &amp; drop your files or <span className="underline cursor-pointer">Browse</span>
                                    </>
                                )}
                            </p>

                            <UploadLimits />
                        </div>

                        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                            {assets.map((asset) => (
                                <SortablePhoto
                                    key={asset.id}
                                    asset={asset}
                                    onDelete={onDelete}
                                    onRotate={onRotate}
                                    onApprove={onApprove}
                                    approval={approval}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </SortableContext>
        </DndContext>
    )
}

export default LotPhotoGallery