import axios from 'axios'

export const getPermissions = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auth/permissions', {
        params
    }).then(then)
}

export const getNotifications = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/notifications/all', {
        params
    }).then(then)
}

export const getUnreadNotifications = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/notifications/unread', {
        params
    }).then(then)
}

export const markNotificationRead = (id, params, then) => {
    axios.post(process.env.REACT_APP_API_URL + `/notification/${id}/mark-read`, params).then(then)
}

export const markAllNotificationsRead = (params, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/notifications/mark-all-read', params).then(then)
}

export const forgotPassword = (params, then) => {
    axios.get(process.env.REACT_APP_BASE_URL + '/sanctum/csrf-cookie').then(response => {
        axios.post(process.env.REACT_APP_API_URL + '/forgot-password', params)
             .then(then)
    })
}