import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { unban } from 'services/users'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const UnbanUserModal = ({ user, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()

    const onClick = () => {
        unban(user.id, {}, ({ data }) => {
            setVisible(false)

            notify({
                message: 'User has been unbanned.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data.user)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Unban User
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={onClick}>
                Confirm
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'unban'}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}>
            <p>
                Are you sure you want to unban this user?
            </p>
        </Dialog>
    )
}

export default UnbanUserModal