const DateRangeFilter = ({ from, to, setFilter }) => {
    return (
        <div className="flex gap-4 items-center mb-4">
            <div className="flex gap-2 items-center">
                <label htmlFor="date_range_from">From</label>
                <input
                    id="date_range_from"
                    type="date"
                    value={from}
                    onChange={(e) => {
                        setFilter('from', e.target.value)
                    }}
                />
            </div>

            <div className="flex gap-2 items-center">
                <label htmlFor="date_range_to">To</label>
                <input
                    id="date_range_to"
                    type="date"
                    value={to}
                    onChange={(e) => {
                        setFilter('to', e.target.value)
                    }}
                />
            </div>
        </div>
    )
}

export default DateRangeFilter