import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/categories', {
        params
    }).then(then).catch(response => {

    })
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/categories/' + id)
         .then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/categories/create', data)
        .then(then).catch(response => {
            alert('Failed to create category. Please try again')
        })
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/categories/' + id + '/update', data)
        .then(then).catch(response => {
            alert('Failed to update category. Please try again')
        })
}

export const getParents = (id, then) => {
    let url = ''
    if (id) {
        url = process.env.REACT_APP_API_URL + '/categories/parents/' + id
    } else {
        url = process.env.REACT_APP_API_URL + '/categories/parents'
    }
    
    axios.get(url)
        .then(then).catch(response => {
            alert('Failed to get parent categories. Please try again')
        })
}

export const getByType = (type, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/categories/type/' + type)
         .then(then)
}