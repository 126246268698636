import { CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const TableRow = ({ row, handleChange, toggleAll, selectedRows }) => {
    const change = function (e, row) {
        if (e.target.value === '') {
            // This is the header row
            toggleAll(e.target.checked)
        } else {
            handleChange(row, e.target.checked)
        }
    }

    const disabled = row.attrs.block

    let value = ''
    let bg = ''

    if (! row.headers) {
        value = row.mapped.username

        if (row.attrs.block) {
            bg = 'bg-gray-200'
        } else if (row.attrs.duplicate) {
            bg = 'bg-red-600'
        } else if (! row.attrs.accept) {
            bg = 'bg-red-100'
        } else {
            bg = 'bg-green-100'
        }
    }

    const cellClasses = function (cell) {
        let classes = ''

        if (cell.duplicate) {
            classes = 'text-red-600 font-bold'
        }/* else if (cell.ok) {
            classes = 'text-green-600 font-bold'
        } */

        return classes;
    }

    const cellTooltip = function (cell) {
        let tooltip = ''

        if (cell.duplicate) {
            if (cell.key == 'username') {
                tooltip = 'This username already exists, so the user cannot be imported.'
            } else if (cell.key == 'email') {
                tooltip = 'This email address already exists, so the user cannot be imported.'
            }
        }

        return tooltip
    }

    return (
        <tr className={bg}>
            <td>
                <input type="checkbox" value={value} checked={selectedRows.includes(row)} disabled={disabled} onChange={(e) => {
                    change(e, row)
                }} className={"form-checkbox h-5 w-5 text-blue-600 " + (disabled ? 'cursor-not-allowed' : '') } />
            </td>

            {row.cells.map((cell, i) => (
                <td key={i} className="px-3 py-3.5 whitespace-nowrap">
                    <div className={"flex gap-1 items-center " + cellClasses(cell)}>
                        {cell.value}

                        {cell.ok ? (
                            <CheckBadgeIcon className="h-4 w-4" title={cellTooltip(cell)} />
                        ) : ! row.headers && (
                            <ExclamationTriangleIcon className="h-4 w-4" title={cellTooltip(cell)} />
                        )}
                    </div>

                    {
                        cell.exists && (
                            <p className="text-xs text-red-600 font-bold">
                                <span>Already Imported</span>
                            </p>
                        )
                    }
                </td>
            ))}
        </tr>
    )
}

export default TableRow
