import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/invoices', {
        params
    }).then(then)
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/invoice/' + id)
        .then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/invoices/create', data)
        .then(then)
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/invoice/' + id + '/update', data)
        .then(then)
}

export const createExpense = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/invoice/' + id + '/expenses/create', data)
         .then(then)
}

export const updateExpense = (id, expense_id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/invoice/' + id + '/expense/' + expense_id + '/update', data)
         .then(then)
}

export const deleteExpense = (id, expense_id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/invoice/' + id + '/expense/' + expense_id + '/delete')
         .then(then)
}

export const createPayment = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/invoice/' + id + '/payments/create', data)
         .then(then)
}

export const updatePayment = (id, payment_id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/invoice/' + id + '/payment/' + payment_id + '/update', data)
         .then(then)
}

export const deletePayment = (id, payment_id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/invoice/' + id + '/payment/' + payment_id + '/delete')
         .then(then)
}