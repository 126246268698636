import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { can, useAuth } from 'components/auth/AuthContext'
import { recover } from 'services/lots'
import Btn from 'components/buttons/Button'
import SplitButton from 'components/buttons/SplitButton'
import Breadcrumbs from 'components/navbar/Breadcrumbs'
import MenuBar from 'components/menu/MenuBar'
import VerticalMenu from 'components/menu/VerticalMenu'
import NoteDisplay from 'components/notes/NoteDisplay'
import AuctionTransferModal from './modals/AuctionTransferModal'
import ReturnToInventoryModal from './modals/ReturnToInventoryModal'
import { MdGavel } from 'react-icons/md'
import { FaBoxesStacked } from 'react-icons/fa6'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const LotLayout = ({ id, lot, setLot, children, buttons }) => {
    const [ showReturnToInventoryModal, setShowReturnToInventoryModal ] = useState(false)
    const [ showAuctionTransferModal, setShowAuctionTransferModal ] = useState(false)
    const navigate = useNavigate()
    const notify = useNotificationsDispatch()

    const auth = useAuth()
    const canViewBids = can(auth, 'view_bids')

    const menu = [
        { name: 'Dashboard', href: '/admin/lot/' + id },
        { name: 'Edit', href: `/admin/lot/${id}/edit` },
        { name: 'Photos', href: `/admin/lot/${id}/photos` },
        canViewBids ? { name: 'Bids', href: `/admin/lot/${id}/bids`, count: lot?.counts.bids } : null,
        canViewBids ? { name: 'Watches', href: `/admin/lot/${id}/watches`, count: lot?.counts.watches } : null,
        { name: 'Item History', href: `/admin/item/${lot?.item_id}/history` },
        { name: 'Change History', href: `/admin/lot/${id}/changes` },
        { name: 'Documents', href: `/admin/lot/${id}/documents` },
        { name: 'Notes', href: `/admin/lot/${id}/notes` },
    ].filter(item => !!item)

    menu.forEach(item => {
        item.current = (window.location.pathname === item.href)
    })

    if (! lot) {
        return
    }

    const recoverLot = () => {
        recover(id, ({ data }) => {
            setLot(data.lot)

            notify({
                message: 'Lot recovered.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    return (
        <>
            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/auctions",
                        title: "Auctions"
                    },
                    {
                        href: `/admin/auction/${lot.auction.id}`,
                        title: lot.auction.name
                    },
                    {
                        href: "#",
                        title: lot.name
                    }
                ]} />
            </div>

            <div className="pt-4">
                <div className="flex gap-6">
                    <VerticalMenu items={menu} />

                    <div className="w-full">
                        {lot.deleted_at && (
                            <div className="bg-red-50 text-red-700 border border-red-400 w-full px-3 py-2 mb-2">
                                This lot was deleted {new Date(lot.deleted_at).toLocaleString()}.{' '}
                                <a href="#" className="font-bold" onClick={recoverLot}>Click here to recover it.</a>
                            </div>
                        )}

                        <MenuBar>
                            <SplitButton
                                url={lot.links.show}
                                target="_blank"
                                label="Preview"
                                items={[
                                    { name: 'Auction Index', href: lot.auction.links.show, target: '_blank' }
                                ]}
                            />

                            {lot.editable && (
                                <>
                                    <Btn
                                        onClick={() => {
                                            setShowReturnToInventoryModal(true)
                                        }}
                                        size="md"
                                    >
                                        <span className="flex gap-2 items-center">
                                            <FaBoxesStacked className="w-5 h-5" />
                                            <span>Return To Inventory</span>
                                        </span>
                                    </Btn>

                                    <Btn
                                        onClick={() => {
                                            setShowAuctionTransferModal(true)
                                        }}
                                        size="md"
                                    >
                                        <span className="flex gap-2 items-center">
                                            <MdGavel className="w-5 h-5" />
                                            <span>Auction Transfer</span>
                                        </span>
                                    </Btn>
                                </>
                            )}

                            {buttons}
                        </MenuBar>

                        <div className="w-full">
                            <NoteDisplay notes={lot.notes} />
                        </div>

                        {children}
                    </div>
                </div>
            </div>

            <AuctionTransferModal
                open={showAuctionTransferModal}
                setOpen={setShowAuctionTransferModal}
                resetLots={(auction_id) => {
                    navigate(`/admin/auction/${auction_id}/lots`)
                }}
                lots={[id]}
            />
            <ReturnToInventoryModal
                open={showReturnToInventoryModal}
                setOpen={setShowReturnToInventoryModal}
                resetLots={() => {
                    navigate(`/admin/item/${lot.item_id}/history`)
                }}
                lots={[id]}
            />
        </>
    )
}

export default LotLayout