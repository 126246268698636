import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { history as loadHistory } from 'services/items'
import ItemLayout from './components/ItemLayout'
import {
    FaExternalLinkAlt
} from 'react-icons/fa'
import Link from 'components/buttons/Link'

const ItemHistory = () => {
    const { id } = useParams()
    const [ item, setItem ] = useState(null)
    const [ history, setHistory ] = useState([])

    useEffect(() => {
        loadHistory(id, {}, ({ data }) => {
            setItem(data.item)
            setHistory(data.history)
        })
    }, [id])

    if (! item) {
        return
    }

    return (
        <ItemLayout id={id} item={item}>
            <div className="w-full">
                {history.length > 0 ? (
                    <table className="w-full text-left border-separate border-spacing-y-4">
                        <thead>
                        <tr className="uppercase text-xs tracking-widest">
                            <th>Action</th>
                            <th>Quantity</th>
                            <th>Details</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {history.map(record => (
                            <tr>
                                <td>{record.action}</td>
                                <td>{record.qty}</td>
                                <td>
                                    {record.from_auction && (
                                        <div className="flex gap-2 items-center mb-1">
                                            <b>From Auction: </b>
                                            <span>{record.from_auction.name}</span>
                                            <Link to={`/admin/auction/${record.from_auction.id}`}>
                                                <FaExternalLinkAlt className="w-4 h-4" />
                                            </Link>
                                        </div>
                                    )}
                                    {record.auction && (
                                        <div className="flex gap-2 items-center mb-1">
                                            <b>Auction: </b>
                                            <span>{record.auction.name}</span>
                                            <Link to={`/admin/auction/${record.auction.id}`}>
                                                <FaExternalLinkAlt className="w-4 h-4" />
                                            </Link>
                                        </div>
                                    )}
                                    {record.lot && (
                                        <div className="flex gap-2 items-center mb-1">
                                            <b>Lot: </b>
                                            <span>{record.lot.name}</span>
                                            <Link to={`/admin/lot/${record.lot.id}`}>
                                                <FaExternalLinkAlt className="w-4 h-4" />
                                            </Link>
                                        </div>
                                    )}
                                    {record.invoice && (
                                        <div className="flex gap-2 items-center mb-1">
                                            <b>Invoice: </b>
                                            <span>{record.invoice.number}</span>
                                            <Link to={`/admin/invoice/${record.invoice.id}`}>
                                                <FaExternalLinkAlt className="w-4 h-4" />
                                            </Link>
                                        </div>
                                    )}
                                </td>
                                <td>{record.dates.occurred}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No results</p>
                )}
            </div>
        </ItemLayout>
    )
}

export default ItemHistory