import { useCallback, useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { debounce } from 'lodash'
import axios from 'axios'
import Link from 'components/buttons/Link'

const Search = () => {
    const [ query, setQuery ] = useState('')
    const [ results, setResults ] = useState({})
    const [ focused, setFocused ] = useState(false)

    const searchRequest = useCallback(
        debounce(async (query) => {
            if (query.length < 3) {
                setResults({})
                return
            }

            const { data: results } = await axios.get(
                process.env.REACT_APP_API_URL + "/search",
                {
                    params: { query },
                }
            )
            setResults(results)
        }, 400),
        []
    )

    useEffect(() => {
        searchRequest(query);
    }, [query])

    return (
        <div className="relative h-full">
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                <p className="pl-3 pr-2 text-xl">
                    <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                </p>
                <input
                    type="text"
                    placeholder="Search..."
                    className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit focus:outline-none focus:ring-0 border-0 p-0"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onFocus={(e) => {
                        setFocused(true)
                    }}
                    onBlur={(e) => {
                        setTimeout(() => {
                            if (e.target !== document.activeElement) {
                                setFocused(false)
                            }
                        }, 200)
                    }}
                />
            </div>
            {focused && (
                <div className="w-full xl:w-[230px] absolute top-14 -mt-1 left-0 shadow-xl z-50 bg-white text-xs">
                    {results.auctions?.length > 0 && (
                        <div className="p-2">
                            <h4 className="font-bold pb-2 px-1">Auctions</h4>
                            {results.auctions.map((auction) => (
                                <Link
                                    to={`/admin/auction/${auction.id}`}
                                    key={auction.id}
                                    className="block text-slate-600 hover:text-slate-800 hover:bg-slate-100 p-1"
                                >
                                    {auction.name}
                                </Link>
                            ))}
                        </div>
                    )}
                    {results.lots?.length > 0 && (
                        <div className="p-2">
                            <h4 className="font-bold pb-2 px-1">Lots</h4>
                            {results.lots.map((lot) => (
                                <Link
                                    to={`/admin/lot/${lot.id}`}
                                    key={lot.id}
                                    className="block text-slate-600 hover:text-slate-800 hover:bg-slate-100 p-1"
                                >
                                    {lot.name}
                                </Link>
                            ))}
                        </div>
                    )}
                    {results.items?.length > 0 && (
                        <div className="p-2">
                            <h4 className="font-bold pb-2 px-1">Inventory</h4>
                            {results.items.map((item) => (
                                <Link
                                    to={`/admin/item/${item.id}`}
                                    key={item.id}
                                    className="block text-slate-600 hover:text-slate-800 hover:bg-slate-100 p-1"
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    )}
                    {results.clients?.length > 0 && (
                        <div className="p-2">
                            <h4 className="font-bold pb-2 px-1">Clients</h4>
                            {results.clients.map((client) => (
                                <Link
                                    to={`/admin/client/${client.id}`}
                                    key={client.id}
                                    className="block text-slate-600 hover:text-slate-800 hover:bg-slate-100 p-1"
                                >
                                    {client.name}
                                </Link>
                            ))}
                        </div>
                    )}
                    {results.companies?.length > 0 && (
                        <div className="p-2">
                            <h4 className="font-bold pb-2 px-1">Companies</h4>
                            {results.companies.map((company) => (
                                <Link
                                    to={`/admin/company/${company.id}`}
                                    key={company.id}
                                    className="block text-slate-600 hover:text-slate-800 hover:bg-slate-100 p-1"
                                >
                                    {company.name}
                                </Link>
                            ))}
                        </div>
                    )}
                    {results.users?.length > 0 && (
                        <div className="p-2">
                            <h4 className="font-bold pb-2 px-1">Users</h4>
                            {results.users.map((user) => (
                                <Link
                                    to={`/admin/user/${user.id}`}
                                    key={user.id}
                                    className="block text-slate-600 hover:text-slate-800 hover:bg-slate-100 p-1"
                                >
                                    {user.name}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Search