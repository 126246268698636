import classNames from 'classnames'

const MenuBar = ({ justify = 'start', children }) => {
    return (
        <div className={"flex items-center mb-4 gap-4 justify-" + justify}>
            {children}
        </div>
    )
}

export default MenuBar