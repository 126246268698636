import Card from 'components/card'
import InputField from 'components/fields/InputField'
import TextField from 'components/fields/TextField'
import SelectField from 'components/fields/SelectField'
import { useEffect, useState } from 'react'
import { all as allCompanies } from 'services/companies'
import { keyOptions, runsOptions, drivesOptions, airbagStatuses, catalyticOptions } from 'services/items'
import { decode as decodeVin } from 'services/vin'
import Button from 'components/buttons/Button'
import { getByType } from 'services/categories'
import AddLocationModal from '../../companies/components/modals/AddLocationModal'
import EditLocationModal from '../../companies/components/modals/EditLocationModal'

const ItemForm = ({ item, setItem, company, setCompany, submit }) => {
    const [ companies, setCompanies ] = useState([])
    const [ companiesOptions, setCompaniesOptions ] = useState([])
    const [ locationsOptions, setLocationsOptions ] = useState([])
    const [ location, setLocation ] = useState(null)
    const [ categories, setCategories ] = useState([])
    const [ errors, setErrors ] = useState({})
    const hasErrors = Object.values(errors).filter((value) => {
        return value
    }).length > 0

    const setData = (k, v) => {
        setItem({
            ...item,
            [k]: v
        })
    }

    useEffect(() => {
        allCompanies({}, ({ data }) => {
            setCompanies(data.companies)

            // Automatically select the first (if we're creating a new item)
            if (! item.id && data.companies.length === 1) {
                const company = data.companies[0]
                setData('company_id', company.id)
                setCompany(company)
            }
        })

        getByType('item', ({ data }) => {
            setCategories(data.categories)
        })
    }, [])

    useEffect(() => {
        setCompaniesOptions(
            companies.map(company => {
                return {
                    label: company.name,
                    value: company.id
                }
            })
        )
    }, [companies])

    useEffect(() => {
        if (! company) {
            setLocationsOptions([])
            return
        }

        setLocationsOptions(
            company.locations?.map(location => {
                return {
                    label: location.name || location.full_address,
                    value: location.id
                }
            })
        )
        setLocationFromId(item.location_id)
    }, [company])

    const setLocationFromId = (id) => {
        setLocation(
            id
                ? company.locations.filter(l => l.id == id)[0]
                : null
        )
    }

    const selectCompany = (v) => {
        setData('company_id', v)

        setCompany(
            companies.filter(company => company.id == v)[0]
        )

        setError('company_id', false)
    }

    const setError = (k, v) => {
        setErrors({
            ...errors,
            [k]: v
        })
    }

    const validate = () => {
        if (! item.company_id) {
            setError('company_id', true)
            return false
        }

        return true
    }

    const submitAfterValidating = (e) => {
        if (! validate()) {
            return false
        }

        return submit(e)
    }

    const vinLookup = (e) => {
        e.preventDefault()

        decodeVin(item.vin, ({ data }) => {
            const updated = {
                ...item
            }

            if (data.make) {
                updated.make = data.make
            }

            if (data.model) {
                updated.model = data.model
            }

            if (data.year) {
                updated.year = data.year
            }

            setItem(updated)
        })
    }

    return (
        <Card extra={"w-1/2 mt-3 px-6 py-6"}>
            {/* Header */}
            <div className="w-full px-[8px] mb-4">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Item Details
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Basic information related to the item
                </p>
            </div>

            <div className="grid grid-cols-3 gap-3 mb-3">
                <SelectField
                    extra=""
                    label="Category"
                    id="category_id"
                    value={item.category_id || ''}
                    onChange={(e) => setData('category_id', e.target.value)}
                    options={categories}
                />
                
                <InputField
                    extra="mb-3"
                    label="Inventory Number"
                    id="inventory_number"
                    value={item.inventory_number || ''}
                    onChange={(e) => setData('inventory_number', e.target.value)}
                    type="number"
                />

                <InputField
                    extra="mb-3"
                    label="Quantity"
                    id="qty"
                    value={item.qty}
                    onChange={(e) => setData('qty', e.target.value)}
                    type="number"
                />

                <SelectField
                    extra="mb-3"
                    label="Consignor Company *"
                    id="company_id"
                    value={item.company_id || ''}
                    onChange={(e) => selectCompany(e.target.value)}
                    options={companiesOptions}
                    state={errors.company_id ? 'error' : ''}
                />

                <SelectField
                    extra="mb-3"
                    label="Location"
                    id="location_id"
                    value={item.location_id || ''}
                    onChange={(e) => {
                        setData('location_id', e.target.value)
                        setLocationFromId(e.target.value)
                    }}
                    options={locationsOptions}
                />

                <div className="flex flex-col justify-center items-center gap-2">
                    <AddLocationModal
                        company={company}
                        after={(newLocation) => {
                            setCompany({
                                ...company,
                                locations: [
                                    ...company.locations,
                                    newLocation
                                ]
                            })
                            setData('location_id', newLocation.id)
                        }}
                    />

                    {location && (
                        <EditLocationModal
                            location={location}
                            after={(location) => {
                                setCompany({
                                    ...company,
                                    locations: company.locations.map(l => {
                                        if (l.id == location.id) {
                                            return location
                                        }

                                        return l
                                    })
                                })
                            }}
                        />
                    )}
                </div>
            </div>

            <div className="flex items-end gap-3 mb-3">
                <div className="w-2/3">
                    <InputField
                        label="VIN"
                        id="vin"
                        value={item.vin || ''}
                        onChange={(e) => setData('vin', e.target.value)}
                        type="text"
                    />
                </div>
                <div className="w-1/3 mb-1">
                    <Button
                        onClick={vinLookup}
                    >
                        Look Up
                    </Button>
                </div>
            </div>

            <div className="grid grid-cols-3 gap-3 mb-3">
                <InputField
                    extra=""
                    label="Make"
                    id="make"
                    value={item.make || ''}
                    onChange={(e) => setData('make', e.target.value)}
                    type="text"
                />

                <InputField
                    extra=""
                    label="Model"
                    id="model"
                    value={item.model || ''}
                    onChange={(e) => setData('model', e.target.value)}
                    type="text"
                />

                <InputField
                    extra=""
                    label="Year"
                    id="year"
                    value={item.year || ''}
                    onChange={(e) => setData('year', e.target.value)}
                    type="text"
                />

                <InputField
                    extra=""
                    label="Color"
                    id="color"
                    value={item.color || ''}
                    onChange={(e) => setData('color', e.target.value)}
                    type="text"
                />

                <InputField
                    extra=""
                    label="Mileage"
                    id="mileage"
                    value={item.mileage || ''}
                    onChange={(e) => setData('mileage', e.target.value)}
                    type="text"
                />

                <SelectField
                    extra=""
                    label="Key"
                    id="key"
                    value={item.key || ''}
                    onChange={(e) => setData('key', e.target.value)}
                    options={keyOptions}
                />

                <SelectField
                    extra=""
                    label="Runs"
                    id="runs"
                    value={item.runs || ''}
                    onChange={(e) => setData('runs', e.target.value)}
                    options={runsOptions}
                />

                <SelectField
                    extra=""
                    label="Drives"
                    id="drives"
                    value={item.drives || ''}
                    onChange={(e) => setData('drives', e.target.value)}
                    options={drivesOptions}
                />

                <SelectField
                    extra=""
                    label="Catalytic Converter"
                    id="catalytic_converter"
                    value={item.catalytic_converter || ''}
                    onChange={(e) => setData('catalytic_converter', e.target.value)}
                    options={catalyticOptions}
                />

                <SelectField
                    extra=""
                    label="Airbag Status"
                    id="airbag_status"
                    value={item.airbag_status || ''}
                    onChange={(e) => setData('airbag_status', e.target.value)}
                    options={airbagStatuses}
                />
            </div>

            <TextField
                extra="mb-4"
                label="Description"
                value={item.description || ''}
                onChange={(e) => setData('description', e.target.value)}
                id="description"
                cols="30"
                rows="5"
            />

            <div className="flex w-full justify-end items-center gap-4">
                {hasErrors && (
                    <div className={"text-bold text-red-500"}>
                        Please ensure all required fields are filled in.
                    </div>
                )}

                <button onClick={submitAfterValidating} className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    {item.id ? 'Update Vehicle' : 'Add Vehicle'}
                </button>
            </div>
        </Card>
    )
}

export default ItemForm