import { useState } from 'react'
import CreditCardSelect from './CreditCardSelect'
import SetupForm from './StripeSetupForm'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const CreditCardSelectOrAdd = ({ user, config, cards, formRef, onSelectCard, onCardSubmit }) => {
    const noCards = cards?.length === 0
    const [ showForm, setShowForm ] = useState(noCards)
    const [ stripePromise ] = useState(
        loadStripe(config.stripe_key)
    )

    const stripeOptions = {
        mode: 'setup',
        currency: 'usd'
    }

    const onCardCancel = () => {
        setShowForm(false)
    }

    return (
        <>
            {showForm ? (
                <div className="max-h-[400px] overflow-x-hidden overflow-y-auto">
                    <h2 className="text-xl font-medium leading-6 text-gray-900 mb-2">Add a Card</h2>

                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <SetupForm
                            config={{
                                return_url: window.location.href,
                                user_id: user.id,
                                hide_cancel: noCards,
                                mode: 'payment'
                            }}
                            onCancel={onCardCancel}
                            onSubmit={onCardSubmit}
                            formRef={formRef}
                        />
                    </Elements>
                </div>
            ) : (
                <div>
                    <CreditCardSelect
                        cards={cards}
                        label="Select a Credit Card"
                        onChange={onSelectCard}
                        setShowForm={setShowForm}
                    />
                </div>
            )}
        </>
    )
}

export default CreditCardSelectOrAdd