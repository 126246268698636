import { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { createDeposit } from 'services/auctions'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import CreditCardSelectOrAdd from 'components/payments/CreditCardSelectOrAdd'
import PaymentTypeSelect from 'components/payments/PaymentTypeSelect'
import { FaPlus } from 'react-icons/fa6'
import Label from 'components/fields/Label'
import { InputNumber } from 'primereact/inputnumber'

const AddDepositModal = ({ registration, amount, user, cards, config, then }) => {
    const defaultData = {
        payment_type_id: '',
        payment_type_name: '',
        amount: amount || 0.00
    }
    const notify = useNotificationsDispatch()
    const [ visible, setVisible ] = useState(false)
    const [ data, setData ] = useState(defaultData)
    const formRef = useRef()

    const setValue = (k, v) => {
        setData({
            ...data,
            [k]: v
        })
    }

    const close = () => {
        setVisible(false)
        setData(defaultData)
    }

    const submit = (e) => {
        e.preventDefault()

        if (formRef.current) {
            formRef.current.requestSubmit()
        } else {
            submitDeposit(data)
        }
    }

    const submitDeposit = (data) => {
        createDeposit(registration.id, data, ({ data }) => {
            close()

            notify({
                message: 'Deposit has been added',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Add Deposit
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => close()}>
                Cancel
            </Button>
            <Button onClick={submit}>
                Add
            </Button>
        </div>
    )

    const onSelectCard = (card) => {
        setData({
            ...data,
            card_id: card?.id
        })
    }

    const onCardSubmit = (result) => {
        submitDeposit({
            ...data,
            card_id: result.setupIntent.payment_method
        })
    }

    return (
        <div>
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer}
                    onHide={() => close()}
                    style={{ width: '25rem' }}
                    contentClassName="overflow-visible"
            >
                <PaymentTypeSelect
                    mode="deposit"
                    defaultTypeId={data.payment_type_id}
                    onSelect={(id, name) => {
                        setValue('payment_type_id', id)
                        setValue('payment_type_name', name)

                        if (name !== 'Credit Card') {
                            setValue('card_id', null)
                        }
                    }}
                />

                <div className="mt-4">
                    <Label htmlFor="amount">
                        Amount
                    </Label>
                    <div className="p-inputgroup flex-1 mt-2">
                        <span className="p-inputgroup-addon">$</span>
                        <InputNumber
                            inputId="amount"
                            maxFractionDigits={2}
                            value={data.amount}
                            onChange={(e) => setValue('amount', e.value)}
                        />
                    </div>
                </div>

                {data.payment_type_name === 'Credit Card' && (
                    <div className="mt-4">
                        <CreditCardSelectOrAdd
                            user={user}
                            cards={cards}
                            config={config}
                            formRef={formRef}
                            onSelectCard={onSelectCard}
                            onCardSubmit={onCardSubmit}
                        />
                    </div>
                )}
            </Dialog>

            <Button size="sm" onClick={() => {
                setVisible(true)
            }}>
                <span className="flex gap-2 items-center">
                    <FaPlus className="w-4 h-4" />
                    <span>Add Deposit</span>
                </span>
            </Button>
        </div>
    )
}

export default AddDepositModal