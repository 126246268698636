import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { resume } from 'services/auctions'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const ResumeAuctionModal = ({ auction, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()
    const [ extend, setExtend ] = useState(false)

    const onClick = () => {
        resume(auction.id, {
            extend
        }, ({ data }) => {
            setVisible(false)

            notify({
                message: 'Auction has been resumed.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data.auction)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Resume Auction
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={onClick}>
                Submit
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'resume'}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}>
            <p>
                Are you sure you want to resume this auction?
            </p>

            <label className="flex gap-2 items-center mt-4">
                <input
                    type="checkbox"
                    onClick={(e) => setExtend(e.target.checked)}
                    checked={extend}
                />
                <span>Extend auction &amp; lots by pause time</span>
            </label>
        </Dialog>
    )
}

export default ResumeAuctionModal