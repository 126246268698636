import classNames from 'classnames'

const VerticalMenu = ({ items }) => {
    return (
        <nav className="flex flex-col flex-shrink-0 w-36 mr-4" aria-label="Sidebar">
            <ul role="list" className="-mx-2 space-y-1">
                {items.map((item) => (
                    <li key={item.name}>
                        <a
                            href={item.href}
                            className={classNames(
                                item.current ? 'bg-gray-50 text-slate-600' : 'text-gray-700 hover:text-slate-600 hover:bg-gray-50',
                                'group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold'
                            )}
                        >
                            {item.name}
                            {item.count ? (
                                <span
                                    className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                                    aria-hidden="true"
                                >
                                  {item.count}
                                </span>
                            ) : null}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default VerticalMenu