import { useRef, useState } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import Button from "components/buttons/Button";

const Password = ({ user, setUser, isProfile, submit }) => {
    const password = useRef()
    const confirmPassword = useRef()
    const [ errors, setErrors ] = useState({})

    const setData = (k, v) => {
        setUser({
            ...user,
            [k]: v
        })

        if (password.current.value !== confirmPassword.current.value) {
            confirmPassword.current.setCustomValidity("Confirm password field doesn't match.");
        } else {
            confirmPassword.current.setCustomValidity("");
        }
    }

    const validateAndSubmit = (e) => {
        e.preventDefault();

        if (password.current.form.reportValidity()) {
            submit(() => {
                setErrors({})

                setUser({
                    ...user,
                    current_password: '',
                    password: '',
                    password_confirmation: ''
                })
            }, ({ data }) => {
                if (data.errors) {
                    setErrors(data.errors)
                }
            });
        }
    }

    return (
        <Card extra={"w-full p-4"}>
            {/* Header */}
            <div className="w-full px-2">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Change Password
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Here you can set the user's new password (minimum 8 characters)
                </p>
            </div>
            {/* inputs */}
            <form className="mt-7 grid grid-cols-1 gap-3">
                {/* Require and check if editing own account */}
                {isProfile && (
                    <InputField
                        autoComplete="off"
                        label="Current Password"
                        placeholder="Current Password"
                        id="current_password"
                        type="password"
                        onChange={(e) => setData('current_password', e.target.value)}
                        value={user.current_password}
                        state={errors.current_password ? 'error' : ''}
                        above={errors.current_password ? (
                            <p className="text-red-500 text-xs mt-0.5">
                                {errors.current_password[0]}
                            </p>
                        ) : ''}
                    />
                )}
                <InputField
                    autoComplete="off"
                    ref={password}
                    label="New Password"
                    placeholder="New Password"
                    id="new_password"
                    type="password"
                    onChange={(e) => setData('password', e.target.value)}
                    value={user.password}
                    minLength="8"
                    required={true}
                />
                <InputField
                    autoComplete="off"
                    ref={confirmPassword}
                    label="New Password Confirmation"
                    placeholder="Confirm New Password"
                    id="password_confirmation"
                    type="password"
                    onChange={(e) => setData('password_confirmation', e.target.value)}
                    value={user.password_confirmation}
                    required={true}
                />

                <div className="mt-12 flex w-full justify-end">
                    <Button onClick={validateAndSubmit}>
                        Change Password
                    </Button>
                </div>
            </form>
            {/* full width inputs */}
        </Card>
    );
};

export default Password;
