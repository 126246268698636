import SettingsLayout from './components/SettingsLayout'

const SettingsIndex = () => {
    return (
        <SettingsLayout>

        </SettingsLayout>
    )
}

export default SettingsIndex