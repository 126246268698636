import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { PaymentTypes } from 'services/settings'
import Badge from 'components/dataDisplay/Badge'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const defaultPaymentType = {
    invoice_available: true,
    invoice_default: false,
    invoice_hide: false,
    statement_available: true,
    statement_default: false,
    statement_hide: false,
    deposit_available: true,
    deposit_default: false,
    deposit_hide: false
}

const SettingsPaymentTypes = () => {
    const [ visible, setVisible ] = useState(false)
    const [ dialogTitle, setDialogTitle ] = useState('Create Payment Type')
    const [ paymentTypes, setPaymentTypes ] = useState([])
    const [ paymentType, setPaymentType ] = useState(defaultPaymentType)

    const loadPaymentTypes = () => {
        PaymentTypes.index({}, ({ data }) => {
            setPaymentTypes(data.paymentTypes)
        })
    }

    const deletePaymentType = (t) => {
        PaymentTypes.delete(t.id, () => {
            loadPaymentTypes()
        })
    }

    useEffect(() => {
        loadPaymentTypes()
    }, [])

    const headerElement = (
        <div className="text-lg font-bold">
            {dialogTitle}
        </div>
    )

    const footerContent = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={() => savePaymentType()}>
                Save
            </Button>
        </div>
    )

    const ActionsTemplate = (rowData) => {
        return (
            <div className="flex gap-2 items-center">
                <Button
                    onClick={() => {
                        setPaymentType(rowData)
                        setDialogTitle('Edit Payment Type')
                        setVisible(true)
                    }}>Edit</Button>
                <Button
                    color="red"
                    onClick={() => {
                        if (window.confirm("Are you sure you want to delete?")) {
                            deletePaymentType(rowData)
                        }
                    }}>Delete</Button>
            </div>
        )
    }

    const PaymentTypeTemplate = (pt) => {
        return (
            <div className="flex gap-1 items-center">
                <span>{pt.name}</span>

                {pt.invoice_default ? (
                    <Badge color="gray">Invoice Default</Badge>
                ) : ''}

                {pt.deposit_default ? (
                    <Badge color="gray">Deposit Default</Badge>
                ) : ''}

                {pt.statement_default ? (
                    <Badge color="gray">Statement Default</Badge>
                ) : ''}
            </div>
        )
    }

    const setData = (k, v) => {
        setPaymentType({
            ...paymentType,
            [k]: v
        })
    }
    const savePaymentType = () => {
        const callback = () => {
            setVisible(false)
            setPaymentType(defaultPaymentType)
            loadPaymentTypes()
        }

        if (paymentType.id) {
            PaymentTypes.update(paymentType.id, paymentType, callback)
        } else {
            PaymentTypes.create(paymentType, callback)
        }
    }

    const PaymentTypeCheckbox = ({ k, children }) => {
        return (
            <label className="flex items-center gap-2">
                <input
                    type="checkbox"
                    onChange={(e) => setData(k, e.target.checked)}
                    checked={paymentType[k]}
                />
                <span>{children}</span>
            </label>
        )
    }

    return (
        <SettingsLayout expandedKeys={['finance', 'invoices']}>
            <div className="flex w-full justify-end">
                <Button
                    onClick={() => {
                        setDialogTitle('Create Payment Type')
                        setVisible(true)
                    }}
                >
                    Create
                </Button>
            </div>

            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={headerElement} footer={footerContent}
                    style={{ width: '50rem' }}
                    onHide={() => setVisible(false)}>
                <div className="grid grid-cols-2 gap-2 items-start">
                    <InputField
                        id="name"
                        label="Name"
                        value={paymentType.name || ''}
                        onChange={(e) => setData('name', e.target.value)}
                    />

                    <div className="col-span-2 mt-2 grid grid-cols-3 gap-2 items-center">
                        <div>
                            <div className="text-lg font-bold">Invoices</div>
                            <div className="flex flex-col items-start gap-2">
                                <PaymentTypeCheckbox k="invoice_available">
                                    Available
                                </PaymentTypeCheckbox>
                                <PaymentTypeCheckbox k="invoice_default">
                                    Default
                                </PaymentTypeCheckbox>
                                <PaymentTypeCheckbox k="invoice_hide">
                                    Hide
                                </PaymentTypeCheckbox>
                            </div>
                        </div>
                        <div>
                            <div className="text-lg font-bold">Statements</div>
                            <div className="flex flex-col items-start gap-2">
                                <PaymentTypeCheckbox k="statement_available">
                                    Available
                                </PaymentTypeCheckbox>
                                <PaymentTypeCheckbox k="statement_default">
                                    Default
                                </PaymentTypeCheckbox>
                                <PaymentTypeCheckbox k="statement_hide">
                                    Hide
                                </PaymentTypeCheckbox>
                            </div>
                        </div>
                        <div>
                            <div className="text-lg font-bold">Deposits</div>
                            <div className="flex flex-col items-start gap-2">
                                <PaymentTypeCheckbox k="deposit_available">
                                    Available
                                </PaymentTypeCheckbox>
                                <PaymentTypeCheckbox k="deposit_default">
                                    Default
                                </PaymentTypeCheckbox>
                                <PaymentTypeCheckbox k="deposit_hide">
                                    Hide
                                </PaymentTypeCheckbox>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <DataTable className="text-sm" value={paymentTypes} lazy dataKey="id">
                <Column body={PaymentTypeTemplate} header="Name" />
                <Column body={ActionsTemplate} />
            </DataTable>
        </SettingsLayout>
    )
}

export default SettingsPaymentTypes