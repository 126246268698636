import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from 'services/items'
import { can, useAuth } from 'components/auth/AuthContext'
import ItemLayout from './components/ItemLayout'
import {
    FaBoxesStacked,
    FaCartShopping
} from 'react-icons/fa6'
import { MdGavel } from 'react-icons/md'
import { AuctionStats } from '../auctions/components/AuctionStats'
import Link from 'components/buttons/Link'
import ActionPanel from 'components/panels/ActionPanel'
import DeleteItemModal from './components/modals/DeleteItemModal'

const Dashboard = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ item, setItem ] = useState(null)
    const [ stats, setStats ] = useState([])
    const [ visibleModal, setVisibleModal ] = useState(null)

    const auth = useAuth()
    const canDelete = can(auth, 'delete_inventory')

    useEffect(() => {
        get(id, ({ data }) => {
            setItem(data.item)
        })
    }, [id])

    useEffect(() => {
        if (! item) {
            return
        }

        setStats(
            buildStats()
        )
    }, [item])

    const buildStats = () => {
        return [
            {
                icon: FaBoxesStacked,
                title: 'On Hand',
                value: item.qty
            },
            {
                icon: MdGavel,
                title: 'On Auction',
                value: item.stats.qty_on_auction
            },
            {
                icon: FaCartShopping,
                title: 'Sold',
                value: item.stats.qty_sold
            },
        ]
    }

    if (! item) {
        return
    }

    return (
        <ItemLayout id={id} item={item}>
            <div>
                <AuctionStats stats={stats} />

                <p className="mt-4">
                    <b>Consignor Company:</b> <Link to={`/admin/company/${item.company_id}`}>{item.company?.name}</Link>
                </p>

                {canDelete && item?.editable && (
                    <div className="mt-8">
                        <ActionPanel
                            color="red"
                            title="Delete Item"
                            action="Delete"
                            onClick={() => setVisibleModal('delete')}
                        >
                            Delete this item and everything associated with it, including all lots attached to it.
                        </ActionPanel>
                    </div>
                )}

                <DeleteItemModal
                    item={item}
                    visible={visibleModal}
                    setVisible={setVisibleModal}
                    then={() => {
                        navigate('/admin/inventory')
                    }}
                />
            </div>
        </ItemLayout>
    )
}

export default Dashboard