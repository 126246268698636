import { useEffect, useRef, useState } from 'react'
import Card from 'components/card'
import Button from 'components/buttons/Button'
import SelectField from 'components/fields/SelectField'
import InputField from 'components/fields/InputField'
import TextField from 'components/fields/TextField'
import { get as getAuction, all as allAuctions } from 'services/auctions'
import { keyOptions, runsOptions, drivesOptions, airbagStatuses, catalyticOptions } from 'services/items'
import { BidIncrements, Premiums } from 'services/settings'
import { decode as decodeVin } from 'services/vin'
import Label from 'components/fields/Label'
import { InputNumber } from 'primereact/inputnumber'
import AddLocationModal from '../../companies/components/modals/AddLocationModal'
import EditLocationModal from '../../companies/components/modals/EditLocationModal'
import { can, useAuth } from 'components/auth/AuthContext'

const LotForm = ({ lot, setLot, auction, setAuction, submit, canApproveLots, modified }) => {
    const [ auctions, setAuctions ] = useState([])
    const [ auctionsOptions, setAuctionsOptions ] = useState([])
    const [ locationsOptions, setLocationsOptions ] = useState([])
    const [ location, setLocation ] = useState(null)
    const [ bidIncrements, setBidIncrements ] = useState([])
    const [ premiums, setPremiums ] = useState([])
    const [ errors, setErrors ] = useState({})
    const [ newLot, setNewLot ] = useState({
        item: {}
    })
    const form = useRef()

    const auth = useAuth()
    const canUpdateUnrestricted = can(auth, 'update_auctions_unrestricted')

    useEffect(() => {
        allAuctions({}, ({ data }) => {
            setAuctions(data.auctions)
        })

        BidIncrements.index({}, ({ data }) => {
            setBidIncrements(data.bidIncrements)
        })

        Premiums.index({}, ({ data }) => {
            setPremiums(data.premiums)
        })
    }, [])

    useEffect(() => {
        const updated = {
            item: {}
        }

        modified?.forEach(field => {
            if (field.item) {
                updated.item[field.k] = field.v
            } else {
                updated[field.k] = field.v
            }
        })

        setNewLot(updated)
    }, [modified])

    useEffect(() => {
        setAuctionsOptions(
            auctions.map(auction => {
                return {
                    label: auction.name,
                    value: auction.id
                }
            })
        )
    }, [auctions])

    const setData = (k, v) => {
        setLot({
            ...lot,
            [k]: v
        })
    }

    const setItemData = (k, v) => {
        setData('item', {
            ...lot.item,
            [k]: v
        })
    }

    const setConfig = (k, v) => {
        const config = lot.config || {}

        setLot({
            ...lot,
            config: {
                ...config,
                [k]: v
            }
        })
    }

    const toggleCheckbox = (k) => {
        setConfig(k, ! (lot.config ? lot.config[k] : false))
    }

    const selectAuction = (v) => {
        setData('auction_id', v)

        getAuction(v, ({ data }) => {
            setAuction(data.auction)
        })
    }

    const submitAndAdd = (e) => {
        validateAndSubmit(e, true)
    }

    const validateAndSubmit = (e, add = false) => {
        e.preventDefault();

        if (form.current.reportValidity()) {
            submit(add, () => {
                setErrors({})
            }, ({ data }) => {
                if (data.errors) {
                    setErrors(data.errors)
                    window.scrollTo(0, 0)
                }
            });
        }
    }

    const vinLookup = (e) => {
        e.preventDefault()

        decodeVin(lot.item.vin, ({ data }) => {
            const updated = {
                ...lot.item
            }

            if (data.make) {
                updated.make = data.make
            }

            if (data.model) {
                updated.model = data.model
            }

            if (data.year) {
                updated.year = data.year
            }

            setData('item', updated)
        })
    }

    useEffect(() => {
        if (! auction?.company) {
            setLocationsOptions([])
            return
        }

        setLocationsOptions(
            auction.company.locations?.map(location => {
                return {
                    label: location.name || location.full_address,
                    value: location.id
                }
            })
        )
        setLocationFromId(lot.location_id)
    }, [auction])

    const setLocationFromId = (id) => {
        setLocation(
            id
                ? auction.company.locations.filter(l => l.id == id)[0]
                : null
        )
    }

    return (
        <Card extra={"w-1/2 mt-3 px-6 py-6"}>
            {/* Header */}
            <div className="w-full px-[8px] mb-4">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Lot Details
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Basic information related to the lot
                </p>
            </div>

            <form ref={form}>

            <SelectField
                extra="mb-3"
                label="Auction"
                id="auction_id"
                value={lot.auction_id}
                onChange={(e) => selectAuction(e.target.value)}
                options={auctionsOptions}
                disabled={! lot.editable}
            />

            <div className="mb-4">
                <SelectField
                    extra="mb-3"
                    label="Location"
                    id="location_id"
                    value={lot.location_id || ''}
                    onChange={(e) => {
                        setData('location_id', e.target.value)
                        setLocationFromId(e.target.value)
                    }}
                    options={locationsOptions}
                />

                <div className="flex items-center gap-2">
                    <AddLocationModal
                        company={auction?.company}
                        after={(newLocation) => {
                            setAuction({
                                ...auction,
                                company: {
                                    ...auction.company,
                                    locations: [
                                        ...auction.company.locations,
                                        newLocation
                                    ]
                                }
                            })
                            setData('location_id', newLocation.id)
                        }}
                    />

                    {location && (
                        <EditLocationModal
                            location={location}
                            after={(location) => {
                                setAuction({
                                    ...auction,
                                    company: {
                                        ...auction.company,
                                        locations: auction.company.locations?.map(l => {
                                            if (l.id == location.id) {
                                                return location
                                            }

                                            return l
                                        })
                                    }
                                })
                            }}
                        />
                    )}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
                <InputField
                    extra="mb-3"
                    label="Lot Number"
                    id="number"
                    value={lot.number || ''}
                    onChange={(e) => setData('number', e.target.value)}
                    state={errors.number ? 'error' : ''}
                    above={errors.number ? (
                        <p className="text-red-500 text-xs mt-0.5">
                            {errors.number[0]}
                        </p>
                    ) : ''}
                    below={newLot.number && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.number}
                        </p>
                    )}
                    type="text"
                />

                <InputField
                    extra="mb-3"
                    label="Quantity"
                    id="qty"
                    value={lot.qty}
                    onChange={(e) => setData('qty', e.target.value)}
                    type="number"
                    below={newLot.qty && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.qty}
                        </p>
                    )}
                />
            </div>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 mb-3">
                {canUpdateUnrestricted && (
                    <div>
                        <Label htmlFor="starting_bid">
                            Starting Bid
                        </Label>
                        <div className="p-inputgroup flex-1 mt-2">
                            <span className="p-inputgroup-addon">$</span>
                            <InputNumber
                                inputId="starting_bid"
                                maxFractionDigits={2}
                                value={lot.starting_bid || ''}
                                onChange={(e) => setData('starting_bid', e.value)}
                            />
                        </div>
                        {newLot.starting_bid && (
                            <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                                NEW: {newLot.starting_bid}
                            </p>
                        )}
                    </div>
                )}
                <div>
                    <div className="flex gap-2">
                        <Label htmlFor="reserve">
                            Reserve
                        </Label>
                        <div className="text-xs ml-2 mt-0.5">
                            <label className="flex gap-1 items-center" title="Show Reserve Status">
                                <input type="checkbox"
                                       checked={lot.config?.show_reserve_status || false}
                                       onChange={() => toggleCheckbox('show_reserve_status')}
                                />
                                <span>Show Reserve Status</span>
                            </label>
                        </div>
                    </div>
                    <div className="p-inputgroup flex-1 mt-2">
                        <span className="p-inputgroup-addon">$</span>
                        <InputNumber
                            inputId="reserve"
                            maxFractionDigits={2}
                            value={lot.reserve || ''}
                            onChange={(e) => setData('reserve', e.value)}
                        />
                    </div>
                    {newLot.reserve && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.reserve}
                        </p>
                    )}
                </div>
            </div>

            <div className="grid grid-cols-3 gap-3 mb-3">
                <InputField
                    id="sale_order"
                    name="sale_order"
                    label="Sale Order"
                    value={lot.config?.sale_order || ''}
                    onChange={(e) => setConfig('sale_order', e.target.value)}
                />

                <SelectField
                    id="bid_increment"
                    name="bid_increment"
                    label="Bid Increment"
                    value={lot.config?.bid_increment || ''}
                    onChange={(e) => setConfig('bid_increment', e.target.value)}
                    options={bidIncrements.map(bidIncrement => {
                        return {
                            value: bidIncrement.id,
                            label: bidIncrement.name
                        }
                    })}
                />

                <SelectField
                    id="premium"
                    name="premium"
                    label="Buyer's Premium"
                    value={lot.config?.premium || ''}
                    onChange={(e) => setConfig('premium', e.target.value)}
                    options={premiums.map(premium => {
                        return {
                            value: premium.id,
                            label: premium.name
                        }
                    })}
                />
            </div>

            <div className="flex items-start gap-3 mb-3">
                <div className="w-2/3">
                    <InputField
                        label="VIN"
                        id="vin"
                        value={lot.item.vin || ''}
                        onChange={(e) => setItemData('vin', e.target.value)}
                        type="text"
                        below={newLot.item.vin && (
                            <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                                NEW: {newLot.item.vin}
                            </p>
                        )}
                    />
                </div>
                <div className="w-1/3 mt-8">
                    <Button
                        onClick={vinLookup}
                    >
                        Look Up
                    </Button>
                </div>
            </div>

            <div className="grid grid-cols-3 gap-3 mb-3">
                <InputField
                    extra=""
                    label="Make"
                    id="make"
                    value={lot.item.make || ''}
                    onChange={(e) => setItemData('make', e.target.value)}
                    type="text"
                    below={newLot.item.make && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.make}
                        </p>
                    )}
                />

                <InputField
                    extra=""
                    label="Model"
                    id="model"
                    value={lot.item.model || ''}
                    onChange={(e) => setItemData('model', e.target.value)}
                    type="text"
                    below={newLot.item.model && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.model}
                        </p>
                    )}
                />

                <InputField
                    extra=""
                    label="Year"
                    id="year"
                    value={lot.item.year || ''}
                    onChange={(e) => setItemData('year', e.target.value)}
                    type="text"
                    below={newLot.item.year && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.year}
                        </p>
                    )}
                />

                <InputField
                    extra=""
                    label="Color"
                    id="color"
                    value={lot.item.color || ''}
                    onChange={(e) => setItemData('color', e.target.value)}
                    type="text"
                    below={newLot.item.color && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.color}
                        </p>
                    )}
                />

                <InputField
                    extra=""
                    label="Mileage"
                    id="mileage"
                    value={lot.item.mileage || ''}
                    onChange={(e) => setItemData('mileage', e.target.value)}
                    type="text"
                    below={newLot.item.mileage && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.mileage}
                        </p>
                    )}
                />

                <SelectField
                    extra=""
                    label="Key"
                    id="key"
                    value={lot.item.key}
                    onChange={(e) => setItemData('key', e.target.value)}
                    state={errors.key ? 'error' : ''}
                    above={errors.key ? (
                        <p className="text-red-500 text-xs mt-0.5">
                            {errors.key[0]}
                        </p>
                    ) : ''}
                    below={newLot.item.key && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.key}
                        </p>
                    )}
                    options={keyOptions}
                    required
                />

                <SelectField
                    extra=""
                    label="Runs"
                    id="runs"
                    value={lot.item.runs}
                    onChange={(e) => setItemData('runs', e.target.value)}
                    options={runsOptions}
                    below={newLot.item.runs && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.runs}
                        </p>
                    )}
                />

                <SelectField
                    extra=""
                    label="Drives"
                    id="drives"
                    value={lot.item.drives}
                    onChange={(e) => setItemData('drives', e.target.value)}
                    options={drivesOptions}
                    below={newLot.item.drives && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.drives}
                        </p>
                    )}
                />

                <SelectField
                    extra=""
                    label="Catalytic Converter"
                    id="catalytic_converter"
                    value={lot.item.catalytic_converter}
                    onChange={(e) => setItemData('catalytic_converter', e.target.value)}
                    options={catalyticOptions}
                    below={newLot.item.catalytic_converter && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.catalytic_converter}
                        </p>
                    )}
                />

                <SelectField
                    extra=""
                    label="Airbag Status"
                    id="airbag_status"
                    value={lot.item.airbag_status}
                    onChange={(e) => setItemData('airbag_status', e.target.value)}
                    options={airbagStatuses}
                    below={newLot.item.airbag_status && (
                        <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                            NEW: {newLot.item.airbag_status}
                        </p>
                    )}
                />
            </div>

            {/*<div className="grid grid-cols-1 gap-3 md:grid-cols-2">*/}
            {/*    <InputField*/}
            {/*        extra="mb-3"*/}
            {/*        label="Start Date"*/}
            {/*        value={formatDate(lot.starts_at)}*/}
            {/*        onChange={(e) => setData('starts_at', e.target.value)}*/}
            {/*        id="starts_at"*/}
            {/*        type="date"*/}
            {/*    />*/}
            {/*    <InputField*/}
            {/*        extra="mb-3"*/}
            {/*        label="End Date"*/}
            {/*        value={formatDate(lot.ends_at)}*/}
            {/*        onChange={(e) => setData('ends_at', e.target.value)}*/}
            {/*        placeholder=""*/}
            {/*        id="ends_at"*/}
            {/*        type="date"*/}
            {/*    />*/}
            {/*</div>*/}

            <div className="flex gap-4 items-center">
                <label
                    className="flex gap-2 items-center mb-4"
                    title="Whether or not this lot is featured."
                >
                    <input
                        type="checkbox"
                        onChange={(e) => setData('is_featured', e.target.checked)}
                        checked={lot.is_featured}
                    />
                    <span>Featured</span>
                </label>

                <label
                    className="flex gap-2 items-center mb-4"
                    title="Whether or not this lot is featured on the homepage."
                >
                    <input
                        type="checkbox"
                        onChange={(e) => setData('is_featured_home', e.target.checked)}
                        checked={lot.is_featured_home}
                    />
                    <span>Featured On Homepage</span>
                </label>
            </div>

            <TextField
                extra="mb-4"
                label="Description"
                value={lot.description || ''}
                onChange={(e) => setData('description', e.target.value)}
                id="description"
                cols="30"
                rows="5"
                below={newLot.description && (
                    <p className="bg-yellow-50 text-yellow-700 text-xs mt-0.5 px-2 py-1">
                        NEW: {newLot.description}
                    </p>
                )}
            />

            <div className="flex w-full justify-end items-center gap-4">
                <Button onClick={submitAndAdd} color="outline">
                    Save &amp; Add Another Lot
                </Button>

                <Button onClick={validateAndSubmit}>
                    {lot.id ? (lot.editable ? 'Update Lot' : 'Request Changes') : 'Add Lot'}
                </Button>
            </div>

            </form>
        </Card>
    )
}

export default LotForm