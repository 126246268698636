import LotLayout from './components/LotLayout'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, watches as getWatches } from 'services/lots'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const LotWatches = () => {
    const { id } = useParams()
    const [ lot, setLot ] = useState(null)
    const [ watches, setWatches ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()

    useEffect(() => {
        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })
    }, [searchParams])

    useEffect(() => {
        get(id, ({ data }) => {
            setLot(data.lot)
        })
    }, [id])

    useEffect(() => {
        getWatches(id, {
            by: sort.by,
            dir: sort.dir
        }, ({ data }) => {
            setWatches(data.watches)
        })
    }, [id, sort])

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    if (! lot) {
        return
    }

    return (
        <LotLayout id={id} lot={lot} setLot={setLot}>
            <DataTable className="text-sm" value={watches} lazy dataKey="id"
                       onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                       rowClassName="cursor-pointer"
                       onRowClick={(e) => {
                           navigate(`/admin/user/${e.data.id}/edit`)
                       }}
                       tableStyle={{ minWidth: '75rem' }}>
                <Column field="watcher.id" header="User ID" sortable />
                <Column field="watcher.first_name" header="First Name" sortable />
                <Column field="watcher.last_name" header="Last Name" sortable />
                <Column field="dates.created" header="Watched At" sortable sortField="watches.created_at" />
            </DataTable>
        </LotLayout>
    )
}

export default LotWatches