import AuctionLayout from './components/AuctionLayout'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, invoices } from 'services/auctions'
import InvoicesTable from '../invoices/components/InvoicesTable'

const Invoices = () => {
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
        })
    }, [])

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
            <InvoicesTable loadInvoices={(params, then) => invoices(id, params, then)} />
        </AuctionLayout>
    )
}

export default Invoices