import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { removeDeposit } from 'services/auctions'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import { FaTrash } from 'react-icons/fa6'

const RemoveDepositModal = ({ deposit, then }) => {
    const notify = useNotificationsDispatch()
    const [ visible, setVisible ] = useState(false)

    const isCreditCard = deposit.transaction.payment_type?.name === 'Credit Card'

    const submit = () => {
        removeDeposit(deposit.id, () => {
            setVisible(false)

            notify({
                message: 'Deposit has been removed',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(deposit)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            {isCreditCard ? 'Remove & Refund Deposit' : 'Remove Deposit'}
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={submit}>
                {isCreditCard ? 'Remove & Refund Deposit' : 'Remove Deposit'}
            </Button>
        </div>
    )

    return (
        <div>
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer}
                    onHide={() => setVisible(false)}
                    style={{ width: '25rem' }}
            >
                Are you sure you want to delete this deposit?
            </Dialog>

            <Button size="sm" color="red" invisible={deposit.deleted_at} onClick={() => {
                setVisible(true)
            }}>
                <span className="flex gap-2 items-center">
                    <FaTrash className="w-4 h-4" />
                    <span>Remove</span>
                </span>
            </Button>
        </div>
    )
}

export default RemoveDepositModal