import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { can, useAuth } from 'components/auth/AuthContext'
import { index, getRoles, allNotes } from 'services/users'
import { all as getClients } from 'services/clients'
import { all as getCompanies } from 'services/companies'
import Btn from 'components/buttons/Button'
import SplitButton from 'components/buttons/SplitButton'
import IconAlert from 'components/alerts/IconAlert'
import SelectField from 'components/fields/SelectField'
import { FaEllipsisVertical, FaMagnifyingGlass, FaPencil, FaPlus, FaUser } from 'react-icons/fa6'
import { MdBusiness, MdBusinessCenter } from 'react-icons/md'
import { FilterMatchMode } from 'primereact/api'
import { debounce } from 'lodash'
import { InputText } from 'primereact/inputtext'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ImportUsersModal from './components/modals/ImportUsersModal'
import FilterType from 'components/filters/FilterType'
import AdditionalFiltersDisplay from 'components/filters/AdditionalFiltersDisplay'
import AdditionalFiltersModal from 'components/filters/AdditionalFiltersModal'
import { FilterIcon } from 'primereact/icons/filter'

const UsersIndex = ({ client, company }) => {
    const auth = useAuth()
    const canCreate = can(auth, 'create_users')
    const canViewClients = can(auth, 'view_clients')
    const canUpdate = can(auth, 'update_users')
    const canImport = can(auth, 'import_users')
    const canExport = can(auth, 'export_users')

    const prefiltered = !!(client || company)
    const navigate = useNavigate()
    const [ users, setUsers ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const [ filters, setFilters ] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.first_name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'users.last_name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'users.email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.username': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.role': { value: null, matchMode: FilterMatchMode.EQUALS },
        'client_id': { value: client?.id, matchMode: FilterMatchMode.EQUALS },
        'company_id': { value: company?.id, matchMode: FilterMatchMode.EQUALS }
    })
    const [ additionalFilters, setAdditionalFilters ] = useState({
        'is_banned': { label: 'Banned', value: null, type: FilterType.BOOLEAN },
        'city': { label: 'City', value: null, type: FilterType.STRING },
        'state': { label: 'State', value: null, type: FilterType.STRING },
        'role_id': { label: 'Role', value: null, type: FilterType.SELECT, options: [] }
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')
    const [ roles, setRoles ] = useState([])
    const [ clients, setClients ] = useState([])
    const [ companies, setCompanies ] = useState([])
    const [ showImportUsersModal, setShowImportUsersModal ] = useState(false)
    const [ showAdditionalFiltersModal, setShowAdditionalFiltersModal ] = useState(false)

    const updateUsers = () => {
        index({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify({
                ...filters,
                ...additionalFilters
            })
        }, ({ data }) => {
            setUsers(data.users)
        })
    }

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        updateUsers()
    }, [page, sort, filters, additionalFilters])

    useEffect(() => {
        getRoles({}, ({ data }) => {
            setRoles(data.roles)
            setAdditionalFilters({
                ...additionalFilters,
                role_id: {
                    ...additionalFilters.role_id,
                    options: data.roles.map(role => {
                        return {
                            label: role.name,
                            value: role.id
                        }
                    })
                }
            })
        })

        if (! prefiltered) {
            getClients({}, ({ data }) => {
                setClients(data.clients)
            })

            getCompanies({}, ({ data }) => {
                setCompanies(data.companies)
            })
        }
    }, [])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const onCustomFilterChange = (e, name) => {
        const value = e.target.value;
        let _filters = { ... filters };

        _filters[name].value = value;

        onFilter({
            filters: _filters
        });
    }

    const roleRowFilterTemplate = (options) => {
        return (
            <SelectField
                options={roles.map(role => {
                    return {
                        label: role.name,
                        value: role.id
                    }
                })}
                value={options.value || ''}
                onChange={(e) => options.filterApplyCallback(e.target.value)}
            />
        )
    }

    const role = (id) => {
        return roles.filter(role => role.id === id)[0]
    }

    const roleBodyTemplate = (user) => {
        return (
            <div className="flex flex-col gap-2">
                {user.role_id && (
                    <div>
                        {role(user.role_id)?.name}
                    </div>
                )}

                {user.associations.length > 0 && (
                    <div className="flex flex-col gap-2">
                        {user.associations.map((association) => {
                            let href = '#'

                            if (association.associated_type.indexOf('Company') >= 0) {
                                href = '/admin/company/' + association.associated_id
                            } else if (association.associated_type.indexOf('Client') >= 0) {
                                href = '/admin/client/' + association.associated_id
                            }

                            if (! association.associated) {
                                return ''
                            }

                            return (
                                <div key={association.id}>
                                    {role(association.role_id)?.name} &ndash; <a href={href}>{association.associated.name}</a>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>

                {! prefiltered && (
                    <>
                        {canViewClients && (
                            <div>
                                <SelectField
                                    options={clients.map(client => {
                                        return {
                                            label: client.name,
                                            value: client.id
                                        }
                                    })}
                                    value={filters.client_id.value}
                                    onChange={(e) => {
                                        return onCustomFilterChange(e, 'client_id')
                                    }}
                                    placeholder="Filter by client"
                                />
                            </div>
                        )}

                        <div>
                            <SelectField
                                options={companies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.id
                                    }
                                })}
                                value={filters.company_id.value}
                                onChange={(e) => {
                                    return onCustomFilterChange(e, 'company_id')
                                }}
                                placeholder="Filter by company"
                            />
                        </div>
                    </>
                )}

                <div>
                    <Button
                        className="flex items-center gap-2"
                        onClick={() => setShowAdditionalFiltersModal(true)}
                    >
                        <FilterIcon />
                    </Button>
                </div>

                <AdditionalFiltersDisplay
                    filters={additionalFilters}
                    setFilters={setAdditionalFilters}
                />
            </div>
        )
    }

    const header = renderHeader()

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null);
        const items = [
            canUpdate ? {
                label: 'Edit',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/user/${rowData.id}/edit`)
                }
            } : {
                label: 'View',
                icon: <FaUser className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/user/${rowData.id}`)
                }
            },
        ]

        if (rowData.associations.length > 0) {
            rowData.associations.forEach(association => {
                let label, icon, url;

                if (association.associated_type.indexOf('Company') >= 0) {
                    label = 'Go to company';
                    icon = <MdBusiness className="w-5 h-5 pr-2" />;
                    url = `/admin/company/${association.associated_id}`;
                } else if (association.associated_type.indexOf('Client') >= 0) {
                    label = 'Go to client';
                    icon = <MdBusinessCenter className="w-5 h-5 pr-2" />;
                    url = `/admin/client/${association.associated_id}`;
                } else {
                    return;
                }

                items.push({
                    label,
                    icon,
                    command: () => {
                        navigate(url)
                    }
                })
            });
        }

        return (
            <>
                <Menu model={items} popup ref={menu} id="popup_menu_right" popupAlignment="right" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} className="mr-2" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
            </>
        )
    }

    const UserAlertsTemplate = (user) => {
        const notes = allNotes(user)

        return (
            <div className="flex gap-1 items-center">
                {notes.map(note => (
                    <IconAlert
                        key={note.id}
                        note={note}
                    />
                ))}
            </div>
        )
    }

    return (
        <div className="mt-3">
            {canImport ? (
                <div className="pt-3 mb-3 flex justify-end">
                    <SplitButton
                        items={[
                            { name: 'Import Users', onClick: () => {
                                setShowImportUsersModal(true)
                            } },
                            canExport && { name: 'Export Users', onClick: () => {
                                const params = new URLSearchParams({
                                    by: sort.by || 'id',
                                    dir: sort.dir,
                                    filters: JSON.stringify({
                                        ...filters,
                                        ...additionalFilters
                                    })
                                })
                                const href = `${process.env.REACT_APP_BASE_URL}/admin/users/export?` + params.toString()
                                window.open(href, '_blank')
                            } }
                        ]}
                        url="/admin/users/create"
                    >
                    <span className="flex gap-2 items-center">
                        <FaPlus className="w-5 h-5" />
                        <span>New User</span>
                    </span>
                    </SplitButton>
                </div>
            ) : canCreate && (
                <div className="pt-3 mb-3 flex justify-end">
                    <Btn href="/admin/users/create">
                        <span className="flex gap-2 items-center">
                            <FaPlus className="w-5 h-5" />
                            <span>New User</span>
                        </span>
                    </Btn>
                </div>
            )}

            {users && (
                <DataTable className="text-sm" value={users.data} lazy dataKey="id" paginator
                           filters={filters} filterDisplay="menu" onFilter={onFilter} globalFilterFields={['users.first_name', 'users.last_name', 'users.email', 'users.username']}
                           first={users.from - 1} rows={users.per_page} totalRecords={users.total} onPage={onPage}
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           rowClassName="cursor-pointer"
                           onRowClick={(e) => {
                               navigate(`/admin/user/${e.data.id}`)
                           }}
                           header={header}>
                    <Column header="Alerts" body={UserAlertsTemplate} />
                    <Column field="first_name" sortField="users.first_name" filterField="users.first_name" header="First Name" filter sortable />
                    <Column field="last_name" sortField="users.last_name" filterField="users.last_name" header="Last Name" filter sortable />
                    <Column field="email" sortField="users.email" filterField="users.email" header="Email" filter sortable />
                    <Column field="username" sortField="users.username" filterField="users.username" header="Username" filter sortable />
                    <Column field="role" sortField="users.role" filterField="users.role" header="Role" filter filterElement={roleRowFilterTemplate} body={roleBodyTemplate} />
                    <Column body={MenuBodyTemplate}></Column>
                </DataTable>
            )}

            <ImportUsersModal
                visible={showImportUsersModal}
                setVisible={setShowImportUsersModal}
                afterImport={() => {
                    updateUsers()
                }}
            />

            <AdditionalFiltersModal
                visible={showAdditionalFiltersModal}
                setVisible={setShowAdditionalFiltersModal}
                filters={additionalFilters}
                setFilters={setAdditionalFilters}
            />
        </div>
    )
}

export default UsersIndex