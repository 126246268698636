import Button from 'components/buttons/Button'
import SelectField from 'components/fields/SelectField'

const Association = ({ association, setAssociation, removeAssociation, roles, clients, companies }) => {
    const setData = (k, v) => {
        setAssociation({
            ...association,
            [k]: v
        })
    }

    const isCompanyType = association.associated_type === 'App\\Models\\Company'
    const filteredRoles = isCompanyType ? roles.filter(role => {
        return role.name.toLowerCase().indexOf('company') >= 0
    }) : roles.filter(role => {
        return role.name.toLowerCase().indexOf('client') >= 0
    })

    return (
        <div className="flex gap-4 items-end">
            <div className="w-1/2">
                {isCompanyType ? (
                    <SelectField
                        label="Company"
                        value={association.associated_id || ''}
                        onChange={(e) => setData('associated_id', e.target.value)}
                        options={companies.map(company => {
                            return {
                                label: company.name,
                                value: company.id
                            }
                        })}
                    />
                ) : (
                    <SelectField
                        label="Client"
                        value={association.associated_id || ''}
                        onChange={(e) => setData('associated_id', e.target.value)}
                        options={clients.map(client => {
                            return {
                                label: client.name,
                                value: client.id
                            }
                        })}
                    />
                )}
            </div>

            <div className="w-[200px]">
                <SelectField
                    label="Role"
                    value={association.role_id || ''}
                    onChange={(e) => setData('role_id', e.target.value)}
                    options={filteredRoles.map(role => {
                        return {
                            label: role.name,
                            value: role.id
                        }
                    })}
                />
            </div>

            <div className="pb-3">
                <Button
                    color="red"
                    size="sm"
                    onClick={(e) => removeAssociation(e, association)}
                >
                    Remove
                </Button>
            </div>
        </div>
    )
}

export default Association