import { FilterMatchMode } from 'primereact/api'

export const BooleanFilterModes = [
    {
        value: FilterMatchMode.EQUALS,
        label: 'Is'
    },
    {
        value: FilterMatchMode.NOT_EQUALS,
        label: 'Is Not'
    },
]

export const FilterModes = [
    {
        value: FilterMatchMode.STARTS_WITH,
        label: 'Starts with'
    },
    {
        value: FilterMatchMode.CONTAINS,
        label: 'Contains'
    },
    {
        value: FilterMatchMode.NOT_CONTAINS,
        label: 'Does not contain'
    },
    {
        value: FilterMatchMode.ENDS_WITH,
        label: 'Ends with'
    },
    {
        value: FilterMatchMode.EQUALS,
        label: 'Equals'
    },
    {
        value: FilterMatchMode.NOT_EQUALS,
        label: 'Does not equal'
    }
]

export const SelectFilterModes = [
    {
        value: FilterMatchMode.EQUALS,
        label: 'Equals'
    },
    {
        value: FilterMatchMode.NOT_EQUALS,
        label: 'Does not equal'
    },
]