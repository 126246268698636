import TwoColumnMultiSelect from 'components/fields/TwoColumnMultiSelect'

const Fields = ({ options, selected, setSelected }) => {
    return (
        <TwoColumnMultiSelect
            value={selected}
            onChange={(value) => setSelected(value)}
            options={options}
            label="Available Fields"
            selectedLabel="Selected Fields"
            deselectTitle="Deselect Fields"
            selectTitle="Select Fields"
        />
    )
}

export default Fields