import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'services/companies'
import CompanyLayout from './components/CompanyLayout'
import StatementsTable from '../statements/components/StatementsTable'
import { index } from 'services/statements'

const CompanyUsers = () => {
    const { id } = useParams()
    const [ company, setCompany ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            setCompany(data.company)
        })
    }, [])

    if (! company) {
        return
    }

    return (
        <CompanyLayout id={id} company={company}>
            <div className="w-full">
                <StatementsTable
                    loadStatements={(params, then) => {
                        return index({
                            ...params,
                            company_id: id
                        }, then)
                    }}
                />
            </div>
        </CompanyLayout>
    )
}

export default CompanyUsers