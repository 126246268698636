import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { deleteFolder } from 'services/folders'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const DeleteFolderModal = ({ folder, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()

    const onClick = () => {
        deleteFolder(folder.id, () => {
            setVisible(false)

            notify({
                message: 'Folder has been deleted.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(folder)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Delete Folder
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button color="red" onClick={onClick}>
                Delete
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'delete'}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}
        >
            <p>Are you sure you want to delete this folder?</p>
        </Dialog>
    )
}

export default DeleteFolderModal