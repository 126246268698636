import ReportsLayout from './components/ReportsLayout'

const ReportsIndex = () => {
    return (
        <ReportsLayout showFilters={false}>

        </ReportsLayout>
    )
}

export default ReportsIndex