import SettingsLayout from '../components/SettingsLayout'
import { useEffect, useState } from 'react'
import { index, create, update, deleteExpense } from 'services/expensesgroups'
import Button from 'components/buttons/Button'
import InputField from 'components/fields/InputField'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const defaultExpenseGroup = {
    calculate_subtotal: false,
    from_event: false,
    show_on_invoices: false,
    show_on_statements: false,
    show_on_reports: false
}

const SettingsExpenses = () => {
    const [ visible, setVisible ] = useState(false)
    const [ dialogTitle, setDialogTitle ] = useState('Create Expense')
    const [ expenseGroups, setExpenseGroups ] = useState([])
    const [ expenseGroup, setExpenseGroup ] = useState(defaultExpenseGroup)

    const loadExpenseGroups = () => {
        index({}, ({ data }) => {
            setExpenseGroups(data.expenses_groups)
        })
    }

    const deleteExpenseGroup = (p) => {
        deleteExpense(p.id, () => {
            loadExpenseGroups()
        })
    }

    useEffect(() => {
        loadExpenseGroups()
    }, [])

    const headerElement = (
        <div className="text-lg font-bold">
            {dialogTitle}
        </div>
    )

    const footerContent = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={() => saveExpenseGroup()}>
                Save
            </Button>
        </div>
    )

    const ActionsTemplate = (rowData) => {
        return (
            <div className="flex gap-2 items-center">
                <Button
                    onClick={() => {
                        setExpenseGroup(rowData)
                        setDialogTitle('Edit Expense')
                        setVisible(true)
                    }}
                >Edit</Button>
                <Button
                    color="red"
                    onClick={() => {
                        if (window.confirm("Are you sure you want to delete?")) {
                            deleteExpenseGroup(rowData)
                        }
                    }}>Delete</Button>
            </div>
        )
    }

    const BooleanTemplate = (value) => {
        return <div className="font-bold text-center"> {(value ? <span class="text-green-500">Yes</span> : <span class="text-red-500">No</span>)} </div>
    }

    const setData = (k, v) => {
        setExpenseGroup({
            ...expenseGroup,
            [k]: v
        })
    }

    const setConfig = (k, v) => {
        const config = expenseGroup.config || {}

        setExpenseGroup({
            ...expenseGroup,
            config: {
                ...config,
                [k]: v
            }
        })
    }

    const saveExpenseGroup = () => {
        const callback = () => {
            setVisible(false)
            setExpenseGroup(defaultExpenseGroup)
            loadExpenseGroups()
        }

        if (expenseGroup.id) {
            update(expenseGroup.id, expenseGroup, callback)
        } else {
            create(expenseGroup, callback)
        }
    }

    return (
        <SettingsLayout expandedKeys={['finance', 'invoices']}>
            <div className="flex w-full justify-end">
                <Button
                    onClick={() => {
                        setDialogTitle('Create Expense')
                        setVisible(true)
                    }}
                >
                    Create
                </Button>
            </div>

            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={headerElement} footer={footerContent}
                    style={{ width: '50rem' }}
                    onHide={() => setVisible(false)}>
                <div className="grid grid-cols-2 gap-2 items-start">
                    <InputField
                        id="group_name"
                        label="Group Name"
                        value={expenseGroup.group_name || ''}
                        onChange={(e) => setData('group_name', e.target.value)}
                    />

                    <InputField
                        id="list_name"
                        label="List Name"
                        value={expenseGroup.list_name || ''}
                        onChange={(e) => setData('list_name', e.target.value)}
                    />

                    <div className="col-span-2 mt-4">
                        <div className="space-y-4">

                            <label className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    checked={expenseGroup.calculate_subtotal || false}
                                    onChange={(e) => setData('calculate_subtotal', e.target.checked)}
                                />
                                <span>Calculate Subtotal</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    checked={expenseGroup.from_event || false}
                                    onChange={(e) => setData('from_event', e.target.checked)}
                                />
                                <span>From Event</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    checked={expenseGroup.show_on_invoices || false}
                                    onChange={(e) => setData('show_on_invoices', e.target.checked)}
                                />
                                <span>Show on Invoices</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    checked={expenseGroup.show_on_statements || false}
                                    onChange={(e) => setData('show_on_statements', e.target.checked)}
                                />
                                <span>Show on Statements</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    checked={expenseGroup.show_on_reports || false}
                                    onChange={(e) => setData('show_on_reports', e.target.checked)}
                                />
                                <span>Show on Reports</span>
                            </label>

                            
                        </div>
                    </div>
                    
                </div>
            </Dialog>

            <DataTable className="text-sm" value={expenseGroups} lazy dataKey="id">
                <Column field="group_name" header="Group Name" />
                <Column field="list_name" header="List Name" />
                <Column field="calculate_subtotal" header="Calculate Subtotal" align='center' body={ rowData => BooleanTemplate(rowData.calculate_subtotal) } />
                <Column field="from_event" header="From Event" align='center' body={ rowData => BooleanTemplate(rowData.from_event) } />
                <Column field="show_on_invoices" header="Show on Invoices" align='center' body={ rowData => BooleanTemplate(rowData.show_on_invoices) } />
                <Column field="show_on_statements" header="Show on Statements" align='center' body={ rowData => BooleanTemplate(rowData.show_on_statements) } />
                <Column field="show_on_reports" header="Show on Reports" align='center' body={ rowData => BooleanTemplate(rowData.show_on_reports) } />
                <Column body={ActionsTemplate} />
            </DataTable>
        </SettingsLayout>
    )
}

export default SettingsExpenses