import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/companies', {
        params
    }).then(then)
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/company/' + id)
        .then(then)
}

export const all = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/companies/all', {
        params
    }).then(then)
}

export const create = (data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/companies/create', data)
        .then(then)
        .catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const update = (id, data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/company/' + id + '/update', data)
        .then(then)
        .catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const deleteCompany = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/company/' + id + '/delete')
        .then(then).catch(response => {
            alert('Failed to delete company. Please try again')
        })
}