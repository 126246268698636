import { useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox as BaseCombobox } from '@headlessui/react'
import classNames from 'classnames'

const Combobox = ({ options, defaultValue, onChange }) => {
    const [query, setQuery] = useState('')
    const [selectedOption, setSelectedOption] = useState(options.filter((option) => option.value === defaultValue)[0])

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) => {
                return option.name.toLowerCase().includes(query.toLowerCase())
            })

    return (
        <BaseCombobox as="div" value={selectedOption} onChange={(option) => {
            onChange(option)
            setSelectedOption(option)
            setQuery('')
        }}>
            <div className="relative mt-2">
                <BaseCombobox.Input
                    className={classNames(
                        "w-full rounded-md py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6",
                        selectedOption?.classes ? selectedOption?.classes : 'border-0 bg-white text-gray-900'
                    )}
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(option) => option?.name}
                />
                <BaseCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </BaseCombobox.Button>

                {filteredOptions.length > 0 && (
                    <BaseCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredOptions.map((option) => (
                            <BaseCombobox.Option
                                key={option.value}
                                value={option}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        option.classes ? option.classes : (
                                            active ? 'bg-slate-600 text-white' : 'text-gray-900'
                                        )
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>{option.name}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-slate-600'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </BaseCombobox.Option>
                        ))}
                    </BaseCombobox.Options>
                )}
            </div>
        </BaseCombobox>
    )
}

export default Combobox