import ReportsLayout from '../components/ReportsLayout'

const LotsSoldReport = () => {
    return (
        <ReportsLayout
            report="lots-sold"
            showAuctionFilter={true}
            showOnlySoldLotsFilter={true}
            defaultFields={[
                'auction',
                'start_date',
                'end_date',
                'invoice_date',
                'status',
                'sold_price',
                'reserve',
                'number',
                'make',
                'model',
                'year',
                'vin',
                'key',
                'runs',
                'drives'
            ]}
        />
    )
}

export default LotsSoldReport