import { useEffect, useState } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { index as searchUsers } from 'services/users'

const UsersAutocomplete = ({ id, onSelect }) => {
    const [ query, setQuery ] = useState('')
    const [ users, setUsers ] = useState([])
    const [ selectedUser, setSelectedUser ] = useState(null)

    useEffect(() => {
        searchUsers({
            filters: JSON.stringify({
                global: {value: query, matchMode: 'contains'}
            })
        }, ({ data }) => {
            setUsers(data.users.data)
        })
    }, [query])

    useEffect(() => {
        if (onSelect) {
            onSelect(selectedUser)
        }
    }, [selectedUser])

    const search = (e) => {
        setQuery(e.query)
    }

    const itemTemplate = (user) => {
        return (
            <div className="flex items-center gap-2">
                <div>{user.name}</div>
                <div>
                    &lt;{user.email}&gt;
                </div>
            </div>
        )
    }

    return (
        <AutoComplete
            inputId={id}
            className="w-full mt-2"
            inputClassName="w-full"
            field="name"
            itemTemplate={itemTemplate}
            value={selectedUser}
            suggestions={users}
            completeMethod={search}
            onChange={(e) => setSelectedUser(e.value)}
        />
    )
}

export default UsersAutocomplete